// Hook odpowiedzialny za zmniejszenie ilości danej pozycji w koszyku

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postOrderEditCancel = (orderId: number): Promise<IResponse> =>
  axios.post(`/order-edit/${orderId}/cancel`, {});

export const usePostOrderEditCancel = (
  orderId: number,
  options?: UseMutationOptions<IResponse, IError>
) => useMutation(() => postOrderEditCancel(orderId), options);
