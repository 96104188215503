// nagłówek zamówienia

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import styles from 'theme/pages/Order/components/OrderHeader/OrderHeader.module.scss';

const Header = () => {
  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Order-components-OrderHeader'
      )}>
      <h3 className={styles.title}>
        <Trans>Szczegóły zamówienia</Trans>
      </h3>
    </div>
  );
};

export default Header;
