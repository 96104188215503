// Hook odpowiedzialny za pobranie podsumowania listy dokumentów

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
export type IResponse = {
  upcoming_payments: {
    count: number;
    amount: number;
    amount_formatted: string;
    currency: string;
  };
  payment_after_deadline: {
    count: number;
    amount: number;
    amount_formatted: string;
    currency: string;
  };
};

const getDocumentsSummaryVmp = (): Promise<IResponse> =>
  axios.get('/documents/documents/summary/vmp');

export const useGetDocumentsSummaryVmp = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(
    ['documents-documents-summary-vmp'],
    () => getDocumentsSummaryVmp(),
    options
  );
