// widok listy pozycji

import React, { FC, useState, Dispatch, SetStateAction, useEffect } from 'react';
import classnames from 'classnames';

import { ICartPositionListItem, IUseGetCartPositionsResponse } from 'api/types';
import { CartPositions } from 'components/containers';

import styles from 'theme/pages/Checkout/components/Positions/Positions.module.scss';

//typ queryParams
interface IQueryParams {
  page: number;
  limit: number;
  searchKeyword: string;
  sort_method: string;
}

// typ danych wejściowych
interface IProps {
  cartId: number;
  cartPositionsData?: IUseGetCartPositionsResponse;
  refetchCartPositions: () => void;
  queryParams: IQueryParams;
  setQueryParams: Dispatch<SetStateAction<IQueryParams>>;
}

const Positions: FC<IProps> = ({
  cartId,
  cartPositionsData,
  refetchCartPositions,
  queryParams,
  setQueryParams
}) => {
  // lista pozycji do usunięcia
  const [itemsToRemove, setItemsToRemove] = useState<ICartPositionListItem[]>([]);

  // lista zaznaczonyxh pozycji (ID'ki)
  const [checkedItemIds, setCheckedItemIds] = useState<number[]>([]);

  useEffect(() => {
    refetchCartPositions();
  }, []);

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Pages-Checkout-Components-Positions'
      )}>
      <CartPositions
        cartId={cartId}
        itemsToRemove={itemsToRemove}
        setItemsToRemove={setItemsToRemove}
        checkedItemIds={checkedItemIds}
        setCheckedItemIds={setCheckedItemIds}
        cartPositionsData={cartPositionsData}
        refetchCartPositions={refetchCartPositions}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        variant="noEdit"
        isCheckout={true}
      />
    </div>
  );
};

export default Positions;
