// widok pozycji w wersji default

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import { IColumn } from 'components/controls/Table';
import { IProductVersion, IUnit, IPosition, IProduct } from 'api/types';

import SizingTableQty from './SizingTableQty';

import styles from 'theme/pages/Product/components/SizingTable/SizingTable.module.scss';

export const getExtendedColumns = ({
  renderCell,
  isMobile,
  unit,
  product,
  positions,
  changeProductPositions
}: {
  renderCell: (arg: number) => ReactElement;
  isMobile: boolean;
  unit?: IUnit;
  product: IProduct;
  positions: IPosition[];
  changeProductPositions: (arg: IPosition) => void;
}): IColumn<IProductVersion>[] =>
  !isMobile
    ? [
        {
          title: <Trans>Kolor</Trans>,
          key: 'color',
          align: 'center',
          renderCell: (item, index) => <div>{!index && item.color}</div>,
          width: 140
        },
        {
          title: <Trans>Rozmiar</Trans>,
          key: 'size',
          align: 'center',
          renderCell: (item) => <div>{item.size}</div>,
          width: 140
        },
        {
          title: isMobile ? (
            'M'
          ) : (
            <>
              <Trans>Dostępność</Trans>
              <br />
              <Trans>w magazynie VMP</Trans>
            </>
          ),
          key: 'vmp_availability',
          align: 'center',
          renderCell: (item) => <div>{renderCell(item.vmp_stock_qty)}</div>,
          width: 140
        },
        {
          title: <Trans>Cena</Trans>,
          key: 'net_price',
          align: 'center',
          renderCell: (item) =>
            !!item.price_net && (
              <div className={classNames({ [styles.promoPrice]: !!item.old_price_net })}>
                {!!item.old_price_net && (
                  <span>
                    {item.old_price_net_formatted.replace('.', ',')} <br /> {product.currency}
                  </span>
                )}
                <span>
                  {item.price_net_formatted.replace('.', ',')} <br /> {product.currency}
                </span>
              </div>
            ),
          width: 140
        },
        {
          title: isMobile ? (
            <>
              <span>
                <Trans>Dostępność w magazynie</Trans>
              </span>
              P
            </>
          ) : (
            <>
              <Trans>Dostępność</Trans>
              <br />
              <Trans>w magazynie partnerskim</Trans>
            </>
          ),
          key: 'stock_availability',
          align: 'center',
          renderCell: (item) => <div>{renderCell(item.partner_stock_qty)}</div>,
          width: 140
        },
        {
          title: <Trans>Cena</Trans>,
          key: 'net_price',
          align: 'center',
          renderCell: (item) =>
            !!item.partner_price_net && (
              <div className={classNames({ [styles.promoPrice]: !!item.old_partner_price_net })}>
                {!!item.old_partner_price_net && (
                  <span>
                    {item.old_partner_price_net_formatted.replace('.', ',')} <br />{' '}
                    {product.currency}
                  </span>
                )}
                <span>
                  {item.partner_price_net_formatted.replace('.', ',')} <br /> {product.currency}
                </span>
              </div>
            ),
          width: 140
        },
        {
          title: isMobile ? (
            'D'
          ) : (
            <>
              <Trans>Dostępność</Trans>
              <br />
              <Trans>u producenta</Trans>
            </>
          ),
          key: 'producer_availability',
          align: 'center',
          renderCell: (item) => <div>{renderCell(item.producer_stock_qty)}</div>,
          width: 140
        },
        {
          title: <Trans>Cena</Trans>,
          key: 'net_price',
          align: 'center',
          renderCell: (item) =>
            !!item.producer_price_net && (
              <div className={classNames({ [styles.promoPrice]: !!item.old_producer_price_net })}>
                {!!item.old_producer_price_net && (
                  <span>
                    {item.old_producer_price_net_formatted.replace('.', ',')} <br />{' '}
                    {product.currency}
                  </span>
                )}
                <span>
                  {item.producer_price_net_formatted.replace('.', ',')} <br /> {product.currency}
                </span>
              </div>
            ),
          width: 140
        },
        {
          title: <Trans>Ilość</Trans>,
          key: 'qty',
          align: 'center',
          renderCell: (item) =>
            unit && (
              <SizingTableQty
                productId={item.product_id}
                unitId={unit.unit_id}
                onChangeQty={changeProductPositions}
                isNoSelected={!positions.length}
              />
            )
        }
      ]
    : [
        {
          title: <Trans>Kolor</Trans>,
          key: 'color',
          align: 'center',
          renderCell: (item, index) => <div>{!index && item.color}</div>
        },
        {
          title: <Trans>Rozmiar</Trans>,
          key: 'size',
          align: 'center',
          renderCell: (item) => <div>{item.size}</div>
        },
        {
          title: isMobile ? (
            'M'
          ) : (
            <>
              <Trans>Dostępność</Trans>
              <br />
              <Trans>w magazynie VMP</Trans>
            </>
          ),
          key: 'vmp_availability',
          align: 'center',
          renderCell: (item) => (
            <div className={styles.availability}>
              <div>{renderCell(item.vmp_stock_qty)}</div>
              <div className={classNames({ [styles.promoPrice]: !!item.old_price_net })}>
                {!!item.old_price_net && (
                  <span>
                    {item.old_price_net_formatted.replace('.', ',')} <br /> {product.currency}
                  </span>
                )}
                <span>
                  {item.price_net_formatted.replace('.', ',')} <br /> {product.currency}
                </span>
              </div>
            </div>
          )
        },
        {
          title: isMobile ? (
            <>
              <span className={styles.availabilityTitle}>
                <Trans>Dostępność w magazynie</Trans>
              </span>
              P
            </>
          ) : (
            <>
              <Trans>Dostępność</Trans>
              <br />
              <Trans>w magazynie partnerskim</Trans>
            </>
          ),
          key: 'stock_availability',
          align: 'center',
          renderCell: (item) => (
            <div className={styles.availability}>
              <div>{renderCell(item.partner_stock_qty)}</div>
              <div className={classNames({ [styles.promoPrice]: !!item.old_partner_price_net })}>
                {!!item.old_partner_price_net && (
                  <span>
                    {item.old_partner_price_net_formatted.replace('.', ',')} <br />{' '}
                    {product.currency}
                  </span>
                )}
                <span>
                  {item.partner_price_net_formatted.replace('.', ',')} <br /> {product.currency}
                </span>
              </div>
            </div>
          )
        },
        {
          title: isMobile ? (
            'D'
          ) : (
            <>
              <Trans>Dostępność</Trans>
              <br />
              <Trans>u producenta</Trans>
            </>
          ),
          key: 'producer_availability',
          align: 'center',
          renderCell: (item) => (
            <div className={styles.availability}>
              <div>{renderCell(item.producer_stock_qty)}</div>
              <div className={classNames({ [styles.promoPrice]: !!item.old_producer_price_net })}>
                {!!item.old_producer_price_net && (
                  <span>
                    {item.old_producer_price_net_formatted.replace('.', ',')} <br />{' '}
                    {product.currency}
                  </span>
                )}
                <span>
                  {item.producer_price_net_formatted.replace('.', ',')} <br /> {product.currency}
                </span>
              </div>
            </div>
          )
        },
        {
          title: <Trans>Ilość</Trans>,
          key: 'qty',
          align: 'center',
          renderCell: (item) =>
            unit && (
              <div className={styles.extendedQty}>
                <SizingTableQty
                  productId={item.product_id}
                  unitId={unit.unit_id}
                  onChangeQty={changeProductPositions}
                  isNoSelected={!positions.length}
                />
              </div>
            )
        }
      ];

export default getExtendedColumns;
