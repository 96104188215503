// Hook odpowiedzialny za pobranie szablonu pliku do importu

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
export interface IResponse {
  url_to_template: string;
}

const getCartImportTemplate = (): Promise<IResponse> => axios.get(`/carts/carts/import-template`);

export const useGetCartImportTemplate = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['import-template'], () => getCartImportTemplate(), options);
