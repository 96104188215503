// widok pozycji w wersji view

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import { ICartPositionListItem } from 'api/types';
import { IColumn } from 'components/controls/Table';
import { Link } from 'components/controls';

import styles from 'theme/components/containers/CartPositions/CartPositions.module.scss';

export const getViewColumns = (): IColumn<ICartPositionListItem>[] => [
  {
    title: <Trans>Produkt</Trans>,
    align: 'left',
    key: 'product-image',
    renderCell: (item) => (
      <div className={styles.productThumb}>
        <Link to={`/${item.url_link}`}>
          <img src={item.image[0]?.thumb} alt={item.name} />
        </Link>
      </div>
    ),
    width: 78
  },
  {
    title: '',
    dataIndex: 'name',
    align: 'left',
    renderCell: (item) => (
      <div className={styles.productInfo}>
        <Link to={`/products?filter_attributes=PRODUCER=${item.producer_id}`}>
          <span className={styles.producerName}>{item.producer_name}</span>
        </Link>
        <Link to={`/${item.url_link}`}>
          <span className={styles.productName}>{item.name}</span>
        </Link>
        <span className={styles.productSize}>
          <Trans>Rozmiar</Trans>: {item.size}
        </span>
        {item.color && (
          <span className={classnames(styles.productSize, styles.productColor)}>
            <Trans>Kolor</Trans>: <span>{item.color}</span>
          </span>
        )}
      </div>
    )
  },
  {
    title: <Trans>Cena netto</Trans>,
    dataIndex: 'price_net',
    align: 'center',
    renderCell: (item) => {
      return (
        <>
          {item.old_price_net_formatted && (
            <div className={classnames(styles.productPrice, styles.oldPrice)}>
              {item.old_price_net_formatted.replace('.', ',')} {item.currency}
            </div>
          )}
          <div
            className={classnames(
              styles.productPrice,
              item.old_price_net_formatted && styles.discount
            )}>
            {item.price_net_formatted.replace('.', ',')} {item.currency}
          </div>
        </>
      );
    },
    width: 270
  },
  {
    title: <Trans>Ilość</Trans>,
    dataIndex: 'quantity',
    align: 'center',
    renderCell: (item) => (
      <span>
        {`${item.quantity} `}
        <Trans>szt.</Trans>
      </span>
    ),
    width: 270
  },
  {
    title: <Trans>Wartość netto</Trans>,
    dataIndex: 'total_price_net',
    align: 'center',
    renderCell: (item) => (
      <>
        {item.total_old_price_net_formatted && (
          <div className={classnames(styles.productTotalPrice, styles.oldPrice)}>
            {item.total_old_price_net_formatted.replace('.', ',')} {item.currency}
          </div>
        )}
        <div
          className={classnames(
            styles.productTotalPrice,
            item.total_old_price_net_formatted && styles.discount
          )}>
          {item.total_price_net_formatted.replace('.', ',')} {item.currency}{' '}
        </div>
      </>
    ),
    width: 270
  }
];
