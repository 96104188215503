// komponent Checkbox
import React, { FC, ReactElement } from 'react';
import { Checkbox as CheckboxMui } from '@mui/material';
import classnames from 'classnames';

import styles from 'theme/components/controls/Checkbox/Checkbox.module.scss';

// typ danych wejściowych
interface IProps {
  checked: boolean;
  indeterminate?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  size?: 'small' | 'medium';
  disabled?: boolean;
  icon?: ReactElement;
  checkedIcon?: ReactElement;
  isSecondary?: boolean;
}

const Checkbox: FC<IProps> = ({
  checked,
  onClick,
  indeterminate,
  size,
  disabled,
  icon,
  checkedIcon,
  isSecondary
}) => (
  <span
    className={classnames(
      styles.wrapperComponent,
      { [styles.isSecondary]: !!isSecondary },
      'StylePath-Components-Controls-Checkbox'
    )}>
    <CheckboxMui
      size={size}
      className={classnames(styles.checkbox, {
        [styles.checked]: checked,
        [styles.indeterminate]: indeterminate
      })}
      checked={checked}
      indeterminate={indeterminate}
      onClick={onClick}
      disabled={disabled}
      icon={icon}
      checkedIcon={checkedIcon}
    />
  </span>
);

export default Checkbox;
