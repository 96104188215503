// strona rozpoczęcia procesu resetu hasła

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

import { useAppNavigate } from 'hooks';
import { usePostUserRegisterEmailConfirm } from 'api';

const RegisterEmailConfirm = () => {
  const { search } = useLocation();
  const navigate = useAppNavigate();
  const { t } = useTranslation();

  const { mutate } = usePostUserRegisterEmailConfirm({
    onSuccess: (data) => {
      navigate('/?registerEmailConfirm=true', {
        state: { message: data.message, title: t('Rejestracja potwierdzona.') }
      });
    }
  });

  const hash = qs.parse(search)?.hash;

  useEffect(() => {
    if (hash && typeof hash === 'string') {
      mutate({ hash });
    }
  }, []);

  return null;
};

export default RegisterEmailConfirm;
