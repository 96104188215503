import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import Popover from '@mui/material/Popover';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import { useGetOrderInformationVmp } from 'api';
import { IOrderVmp } from 'api/types';

import { FinancesIcon, WalletIcon } from 'assets/icons';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

interface IProps {
  orderData: IOrderVmp;
  isEditingMode: boolean;
}

const PaymentEdit: FC<IProps> = ({ orderData }) => {
  const { data: orderInformation } = useGetOrderInformationVmp();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid className={classnames(styles.box, styles.payment)} item xs={12} lg={4}>
      <div className={styles.inner}>
        <div className={styles.boxTitle}>
          <div>
            <WalletIcon />
            <Trans>Płatność</Trans>
          </div>
        </div>
        <div className={styles.value}>
          <img src={orderData.payment_method?.image} />
          {orderData.payment_method?.name}
        </div>
        {orderData.manual_cash_on_delivery_value && (
          <div>
            <div className={classnames(styles.boxTitle, styles.collection)}>
              <br />
              <Trans>Wartość pobrania</Trans>
            </div>
            <div>{orderData.manual_cash_on_delivery_value_formatted.replace('.', ',')}</div>
          </div>
        )}
        {orderData.show_information_to_pay && (
          <div className={styles.pay}>
            <FinancesIcon />{' '}
            <button onClick={handleClick}>
              <Trans>Zapłać</Trans>
            </button>
          </div>
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <div
          className={styles.popoverContent}
          dangerouslySetInnerHTML={{ __html: orderInformation?.information || '' }}
        />
      </Popover>
    </Grid>
  );
};

export default PaymentEdit;
