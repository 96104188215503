// galeria zdjęć

import React, { FC, useState } from 'react';
import classnames from 'classnames';
import Slider, { CustomArrowProps } from 'react-slick';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import slice from 'lodash/slice';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Video from 'yet-another-react-lightbox/plugins/video';

import { useRWD } from 'hooks';
import { IImage, IProductFile, IProductLabel } from 'api/types';
import { Label } from 'components/controls';
import { VideoPlayerIcon } from 'assets/icons';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'yet-another-react-lightbox/styles.css';
import styles from 'theme/pages/Product/components/Gallery/Gallery.module.scss';

// typ danych wejściowych
interface IProps {
  images: IImage[];
  files: IProductFile[];
  labels: IProductLabel[];
}

const Gallery: FC<IProps> = ({ images, files, labels }) => {
  const { isMobile } = useRWD();

  // index aktualnego zdjęcia (z tablicy zdjęć)
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // czy jest otwarta dynamiczna galeria lightbox
  const [open, setOpen] = useState(false);

  const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <button
      {...props}
      className={
        'slick-prev slick-arrow' + (slideCount && currentSlide === 0 ? ' slick-disabled' : '')
      }>
      <ChevronUp />
    </button>
  );

  const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <button
      {...props}
      className={
        'slick-next slick-arrow' +
        (slideCount && currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }>
      <ChevronDown />
    </button>
  );

  const slidesToShow = isMobile ? 2 : 4;

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    vertical: !isMobile,
    verticalSwiping: !isMobile,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };

  const slides = [
    ...images.map((image) => ({
      big: image.big,
      min: image.min,
      source: image.source,
      thumb: image.thumb,
      url: '',
      extension: '',
      thumbnail_url: ''
    })),
    ...files.map((image) => ({
      big: '',
      min: '',
      source: '',
      thumb: '',
      url: image.url,
      extension: image.extension,
      thumbnail_url: images[0].thumb
    }))
  ];

  const lightBoxSlides = slides.map((image) => {
    if (image.extension) {
      return {
        type: 'video' as 'image', //typ jako image, ponieważ jest błąd w typowaniu w module yet-another-react-lightbox
        src: image.url,
        sources: [
          {
            src: image.url,
            type: `video/${image.extension}`
          }
        ]
      };
    }

    return {
      src: image.source
    };
  });

  const renderSlides = () =>
    slides.map((image, index) => (
      <div key={index} onClick={() => setCurrentImageIndex(index)}>
        <div
          style={isMobile ? { width: 80, height: 121 } : { width: 96, height: 145 }}
          key={index}
          onClick={() => setCurrentImageIndex(index)}
          className={classnames(styles.thumb, {
            [styles.active]: index === currentImageIndex
          })}>
          {image.thumbnail_url ? (
            <>
              <img
                className={styles.videoThumbnail}
                src={image.thumbnail_url}
                style={{
                  width: '100%',
                  height: '100%'
                }}
                itemProp="image"
              />
              <VideoPlayerIcon />
            </>
          ) : (
            <img
              src={image.thumb}
              style={{
                width: '100%',
                height: '100%'
              }}
              itemProp="image"
            />
          )}
        </div>
      </div>
    ));

  return (
    <div
      className={classnames(styles.wrapperComponent, 'StylePath-Pages-Product-components-Gallery')}>
      {labels.length > 0 && isMobile && (
        <div className={styles.labels}>
          {labels.map((label) => (
            <Label key={label.type} label={label} />
          ))}
        </div>
      )}
      <div>
        {slides[currentImageIndex]?.extension ? (
          <video width="492" height="745" preload="auto" autoPlay playsInline loop controls muted>
            <source src={slides[currentImageIndex]?.url} />
          </video>
        ) : (
          <img
            itemProp="image"
            className={styles['main-image']}
            src={slides[currentImageIndex]?.big || ''}
            onClick={() => setOpen(true)}
          />
        )}
      </div>

      <div className={classnames(styles.slickWrapper)}>
        {slides.length > slidesToShow ? (
          <Slider {...settings} afterChange={(index) => setCurrentImageIndex(index)}>
            {renderSlides()}
          </Slider>
        ) : (
          <div className={styles.imagesWrapper}>{renderSlides()}</div>
        )}
      </div>

      <Lightbox
        open={open}
        plugins={[Zoom, Video]}
        zoom={{ maxZoomPixelRatio: 1, scrollToZoom: true }}
        close={() => setOpen(false)}
        slides={[
          ...slice(lightBoxSlides, currentImageIndex),
          ...slice(lightBoxSlides, 0, currentImageIndex)
        ]}
        render={
          lightBoxSlides.length < 2
            ? {
                buttonPrev: () => null,
                buttonNext: () => null
              }
            : {}
        }
      />
    </div>
  );
};

export default Gallery;
