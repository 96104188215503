// Hook odpowiedzialny za pobranie listy produktow

import axios from 'api/axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse,
  ISearchRequest,
  IFilterAttributesRequest,
  IProductListItem
} from 'api/types';

// parametry requestu do api
export type IRequest = IPaginationRequest &
  ISearchRequest &
  IFilterAttributesRequest & {
    category_id?: number;
    sort_method?: string;
    filter_attributes?: string;
    mode?: 'PROMOTIONS' | 'NEWS' | 'BESTSELLERS';
  };

// typ zwracanych danych
type IResponse = IPaginationResponse<IProductListItem>;

const getProducts = (params?: IRequest): Promise<IResponse> => axios.get('/products', { params });

export const useGetProductsInfinitiveList = (
  params?: IRequest,
  options?: UseInfiniteQueryOptions<IResponse, IError>
) =>
  useInfiniteQuery<IResponse, IError>(
    ['products', params],
    ({ pageParam = params?.page }) => getProducts({ ...params, page: pageParam }),
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage.page + 1,
      getPreviousPageParam: (lastPage) => lastPage.page - 1
    }
  );
