// komponent pojedynczego wyboru

import React, { FC, useState } from 'react';
import classNames from 'classnames';

import styles from 'theme/components/controls/Image/Image.module.scss';

// typ danych wejściowych
interface IProps {
  src: string;
}

const ImageComponent: FC<IProps> = ({ src }) => {
  const [loaded, setIsLoaded] = useState(false);

  return (
    <div className={classNames(styles.wrapperComponent, { [styles.loaded]: !!loaded })}>
      <img onLoad={() => setIsLoaded(true)} src={src} />
    </div>
  );
};

export default ImageComponent;
