// Hook odpowiedzialny za tworzenie exportu

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
type IRequest = {
  token: string;
};

type IResponse = ICommandResponseSuccess;

const postNewsletterConfirm = (data: IRequest): Promise<IResponse> =>
  axios.post('/newsletter/confirm', data);

export const usePostNewsletterConfirm = (
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => postNewsletterConfirm(data), options);
