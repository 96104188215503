// komponent progress bar
import React, { FC } from 'react';
import classnames from 'classnames';
import LinearProgress from '@mui/material/LinearProgress';

import styles from 'theme/components/controls/ProgressBar/ProgressBar.module.scss';

// typ danych wejściowych
interface IProps {
  value: number;
}

const PageTitle: FC<IProps> = ({ value }) => {
  return (
    <div
      className={classnames(styles.wrapperComponent, 'StylePath-Components-Controls-ProgressBar')}>
      <LinearProgress color="primary" variant="determinate" value={value} />
    </div>
  );
};

export default PageTitle;
