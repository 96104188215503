// box z cenami, jedostkami itp

import React, { FC, Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import { useSelector } from 'store';
import { IProduct, IPosition } from 'api/types';
import { AddToCartButton } from 'components/containers';
import { Button } from 'components/controls';

import styles from 'theme/pages/Product/components/Prices/Prices.module.scss';

// typ danych wejściowych
interface IProps {
  product: IProduct;
  positions: IPosition[];
  setPositions: Dispatch<SetStateAction<IPosition[]>>;
}

const Prices: FC<IProps> = ({ positions, setPositions }) => {
  const { profile } = useSelector((state) => state.auth);

  const isOpenProfile = profile?.role === 'ROLE_OPEN_PROFILE';

  return (
    <div
      className={classnames(styles.wrapperComponent, 'StylePath-Pages-Product-components-Prices')}>
      <div className={styles.mainContent}>
        <div className={styles.actionsWrapper}>
          <AddToCartButton
            positions={positions}
            clearPositions={() => setPositions([])}
            large
            disabled={isOpenProfile || !positions.length}
            simple
          />
          <Button ghost square onClick={() => setPositions([])}>
            <Trans>Wyczyść</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Prices;
