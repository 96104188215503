// wrapper o szerokości wyświetlanej zawartości

import React, { FC, PropsWithChildren } from 'react';
import { Container as ContainerMui } from '@mui/material';

const Container: FC<PropsWithChildren> = ({ children }) => {

  return <ContainerMui maxWidth="lg" disableGutters>{children}</ContainerMui>;
};

export default Container;
