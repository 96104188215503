// Hook odpowiedzialny za pobranie informacji o płatności

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
export type IResponse = {
  information: string;
};

const getDocumentsInformationVmp = (): Promise<IResponse> =>
  axios.get('/documents/documents/information/vmp');

export const useGetDocumentsInformationVmp = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(
    ['documents-information'],
    () => getDocumentsInformationVmp(),
    options
  );
