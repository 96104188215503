// Hook odpowiedzialny za pobranie regulaminu

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
type IResponse = {
  terms_rodo: {
    user_rodo_agreement_content: string;
    user_rodo_agreement_content_2_header: string;
    user_rodo_agreement_content_2: string;
    user_rodo_marketin_header: string;
    user_rodo_marketing_content: string;
  };
  user_agreement_content: string;
};

const getTerms = (): Promise<IResponse> => axios.get('/terms');

export const useGetTerms = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['terms'], () => getTerms(), options);
