// Hook odpowiedzialny za potwiedzene koszyka (stworzenie zamówienia)

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

interface IAttachment {
  base64: string;
  type: string;
  name: string;
  description: string;
}

export interface IRequest {
  attachments: IAttachment[];
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postCartAttachments = (cartId: number, data: IRequest): Promise<IResponse> =>
  axios.post(`/carts/${cartId}/attachments`, data);

export const usePostCartAttachments = (
  cartId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => postCartAttachments(cartId, data), options);
