// strona dashboardu

import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useGetDashboardSettlementsSummaryVmp } from 'api';
import { reduxActions, useDispatch } from 'store';
import { Payments, Orders, BottomActions } from './components';

import styles from 'theme/pages/Dashboard/Dashboard.module.scss';

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //pobieranie danych podsumowania
  const { data: settlementsData } = useGetDashboardSettlementsSummaryVmp();

  // ustawieni breadcrumbs'ów na starcie strony
  useEffect(() => {
    dispatch(reduxActions.setBreadcrumbs([{ name: t('Moje konto') }]));
  }, []);

  return (
    <div className={classnames(styles.componentWrapper, 'StylePath-Pages-Dashboard')}>
      <Grid container columnSpacing="32px">
        <Grid item xs={12}>
          <Payments settlements={settlementsData?.settlements} />
          <Orders tradeCredit={settlementsData?.trade_credit} />
          <BottomActions />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
