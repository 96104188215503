// Hook odpowiedzialny za pobranie listy dokumentów

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse
} from 'api/types';

export interface IDocumentListItemVmp {
  is_paid: boolean;
  type: string;
  value_net: number;
  value_net_formatted: string;
  source_document: string;
  associated_with: boolean;
  has_document_files: boolean;
  days_after_payment_deadline: number;
  has_file: boolean;
  id: number;
  order_id: number;
  symbol: string;
  payment_datetime: string;
  create_datetime: string;
  value_gross: number;
  value_gross_formatted: string;
  currency: string;
}

// parametry requestu do api
export type IRequest = IPaginationRequest & {
  documentType: 'INVOICE' | 'PROFORMA' | 'INVOICECORECTION' | 'WZ';
  dateFrom?: string;
  dateTo?: string;
  searchKeyword?: string;
  expiredPayments?: boolean;
  sortMethod?: string;
};

// typ zwracanych danych
type IResponse = IPaginationResponse<IDocumentListItemVmp>;

const getDocumentsVmp = (params?: IRequest): Promise<IResponse> =>
  axios.get('/documents/documents/vmp', { params });

export const useGetDocumentsVmp = (
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['documents-documents-vmp', params],
    () => getDocumentsVmp(params),
    options
  );
