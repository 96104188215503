// formularz klienta

import React, { FC, PropsWithChildren, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import classnames from 'classnames';
import map from 'lodash/map';

import {
  usePostClientVMP,
  usePutClientVMP,
  useGetClientVMP,
  useGetReceiversCountries,
  useGetClientCountries,
  useGetPromotionSimpleForClient,
  useGetTokenSecurity,
  usePostTokenSecurity
} from 'api';
import { useNotifications } from 'hooks';
import { IReceiversCountryListItem } from 'api/types';
import { useSelector } from 'store';
import { Button, Input, FormElement, Checkbox, Select, Collapse } from 'components/controls';
import { InputWithCheckbox } from './components';

import { ClipboardIcon } from 'assets/icons';

import styles from 'theme/pages/Clients/components/ClientForm/ClientForm.module.scss';

type IAddressFieldType = 'primary_shipping_address' | 'additional_shipping_address';

interface ITitleProps extends PropsWithChildren {
  secondary?: boolean;
}

// typ danych wejściowych
interface IProps {
  clientId?: number;
  onCancel: () => void;
  onSuccess: () => void;
}

const ClientForm: FC<IProps> = ({ onCancel, onSuccess, clientId }) => {
  const { t } = useTranslation();
  const { showSuccessMessage } = useNotifications();
  const { profile } = useSelector((state) => state.auth);

  // pobranie szczegółów klienta
  const { data: clientData } = useGetClientVMP(clientId || 0, { enabled: !!clientId });

  // pobieranie krajów odbiorców
  const { data: receiversCountriesData } = useGetReceiversCountries();

  // pobieranie krajów klientów
  const { data: clientCountriesData } = useGetClientCountries();

  // pobieranie danych clientApi
  const { data: tokenData, refetch: refetchTokenData } = useGetTokenSecurity(profile?.id || 0, {
    enabled: !!clientId
  });

  // generowanie tokena clientApi
  const { mutate: generateToken } = usePostTokenSecurity(clientId || 0, {
    onSuccess: () => {
      refetchTokenData();
    }
  });

  // tworzenie nowego klienta
  const { mutate: createClient, isLoading: isCreatingClient } = usePostClientVMP({
    onSuccess: () => {
      onSuccess();
      onCancel();
    },
    onError: (errors) => {
      map(errors.fields_info, (field) => setFieldError(field.property_path, field.message));
    }
  });

  // aktualizacja danych klienta
  const { mutate: updateClient, isLoading: isUpdatingClient } = usePutClientVMP(clientId || 0, {
    onSuccess: () => {
      onSuccess();
      onCancel();
    },
    onError: (errors) => {
      map(errors.fields_info, (field) => setFieldError(field.property_path, field.message));
    }
  });

  // pobieranie listy promocji
  const { data: promotionList } = useGetPromotionSimpleForClient(clientId || 0);

  // obsługa formularza
  const { values, errors, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      nip: clientData?.nip || '',
      name: clientData?.name || '',
      first_name: clientData?.first_name || '',
      last_name: clientData?.last_name || '',
      street: clientData?.street || '',
      building: clientData?.building || '',
      apartment: clientData?.apartment || '',
      postal_code: clientData?.postal_code || '',
      city: clientData?.city || '',
      country_code: clientData?.country_code || '',

      primary_shipping_address: {
        name: clientData?.primary_shipping_address?.name || '',
        first_name: clientData?.primary_shipping_address?.first_name || '',
        last_name: clientData?.primary_shipping_address?.last_name || '',
        address: {
          street: clientData?.primary_shipping_address?.address.street || '',
          building: clientData?.primary_shipping_address?.address.building || '',
          apartment: clientData?.primary_shipping_address?.address.apartment || '',
          postal_code: clientData?.primary_shipping_address?.address.postal_code || '',
          city: clientData?.primary_shipping_address?.address.city || '',
          country_code: clientData?.primary_shipping_address?.address.country_code || ''
        }
      },

      additional_shipping_address: {
        name: clientData?.additional_shipping_address?.name || '',
        first_name: clientData?.additional_shipping_address?.first_name || '',
        last_name: clientData?.additional_shipping_address?.last_name || '',
        address: {
          street: clientData?.additional_shipping_address?.address.street || '',
          building: clientData?.additional_shipping_address?.address.building || '',
          apartment: clientData?.additional_shipping_address?.address.apartment || '',
          postal_code: clientData?.additional_shipping_address?.address.postal_code || '',
          city: clientData?.additional_shipping_address?.address.city || '',
          country_code: clientData?.additional_shipping_address?.address.country_code || ''
        }
      },

      contact_person_first_name: clientData?.contact_person_first_name || '',
      contact_person_last_name: clientData?.contact_person_last_name || '',
      phone: clientData?.contact_person_phone || '',
      email: clientData?.contact_person_email || '',
      website: clientData?.website || '',
      agree_to_send_marketing_information: clientData?.agree_to_send_marketing_information || false,
      agree_online_invoice: clientData?.agree_online_invoice || false,
      flag_dropshipping: clientData?.flag_dropshipping || false,
      dropshipping_percent: clientData?.dropshipping_percent?.toString() || null,
      flag_automatic_return_insurance_in_dropshipping:
        clientData?.flag_automatic_return_insurance_in_dropshipping || false,
      automatic_return_insurance_percent_in_dropshipping:
        clientData?.automatic_return_insurance_percent_in_dropshipping?.toString() || null,
      flag_automatic_return_insurance_without_dropshipping:
        clientData?.flag_automatic_return_insurance_without_dropshipping || false,
      automatic_return_insurance_percent_without_dropshipping:
        clientData?.automatic_return_insurance_percent_without_dropshipping?.toString() || null,
      allow_trade_credit: clientData?.allow_trade_credit || false,
      trade_credit_total: clientData?.trade_credit_total?.toString() || null,
      contract_has_delivered: clientData?.contract_has_delivered || false
    },
    onSubmit: (values) => {
      const valuesFormatted = {
        ...values,
        trade_credit_total: parseFloat(values.trade_credit_total as string),
        automatic_return_insurance_percent_without_dropshipping: parseFloat(
          values.automatic_return_insurance_percent_without_dropshipping as string
        ),
        automatic_return_insurance_percent_in_dropshipping: parseFloat(
          values.automatic_return_insurance_percent_in_dropshipping as string
        ),
        dropshipping_percent: parseFloat(values.dropshipping_percent as string)
      };

      clientId ? updateClient(valuesFormatted) : createClient(valuesFormatted);
    },
    validateOnChange: false,
    enableReinitialize: true
  });

  // opcje dropdownu typów pola klient
  const clientCountryOptions = useMemo(() => {
    const sections = (clientCountriesData?.items || []).map((country) => ({
      value: country.code,
      label: <span>{country.name}</span>,
      item: country
    }));

    return sections;
  }, [clientCountriesData]);

  // opcje dropdownu typów pola odbiorce
  const receiverCountryOptions = useMemo(() => {
    const sections = (receiversCountriesData?.items || []).map((country) => ({
      value: country.code,
      label: <span>{country.name}</span>,
      item: country
    }));

    return sections;
  }, [receiversCountriesData]);

  const Title: FC<ITitleProps> = ({ children, secondary }) => (
    <div className={classnames(styles.title, { [styles.secondary]: secondary })}>{children}</div>
  );

  const renderAddress = (field: IAddressFieldType) => {
    return (
      <>
        <span className={styles.label}>
          <Trans>Nazwa firmy</Trans>
        </span>
        <FormElement>
          <Input
            type="text"
            value={values[field].name}
            onChange={(value) => setFieldValue(`${field}.name`, value)}
            error={errors[field]?.name}
          />
        </FormElement>
        <span className={styles.label}>
          <Trans>Imię</Trans>
        </span>
        <FormElement>
          <Input
            type="text"
            value={values[field].first_name}
            onChange={(value) => setFieldValue(`${field}.first_name`, value)}
            error={errors[field]?.first_name}
          />
        </FormElement>
        <span className={styles.label}>
          <Trans>Nazwisko</Trans>
        </span>
        <FormElement>
          <Input
            type="text"
            value={values[field].last_name}
            onChange={(value) => setFieldValue(`${field}.last_name`, value)}
            error={errors[field]?.last_name}
          />
        </FormElement>
        <span className={styles.label}>
          <Trans>Ulica</Trans>
        </span>
        <FormElement>
          <Input
            type="text"
            value={values[field].address.street}
            onChange={(value) => setFieldValue(`${field}.address.street`, value)}
            error={errors[field]?.address?.street}
          />
        </FormElement>
        <div className={styles.halfWidth}>
          <FormElement>
            <span className={styles.label}>
              <Trans>Nr budynku</Trans>
            </span>
            <Input
              type="text"
              value={values[field].address.building}
              onChange={(value) => setFieldValue(`${field}.address.building`, value)}
              error={errors[field]?.address?.building}
            />
          </FormElement>
          <FormElement>
            <span className={styles.label}>
              <Trans>Nr lokalu</Trans>
            </span>
            <Input
              type="text"
              value={values[field].address.apartment}
              onChange={(value) => setFieldValue(`${field}.address.apartment`, value)}
              error={errors[field]?.address?.apartment}
            />
          </FormElement>
        </div>

        <div className={styles.halfWidth}>
          <FormElement>
            <span className={styles.label}>
              <Trans>Kod pocztowy</Trans>
            </span>
            <Input
              type="text"
              value={values[field].address.postal_code}
              onChange={(value) => setFieldValue(`${field}.address.postal_code`, value)}
              error={errors[field]?.address?.postal_code}
            />
          </FormElement>
          <FormElement>
            <span className={styles.label}>
              <Trans>Miasto</Trans>
            </span>
            <Input
              type="text"
              value={values[field].address.city}
              onChange={(value) => setFieldValue(`${field}.address.city`, value)}
              error={errors[field]?.address?.city}
            />
          </FormElement>
        </div>
        <span className={styles.label}>
          <Trans>Kraj</Trans>
        </span>
        <FormElement>
          <Select<IReceiversCountryListItem>
            options={receiverCountryOptions}
            placeholder={t('Wybierz')}
            variant="bordered"
            value={values[field].address.country_code}
            onChange={(item) => {
              if (item?.code) {
                setFieldValue(`${field}.address.country_code`, item?.code);
              }
            }}
          />
        </FormElement>
      </>
    );
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.accountWrapper}>
        <div className={styles.section}>
          <div className={styles.invoice}>
            <Title>
              <Trans>Dane kontrahenta</Trans>
            </Title>
          </div>
          <span className={styles.label}>
            <Trans>NIP/VAT EU</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.nip}
              onChange={(value) => setFieldValue('nip', value)}
              error={errors?.nip}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Nazwa firmy</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.name}
              onChange={(value) => setFieldValue('name', value)}
              error={errors?.name}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Imię</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.first_name}
              onChange={(value) => setFieldValue('first_name', value)}
              error={errors?.first_name}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Nazwisko</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.last_name}
              onChange={(value) => setFieldValue('last_name', value)}
              error={errors?.last_name}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Ulica</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.street}
              onChange={(value) => setFieldValue('street', value)}
              error={errors?.street}
            />
          </FormElement>

          <div className={styles.halfWidth}>
            <div>
              <span className={styles.label}>
                <Trans>Nr budynku</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.building}
                  onChange={(value) => setFieldValue('building', value)}
                  error={errors?.building}
                />
              </FormElement>
            </div>
            <div>
              <span className={styles.label}>
                <Trans>Nr lokalu</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.apartment}
                  onChange={(value) => setFieldValue('apartment', value)}
                  error={errors?.apartment}
                />
              </FormElement>
            </div>
          </div>
          <div className={styles.halfWidth}>
            <div>
              <span className={styles.label}>
                <Trans>Kod pocztowy</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.postal_code}
                  onChange={(value) => setFieldValue('postal_code', value)}
                  error={errors?.postal_code}
                />
              </FormElement>
            </div>
            <div>
              <span className={styles.label}>
                <Trans>Miasto</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.city}
                  onChange={(value) => setFieldValue('city', value)}
                  error={errors?.city}
                />
              </FormElement>
            </div>
          </div>
          <span className={styles.label}>
            <Trans>Kraj</Trans>
          </span>
          <FormElement>
            <Select<IReceiversCountryListItem>
              options={clientCountryOptions}
              placeholder={t('Wybierz')}
              variant="bordered"
              value={values.country_code}
              onChange={(item) => {
                if (item?.code) {
                  setFieldValue('country_code', item?.code);
                }
              }}
            />
          </FormElement>
        </div>
        <div className={classnames(styles.section, styles.sectionMiddle)}>
          <Title>
            <Trans>Adres do wysyłki</Trans>
          </Title>
          <Collapse title={<Trans>Adres główny</Trans>} collapsed={false} fullWidth>
            {renderAddress('primary_shipping_address')}
          </Collapse>
          <Collapse title={<Trans>Adres dodatkowy</Trans>} fullWidth>
            {renderAddress('additional_shipping_address')}
          </Collapse>
        </div>
        <div className={styles.section}>
          <Title>
            <Trans>Dane kontaktowe</Trans>
          </Title>
          <span className={styles.label}>
            <Trans>Imię</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.contact_person_first_name}
              onChange={(value) => setFieldValue('contact_person_first_name', value)}
              error={errors.contact_person_first_name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Nazwisko</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.contact_person_last_name}
              onChange={(value) => setFieldValue('contact_person_last_name', value)}
              error={errors.contact_person_last_name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Telefon</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.phone}
              onChange={(value) => setFieldValue('phone', value)}
              error={errors.phone}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Email</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              onChange={(value) => setFieldValue('email', value)}
              value={values?.email}
              error={errors.email}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Strona internetowa</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              onChange={(value) => setFieldValue('website', value)}
              value={values?.website}
              error={errors?.website}
            />
          </FormElement>
          <FormElement>
            <label className={styles.consent}>
              <Checkbox
                checked={values.agree_to_send_marketing_information}
                onClick={() =>
                  setFieldValue(
                    'agree_to_send_marketing_information',
                    !values.agree_to_send_marketing_information
                  )
                }
              />
              <span className={styles.label}>
                <Trans>Zgoda na przesyłanie informacji marketingowych</Trans>
              </span>
            </label>
            <span className={styles.error}>{errors.agree_to_send_marketing_information}</span>
          </FormElement>
          {clientId && (profile?.role === 'ROLE_USER_MAIN' || profile?.role === 'ROLE_ADMIN') && (
            <div className={styles.clientApiWrapper}>
              <div className={styles.title}>ClientAPI</div>
              <div className={styles.accountWrapper}>
                <div className={styles.tokenWrapper}>
                  <div>
                    <label>Client Id</label>
                    <Input value={tokenData?.client_id} disabled />
                    <ClipboardIcon
                      onClick={() => {
                        navigator.clipboard.writeText(tokenData?.client_id || '');
                        showSuccessMessage(t('Skopiowano token do schowka'));
                      }}
                    />
                  </div>
                  <div>
                    <label>Client Secret</label>
                    <Input value={tokenData?.client_secret} disabled />
                    <ClipboardIcon
                      onClick={() => {
                        navigator.clipboard.writeText(tokenData?.client_secret || '');
                        showSuccessMessage(t('Skopiowano token do schowka'));
                      }}
                    />
                  </div>

                  <Button onClick={() => generateToken({ client_id: profile.id })}>
                    <Trans>Generuj nowy klucz dostępu</Trans>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.accountWrapper}>
        <div className={styles.section}>
          <Title>
            <Trans>Dokumenty i płatności</Trans>
          </Title>
          <InputWithCheckbox
            label={t('Limit kredytu kupieckiego')}
            setFieldValue={setFieldValue}
            checkboxName="allow_trade_credit"
            inputName="trade_credit_total"
            checkboxValue={values.allow_trade_credit}
            inputValue={values.trade_credit_total}
          />
          <span className={styles.label}>
            <Trans>Akceptuje fakturę elektroniczną</Trans>
          </span>
          <FormElement>
            <Select
              placeholder={t('Wybierz')}
              options={[
                { value: 1, label: 'Tak', item: { agree_online_invoice: true } },
                { value: 0, label: 'Nie', item: { agree_online_invoice: false } }
              ]}
              onChange={(item) => {
                setFieldValue('agree_online_invoice', item?.agree_online_invoice);
              }}
              value={values.agree_online_invoice ? 1 : 0}
            />
          </FormElement>
        </div>
        <div className={classnames(styles.section, styles.sectionMiddle)}>
          <Title>
            <Trans>Umowy</Trans>
          </Title>
          <InputWithCheckbox
            label={t('Dropshipping')}
            setFieldValue={setFieldValue}
            checkboxName="flag_dropshipping"
            inputName="dropshipping_percent"
            checkboxValue={values.flag_dropshipping}
            inputValue={values.dropshipping_percent}
          />
          <InputWithCheckbox
            label={t('Zwroty dropshipping')}
            setFieldValue={setFieldValue}
            checkboxName="flag_automatic_return_insurance_in_dropshipping"
            inputName="automatic_return_insurance_percent_in_dropshipping"
            checkboxValue={values.flag_automatic_return_insurance_in_dropshipping}
            inputValue={values.automatic_return_insurance_percent_in_dropshipping}
          />
          <InputWithCheckbox
            label={t('Zwroty bez dropshippingu')}
            setFieldValue={setFieldValue}
            checkboxName="flag_automatic_return_insurance_without_dropshipping"
            inputName="automatic_return_insurance_percent_without_dropshipping"
            checkboxValue={values.flag_automatic_return_insurance_without_dropshipping}
            inputValue={values.automatic_return_insurance_percent_without_dropshipping}
          />
          <FormElement>
            <label className={styles.consent}>
              <Checkbox
                checked={values.contract_has_delivered}
                onClick={() =>
                  setFieldValue('contract_has_delivered', !values.contract_has_delivered)
                }
              />
              <span className={styles.label}>
                <Trans>Dokument dostarczony</Trans>
              </span>
            </label>
            <span className={styles.error}>{errors.contract_has_delivered}</span>
          </FormElement>
        </div>
        <div className={styles.section}>
          <Title>
            <Trans>Nadane rabaty</Trans>
          </Title>
          <div>
            {map(promotionList?.items || [], (item) => (
              <div className={styles.promotion}>
                <Title>{item.title}</Title>
                <div>
                  {map(item.conditions, (condition) => (
                    <div>
                      <span>
                        {condition.title} - {condition.value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.actions}>
            <Button htmlType="submit" loading={isUpdatingClient || isCreatingClient}>
              <Trans>{clientId ? 'Edytuj kontrahenta' : 'Stwórz kontrahenta'}</Trans>
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ClientForm;
