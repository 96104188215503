// widok pozycji w wersji default

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import { IColumn } from 'components/controls/Table';
import { ICartPositionListItem } from 'api/types';
import { Checkbox, Counter, Availability, Link } from 'components/controls';

import { TrashIcon } from 'assets/icons';

import styles from 'theme/components/containers/CartPositions/CartPositions.module.scss';

export const getEditColumns = ({
  handleChangeQuantity,
  checkedItemIds,
  setCheckedItemIds,
  updatingPositionIds,
  setItemsToRemove,
  isCartPositionsRefetching,
  isMobile
}: {
  handleChangeQuantity: (
    position: ICartPositionListItem,
    newValue: number | null,
    isIncrement?: boolean
  ) => void;
  checkedItemIds: number[];
  setCheckedItemIds: (items: number[]) => void;
  updatingPositionIds: number[];
  setItemsToRemove: (items: ICartPositionListItem[]) => void;
  isCartPositionsRefetching?: boolean;
  isMobile: boolean;
}): IColumn<ICartPositionListItem>[] => [
  {
    title: '',
    key: 'checkbox',
    align: 'left',
    renderCell: (item) => {
      const isChecked = checkedItemIds.includes(item.id);
      return (
        <Checkbox
          checked={isChecked}
          onClick={() =>
            setCheckedItemIds(
              isChecked
                ? checkedItemIds.filter((checkbox) => checkbox !== item.id)
                : [...checkedItemIds, item.id]
            )
          }
        />
      );
    },
    width: 60
  },
  {
    title: <Trans>Produkt</Trans>,
    key: 'product-image',
    align: 'left',
    renderCell: (item) => (
      <div className={styles.productThumb}>
        <Link to={`/${item.url_link}`}>
          <img src={item.image[0]?.thumb} alt={item.name} />
        </Link>
      </div>
    ),
    width: 78
  },
  {
    title: '',
    dataIndex: 'name',
    align: 'center',
    renderCell: (item) => (
      <div className={styles.productInfo}>
        <Link to={`/products?filter_attributes=PRODUCER=${item.producer_id}`}>
          <span className={styles.producerName}>{item.producer_name}</span>
        </Link>
        <Link to={`/${item.url_link}`}>
          <span className={styles.productName}>{item.name}</span>
        </Link>
        <span className={styles.productSize}>
          <Trans>Rozmiar</Trans>: <span>{item.size}</span>
        </span>
        {item.color && (
          <span className={classnames(styles.productSize, styles.productColor)}>
            <Trans>Kolor</Trans>: <span>{item.color}</span>
          </span>
        )}
      </div>
    )
  },
  {
    title: <Trans>{isMobile ? 'Dostępność' : 'Magazyn'}</Trans>,
    dataIndex: 'name',
    align: 'center',
    renderCell: (item) => (
      <div className={styles.productAvailability}>
        <Availability stock={item.warehouses} />
      </div>
    )
  },
  {
    title: <Trans>Cena netto</Trans>,
    dataIndex: 'price_net',
    sortBy: 'price_net',
    align: 'center',
    renderCell: (item) => {
      return (
        <>
          {item.old_price_net_formatted && (
            <div className={classnames(styles.productPrice, styles.oldPrice)}>
              {item.old_price_net_formatted.replace('.', ',')} {item.currency}
            </div>
          )}
          <div
            className={classnames(
              styles.productPrice,
              item.old_price_net_formatted && styles.discount
            )}>
            {item.price_net_formatted.replace('.', ',')} {item.currency}
          </div>
        </>
      );
    }
  },
  {
    title: <Trans>Ilość</Trans>,
    dataIndex: 'quantity',
    sortBy: 'quantity',
    align: 'center',
    width: 150,
    renderCell: (item) => {
      return (
        <div className={styles.quantityCellWrapper}>
          <Counter
            onChange={(value) => handleChangeQuantity(item, value)}
            onIncrease={() => handleChangeQuantity(item, null, true)}
            onDecrease={() => handleChangeQuantity(item, null, false)}
            value={item.quantity}
            disabled={!!updatingPositionIds.length || isCartPositionsRefetching}
          />
        </div>
      );
    }
  },
  {
    title: <Trans>Wartość netto</Trans>,
    dataIndex: 'total_price_net',
    sortBy: 'total_price_net',
    align: 'center',
    renderCell: (item) => (
      <>
        {item.total_old_price_net_formatted && (
          <div className={classnames(styles.productTotalPrice, styles.oldPrice)}>
            {item.total_old_price_net_formatted.replace('.', ',')} {item.currency}
          </div>
        )}
        <div
          className={classnames(
            styles.productTotalPrice,
            item.total_old_price_net_formatted && styles.discount
          )}>
          {item.total_price_net_formatted.replace('.', ',')} {item.currency}{' '}
        </div>
      </>
    )
  },
  {
    title: <Trans>VAT</Trans>,
    dataIndex: 'tax_rate',
    align: 'center',
    renderCell: (item) => <div className={styles.productTotalPrice}>{item.tax_rate} %</div>
  },
  {
    title: <Trans>Wartość brutto</Trans>,
    dataIndex: 'total_price_gross',
    sortBy: 'total_price_gross',
    align: 'center',
    renderCell: (item) => (
      <>
        {item.total_old_price_gross_formatted && (
          <div className={classnames(styles.productTotalPrice, styles.oldPrice)}>
            {item.total_old_price_gross_formatted.replace('.', ',')} {item.currency}
          </div>
        )}
        <div
          className={classnames(
            styles.productTotalPrice,
            item.total_old_price_gross_formatted && styles.discount
          )}>
          {item.total_price_gross_formatted.replace('.', ',')} {item.currency}{' '}
        </div>
      </>
    )
  },
  {
    title: '',
    key: 'actions',
    align: 'center',
    renderCell: (item) => (
      <div className={styles.actionsWrapper}>
        <TrashIcon onClick={() => setItemsToRemove([item])} />
      </div>
    )
  }
];
