// widok pozycji w wersji small

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import { ICartPositionListItem } from 'api/types';
import { IColumn } from 'components/controls/Table';
import { Link } from 'components/controls';

import { TrashIcon } from 'assets/icons';

import styles from 'theme/components/containers/CartPositions/CartPositions.module.scss';

export const getSmallColumns = ({
  setItemsToRemove
}: {
  handleChangeQuantity: (
    position: ICartPositionListItem,
    newValue: number | null,
    isIncrement?: boolean
  ) => void;
  updatingPositionIds: number[];
  setItemsToRemove: (items: ICartPositionListItem[]) => void;
}): IColumn<ICartPositionListItem>[] => [
  {
    title: '',
    key: 'product-image',
    renderCell: (item) => (
      <div className={classnames(styles.productThumb, styles.small)}>
        <Link to={`/${item.url_link}`}>
          <img src={item.image[0]?.thumb} alt={item.name} />
        </Link>
      </div>
    ),
    width: 78
  },
  {
    title: '',
    dataIndex: 'name',
    align: 'left',
    renderCell: (item) => (
      <div className={classnames(styles.productInfo, styles.small)}>
        <Link to={`/products?filter_attributes=PRODUCER=${item.producer_id}`}>
          <div className={styles.producerName}>{item.producer_name}</div>
        </Link>
        <Link to={`/${item.url_link}`}>
          <div className={styles.productName}>{item.name}</div>
        </Link>
        <div className={styles.productSize}>
          <Trans>Rozmiar</Trans>: {item.size}
        </div>
        {item.color && (
          <div className={classnames(styles.productSize, styles.productColor)}>
            <Trans>Kolor</Trans>: <span>{item.color}</span>
          </div>
        )}
      </div>
    )
  },
  {
    title: '',
    dataIndex: 'quantity',
    align: 'center',
    width: 120,
    renderCell: (item) => (
      <div className={classnames(styles.quantityCellWrapper, styles.small)}>{item.quantity}</div>
    )
  },
  {
    title: '',
    dataIndex: 'total_price_net',
    width: 120,
    align: 'right',
    renderCell: (item) => (
      <div className={classnames(styles.productTotalPrice, styles.small)}>
        <div className={styles.netto}>
          <b>
            {item.total_price_net_formatted.replace('.', ',')} {item.currency}{' '}
          </b>
          <span className={styles.suffix}>
            <Trans>netto</Trans>
          </span>
        </div>
        <span className={styles.gross}>
          {item.total_price_gross_formatted.replace('.', ',')} {item.currency}{' '}
          <span className={styles.suffix}>
            <Trans>brutto</Trans>
          </span>
        </span>
      </div>
    )
  },
  {
    title: '',
    key: 'actions',
    align: 'center',
    renderCell: (item) => (
      <div className={classnames(styles.actionsWrapper, styles.small)}>
        <TrashIcon onClick={() => setItemsToRemove([item])} />
      </div>
    )
  }
];
