// selektor zakresu dat

import React, { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as locale from 'date-fns/locale';
import {
  parseISO,
  format,
  endOfMonth,
  startOfMonth,
  subMonths,
  subYears,
  startOfYear,
  startOfDay,
  endOfDay,
  endOfYear
} from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import Popover from '@mui/material/Popover';
import { Calendar } from 'react-bootstrap-icons';
import classnames from 'classnames';

import { useRWD } from 'hooks';

import styles from 'theme/components/controls/DateRangePicker/DateRangePicker.module.scss';
import 'react-day-picker/dist/style.css';

// typ danych wejściowych
interface IProps {
  dateRange?: {
    from?: string;
    to?: string;
  };
  onChange?: (dateRange: NonNullable<Required<IProps['dateRange']>>) => void;
  placeholder?: string;
}

const DateRangePicker: FC<IProps> = ({ dateRange, onChange, placeholder }) => {
  const { isMobile } = useRWD();
  const { pathname } = useLocation();

  // czy zmiana zakresu jest w trakcie (pierwsze kliknięcie)
  const [isNewSelection, setIsNewSelection] = useState(true);

  // lokalny zakres dat
  const [range, setRange] = useState<DateRange>();

  // element html z kalendarzami
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = pathname.slice(0, getPosition(pathname, '/', 2)).replace('/', '');

  // ustawienie lokalnego zakresu dat przy zmianie danych z props'ów
  useEffect(() => {
    setDateRangeFromProps();
  }, [dateRange]);

  // funkcja ustawiająca lokalnego zakresu dat na dane z propsów
  const setDateRangeFromProps = () => {
    const rangeFrom = dateRange?.from;
    const rangeTo = dateRange?.to;

    setRange({
      from: rangeFrom ? parseISO(rangeFrom) : undefined,
      to: rangeTo ? parseISO(rangeTo) : undefined
    });
  };

  // fonkcja wykonywana po wybraniu daty
  const handleOnSelectDate = (range?: DateRange, selectedDate?: Date) => {
    if (isNewSelection || !range) {
      setRange({
        from: selectedDate
      });
      setIsNewSelection(false);
    } else {
      setRange(range);
      setIsNewSelection(true);
      setPopoverAnchor(null);
      range.from &&
        range.to &&
        onChange?.({
          from: format(range.from, 'yyyy-MM-dd HH:mm:ss'),
          to: format(range.to, 'yyyy-MM-dd HH:mm:ss')
        });
    }
  };

  const handleOnSelectFromSidebar = (range: DateRange) => {
    setRange(range);
    setPopoverAnchor(null);
    range.from &&
      range.to &&
      onChange?.({
        from: format(range.from, 'yyyy-MM-dd HH:mm:ss'),
        to: format(range.to, 'yyyy-MM-dd HH:mm:ss')
      });
  };

  const handleOnClosePopover = () => {
    setPopoverAnchor(null);
    setDateRangeFromProps();
  };

  const pickerLocale =
    urlPrefix === 'en'
      ? locale['enGB']
      : locale?.[urlPrefix as keyof typeof locale] || locale['pl'];

  const formatWeekdayName = (weekName: Date) => {
    return format(weekName, 'E', { locale: pickerLocale }).substring(0, 1);
  };

  return (
    <>
      <button
        onClick={({ currentTarget }) => setPopoverAnchor(currentTarget)}
        className={classnames(styles.button, 'StylePath-Components-Controls-DateRangePicker')}>
        {range?.from && range?.to ? (
          <span>
            {format(range.from, 'dd.MM.yyyy')}
            {range.to && ` - ${format(range.to, 'dd.MM.yyyy')}`}
          </span>
        ) : (
          <span className={styles.placeholder}>
            {placeholder || <Trans>Wybierz zakres dat</Trans>}
          </span>
        )}
        <Calendar />
      </button>
      <Popover
        anchorEl={popoverAnchor}
        open={!!popoverAnchor}
        onClose={handleOnClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={styles.pickerWrapper}>
          <div>
            <button
              onClick={() => {
                handleOnSelectFromSidebar({
                  from: startOfDay(new Date()),
                  to: endOfDay(new Date())
                });
              }}>
              <Trans>Dziś</Trans>
            </button>
            <button
              onClick={() =>
                handleOnSelectFromSidebar({
                  from: startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
                  to: endOfDay(new Date(new Date().setDate(new Date().getDate() - 1)))
                })
              }>
              <Trans>Wczoraj</Trans>
            </button>
            <button
              onClick={() =>
                handleOnSelectFromSidebar({
                  from: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
                  to: new Date(new Date().setDate(new Date().getDate() - 1))
                })
              }>
              <Trans>Poprzedni tydzień</Trans>
            </button>
            <button
              onClick={() =>
                handleOnSelectFromSidebar({
                  from: startOfMonth(new Date()),
                  to: new Date()
                })
              }>
              <Trans>Ten miesiąc</Trans>
            </button>
            <button
              onClick={() =>
                handleOnSelectFromSidebar({
                  from: startOfMonth(subMonths(new Date(), 1)),
                  to: endOfMonth(subMonths(new Date(), 1))
                })
              }>
              <Trans>Poprzedni miesiąc</Trans>
            </button>
            <button
              onClick={() =>
                handleOnSelectFromSidebar({
                  from: startOfYear(new Date()),
                  to: new Date()
                })
              }>
              <Trans>Bieżący rok</Trans>
            </button>
            <button
              onClick={() =>
                handleOnSelectFromSidebar({
                  from: startOfYear(subYears(new Date(), 1)),
                  to: endOfYear(subYears(new Date(), 1))
                })
              }>
              <Trans>Poprzedni rok</Trans>
            </button>
          </div>
          <DayPicker
            mode="range"
            numberOfMonths={isMobile ? 1 : 2}
            selected={range}
            onSelect={handleOnSelectDate}
            locale={pickerLocale}
            formatters={{ formatWeekdayName }}
          />
        </div>
      </Popover>
    </>
  );
};

export default DateRangePicker;
