// sekcja demo na stronie głównej

import React, { FC, useEffect } from 'react';

import { useGetCmsSectionArticle, useGetCmsSectionArticles } from 'api';

import { HtmlBlockMultipleArticle, HtmlBlockSingleArticle } from './components';

import 'theme/pages/Home/Home.scss';

interface IProps {
  sectionId: string;
  articleId?: string;
  withBreadCrumbs?: boolean;
}

export const HtmlBlock: FC<IProps> = ({ sectionId, articleId, withBreadCrumbs }) => {
  // pobranie bloku html
  const {
    data: singleArticleData,
    refetch: refetchSingleData,
    isLoading: isSingleArticleDataLoading
  } = useGetCmsSectionArticle(sectionId, articleId || '', {
    enabled: false
  });

  // pobranie bloku html
  const {
    data: multipleArticleData,
    refetch: refetchMultipleData,
    isLoading: isMultipleArticleDataLoading
  } = useGetCmsSectionArticles(
    sectionId,
    { fetchArticleFields: true },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (articleId) {
      refetchSingleData();

      return;
    }

    refetchMultipleData();
  }, [articleId, sectionId]);

  if (isMultipleArticleDataLoading || isSingleArticleDataLoading) {
    return (
      <div className="homePlaceholder">
        <div></div>
        <div></div>
      </div>
    );
  }

  return (
    <>
      {articleId ? (
        <HtmlBlockSingleArticle content={singleArticleData} withBreadCrumbs={withBreadCrumbs} />
      ) : (
        <HtmlBlockMultipleArticle content={multipleArticleData} />
      )}
    </>
  );
};

export default HtmlBlock;
