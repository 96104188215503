// Hook odpowiedzialny za pobranie listy statusów

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse
} from 'api/types';

export interface IClientStatusListItem {
  status: number;
  status_symbol: string;
  status_formatted: string;
}

// parametry requestu do api
export type IRequest = IPaginationRequest;

// typ zwracanych danych
type IResponse = IPaginationResponse<IClientStatusListItem>;

const getClientsStatuses = (params?: IRequest): Promise<IResponse> =>
  axios.get('/clients/statuses', { params });

export const useGetClientsStatuses = (
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['client-statuses', params],
    () => getClientsStatuses(params),
    options
  );
