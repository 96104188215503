// przełącznik widoku list

import React, { FC } from 'react';
import classnames from 'classnames';

import { reduxActions, useDispatch } from 'store';

import styles from 'theme/components/controls/GridSwitcher/GridSwitcher.module.scss';
import { GridSwitcherLines, GridSwitcherTiles } from 'assets/icons';

// typ danych wejściowych
export interface IProps {
  type: 'grid' | 'line';
}

const GridSwitcher: FC<IProps> = ({ type }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={classnames(styles.wrapperComponent, 'StylePath-Components-Controls-GridSwitcher')}>
      <GridSwitcherLines
        className={classnames(styles.gridIcon, { [styles.active]: type === 'line' })}
        onClick={() => type !== 'line' && dispatch(reduxActions.setGridType('line'))}
      />
      <GridSwitcherTiles
        className={classnames(styles.gridIcon, { [styles.active]: type === 'grid' })}
        onClick={() => type !== 'grid' && dispatch(reduxActions.setGridType('grid'))}
      />
    </div>
  );
};

export default GridSwitcher;
