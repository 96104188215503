// Hook odpowiedzialny za export zamówienia

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
interface IResponse {
  file_name: string;
  url: string;
}

const getExportDocument = (documentId: number): Promise<IResponse> =>
  axios.get(`/export/${documentId}`);

export const useGetExportDocument = (
  documentId: number,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(['export', documentId], () => getExportDocument(documentId), options);
