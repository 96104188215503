// Hook odpowiedzialny za pobranie listy zamówień

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

interface FilterField {
  value: string;
  label: string;
}

export interface IOrderFilterItem {
  items: FilterField[];
}

// typ zwracanych danych
type IResponse = IOrderFilterItem;

const getOrdersModes = (): Promise<IResponse> => axios.get('/orders/modes');

export const useGetOrdersModes = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['orders-modes'], () => getOrdersModes(), options);
