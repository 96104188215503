// Hook odpowiedzialny za pobranie listy usług w koszyku

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
type IResponse = {
  information: string;
};

const getOrderInformationVmp = (): Promise<IResponse> => axios.get('/orders/information/vmp');

export const useGetOrderInformationVmp = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['orders-information'], () => getOrderInformationVmp(), options);
