// Hook odpowiedzialny za pobranie szczegółów klienta

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, IPaginationResponse } from 'api/types';

interface Icondition {
  title: string;
  value: string;
}

interface IPromotionItem {
  title: string;
  conditions: Icondition[];
}

// typ zwracanych danych
type IResponse = IPaginationResponse<IPromotionItem>;

const getPromotionSimpleForClient = (clientId: number): Promise<IResponse> =>
  axios.get(`/promotion/simple/for-client/${clientId}`);

export const useGetPromotionSimpleForClient = (
  clientId: number,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['promotion-simple-for-client', clientId],
    () => getPromotionSimpleForClient(clientId),
    options
  );
