/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import map from 'lodash/map';

import { usePostUserRegister, useGetUsersCountries } from 'api';
import { IUsersCountryListItem } from 'api/types';
import { useRWD, useAppNavigate } from 'hooks';
import { Button, Input, FormElement, Checkbox, Select } from 'components/controls';

import styles from 'theme/components/containers/UserForm/UserForm.module.scss';

interface ITitleProps extends PropsWithChildren {
  secondary?: boolean;
}

const UserForm = () => {
  const { t } = useTranslation();
  const { isMobile } = useRWD();
  const navigate = useAppNavigate();

  const { pathname } = useLocation();

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = pathname.slice(0, getPosition(pathname, '/', 2)).replaceAll('/', '');

  // aktualizacja danych profilu
  const { mutate: createProfile, isLoading: isCreatingProfile } = usePostUserRegister({
    onSuccess: (data) => {
      resetForm();
      navigate('/?thankYouRegister=true', { state: { message: data.message } });
    },
    onError: (errors) => {
      map(errors.fields_info, (field) => setFieldError(field.property_path, field.message));
    }
  });

  // pobieranie listy możliwych krajów
  const { data: articleCountries } = useGetUsersCountries();

  // obsługa danych formularza
  const { values, errors, setFieldValue, handleSubmit, setFieldError, resetForm } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      tax_number: '',
      phone: '',
      email: '',
      recaptcha_token: '',
      billing_address: {
        name: '',
        street: '',
        building: '',
        apartment: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
        country_code: ''
      },
      receiver_address: {
        name: '',
        street: '',
        building: '',
        apartment: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
        country_code: ''
      },
      agreements: [
        {
          symbol: 'rules',
          accepted: false
        }
      ]
    },
    onSubmit: (values) => {
      createProfile(values);
    },
    validateOnChange: false,
    enableReinitialize: true
  });

  // opcje dropdownu typów pola
  const countryOptions = useMemo(() => {
    const countries = (articleCountries?.items || []).map((country) => ({
      value: country.code,
      label: <span>{country.name}</span>,
      item: country
    }));

    return countries;
  }, [articleCountries]);

  const Title: FC<ITitleProps> = ({ children, secondary }) => (
    <div className={classnames(styles.title, { [styles.secondary]: secondary })}>{children}</div>
  );

  const renderConsent = () => (
    <FormElement>
      <label className={styles.consent}>
        <Checkbox
          checked={values.agreements[0].accepted}
          onClick={() =>
            setFieldValue('agreements[0]', {
              symbol: 'rules',
              accepted: !values.agreements[0].accepted
            })
          }
        />
        <span className={styles.label}>
          <Trans>Oświadczam, że znam i akceptuję postanowienia Regulaminu.</Trans>
        </span>
      </label>
      <span className={styles.error}>{(errors.agreements?.[0] as any)?.accepted}</span>
    </FormElement>
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.accountWrapper}>
        <div className={styles.section}>
          <div className={styles.invoice}>
            <Title>
              <Trans>Dane do faktury</Trans>
            </Title>
          </div>
          <span className={styles.label}>
            <Trans>NIP/VAT EU</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.tax_number}
              onChange={(value) => setFieldValue('tax_number', value)}
              error={errors?.tax_number}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Nazwa firmy</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.billing_address.name}
              onChange={(value) => setFieldValue('billing_address.name', value)}
              error={errors?.billing_address?.name}
            />
          </FormElement>

          <span className={styles.label}>
            <Trans>Ulica</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.billing_address.street}
              onChange={(value) => setFieldValue('billing_address.street', value)}
              error={errors?.billing_address?.street}
            />
          </FormElement>

          <div className={styles.halfWidth}>
            <div>
              <span className={styles.label}>
                <Trans>Nr budynku</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.billing_address.building}
                  onChange={(value) => setFieldValue('billing_address.building', value)}
                  error={errors?.billing_address?.building}
                />
              </FormElement>
            </div>
            <div>
              <span className={styles.label}>
                <Trans>Nr lokalu</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.billing_address.apartment}
                  onChange={(value) => setFieldValue('billing_address.apartment', value)}
                  error={errors?.billing_address?.apartment}
                />
              </FormElement>
            </div>
          </div>
          <div className={styles.halfWidth}>
            <div>
              <span className={styles.label}>
                <Trans>Kod pocztowy</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.billing_address.postal_code}
                  onChange={(value) => setFieldValue('billing_address.postal_code', value)}
                  error={errors?.billing_address?.postal_code}
                />
              </FormElement>
            </div>
            <div>
              <span className={styles.label}>
                <Trans>Miasto</Trans>
              </span>
              <FormElement>
                <Input
                  type="text"
                  value={values.billing_address.city}
                  onChange={(value) => setFieldValue('billing_address.city', value)}
                  error={errors?.billing_address?.city}
                />
              </FormElement>
            </div>
          </div>
          <span className={styles.label}>
            <Trans>Kraj</Trans>
          </span>
          <FormElement>
            <div className={styles.selectWrapper}>
              <Select<IUsersCountryListItem>
                options={countryOptions}
                variant="small"
                value={values.billing_address.country_code}
                onChange={(item) => {
                  if (item?.code) {
                    setFieldValue('billing_address.country_code', item.code);
                  }
                }}
                error={errors?.billing_address?.country_code}
              />
            </div>
          </FormElement>
        </div>
        <div className={classnames(styles.section, styles.sectionMiddle)}>
          <Title>
            <Trans>Dane do wysyłki</Trans>
          </Title>
          <span className={styles.label}>
            <Trans>Nazwa firmy</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.receiver_address.name}
              onChange={(value) => setFieldValue('receiver_address.name', value)}
              error={errors?.receiver_address?.name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Ulica</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.receiver_address.street}
              onChange={(value) => setFieldValue('receiver_address.street', value)}
              error={errors.receiver_address?.street}
            />
          </FormElement>
          <div className={styles.halfWidth}>
            <FormElement>
              <span className={styles.label}>
                <Trans>Nr budynku</Trans>
              </span>
              <Input
                type="text"
                value={values.receiver_address.building}
                onChange={(value) => setFieldValue('receiver_address.building', value)}
                error={errors.receiver_address?.building}
              />
            </FormElement>
            <FormElement>
              <span className={styles.label}>
                <Trans>Nr lokalu</Trans>
              </span>
              <Input
                type="text"
                value={values.receiver_address.apartment}
                onChange={(value) => setFieldValue('receiver_address.apartment', value)}
                error={errors.receiver_address?.apartment}
              />
            </FormElement>
          </div>

          <div className={styles.halfWidth}>
            <FormElement>
              <span className={styles.label}>
                <Trans>Kod pocztowy</Trans>
              </span>
              <Input
                type="text"
                value={values.receiver_address.postal_code}
                onChange={(value) => setFieldValue('receiver_address.postal_code', value)}
                error={errors.receiver_address?.postal_code}
              />
            </FormElement>
            <FormElement>
              <span className={styles.label}>
                <Trans>Miasto</Trans>
              </span>
              <Input
                type="text"
                value={values.receiver_address.city}
                onChange={(value) => setFieldValue('receiver_address.city', value)}
                error={errors.receiver_address?.city}
              />
            </FormElement>
          </div>
          <span className={styles.label}>
            <Trans>Kraj</Trans>
          </span>
          <FormElement>
            <div className={styles.selectWrapper}>
              <Select<IUsersCountryListItem>
                options={countryOptions}
                variant="small"
                value={values.receiver_address.country_code}
                onChange={(item) => {
                  if (item?.code) {
                    setFieldValue('receiver_address.country_code', item.code);
                  }
                }}
                error={errors.receiver_address?.country_code}
              />
            </div>
          </FormElement>
          {!isMobile && renderConsent()}
        </div>
        <div className={styles.section}>
          <Title>
            <Trans>Dane kontaktowe</Trans>
          </Title>
          <span className={styles.label}>
            <Trans>Imię</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.first_name}
              onChange={(value) => setFieldValue('first_name', value)}
              error={errors.first_name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Nazwisko</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.last_name}
              onChange={(value) => setFieldValue('last_name', value)}
              error={errors.last_name}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Telefon</Trans>
          </span>
          <FormElement>
            <Input
              type="text"
              value={values.phone}
              onChange={(value) => setFieldValue('phone', value)}
              error={errors.phone}
            />
          </FormElement>
          <span className={styles.label}>
            <Trans>Email</Trans>
          </span>
          <Input
            placeholder={t('Email')}
            type="text"
            onChange={(value) => setFieldValue('email', value)}
            value={values.email}
            error={errors.email}
          />
          {process.env.REACT_APP_RECAPTHA_SITE_KEY && (
            <div className={styles.captcha}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTHA_SITE_KEY}
                onChange={(value) => setFieldValue('recaptcha_token', value)}
                hl={urlPrefix}
              />
              <span className={styles.error}>{errors.recaptcha_token}</span>
            </div>
          )}
          {isMobile && renderConsent()}
          <Button htmlType="submit" loading={isCreatingProfile}>
            <Trans>Zarejestruj się</Trans>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
