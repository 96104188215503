// slider produktów

import React, { FC } from 'react';
import Slider, { CustomArrowProps } from 'react-slick';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import classnames from 'classnames';

import { IProductListItem } from 'api/types';
import { ProductItem } from 'components/containers';

import styles from 'theme/components/containers/ProductSlider/ProductSlider.module.scss';

// typ danych wejściowych
interface IProps {
  products: IProductListItem[];
  isArrowHidden?: boolean;
}

const ProductSlider: FC<IProps> = ({ products }) => {
  const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <button
      {...props}
      className={
        'slick-prev slick-arrow' + (slideCount && currentSlide === 0 ? ' slick-disabled' : '')
      }>
      <ChevronLeft />
    </button>
  );

  const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <button
      {...props}
      className={
        'slick-next slick-arrow' +
        (slideCount && currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }>
      <ChevronRight />
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-ProductSlider'
      )}>
      <Slider {...settings} className={classnames(styles.slickSlider, 'customSlick')}>
        {products.map((product) => (
          <div className={styles.slide} key={product.id}>
            <ProductItem
              product={product}
              transparent
              withDynamicGallery
              withHoverEffect
              hideAvailability
              isSlider
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;
