// Hook odpowiedzialny za pobranie szczgółów zamówienia

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IStatus,
  IPaginationRequest,
  ISearchRequest,
  ITaxDetails
} from 'api/types';

interface IAttachment {
  type: 'shipping_letter' | 'shipping_protocol';
  url: string | null;
  name: string;
}

interface IDelivery {
  delivery_date: null | string;
  delivery_method_icon_image_url: string;
  delivery_method_id: number;
  delivery_method_name: string;
  shipping_date: null | string;
  status: string;
  status_formatted: string;
  tracking_number: string;
  tracking_url: string;
}

export interface HistoryItem {
  date: string;
  hour: string;
  status_type: number;
  status_message: string;
}

// typ zwracanych danych
export interface IResponse {
  attachments: IAttachment[];
  package_number: string;
  bank_account_number: string;
  document_name: string;
  delivery_cost_price_net: number;
  delivery_cost_price_net_formatted: string;
  delivery_cost_price_gross: number;
  delivery_cost_price_gross_formatted: string;
  has_document_files: boolean;
  id: number;
  currency: string;
  client_id: number;
  client_name: string;
  client_first_name: string;
  client_last_name: string;
  client_address: {
    name: string;
    street: string;
    building: string;
    apartment: string;
    city: string;
    postal_code: string;
    country_code: string;
    state: string;
  };
  client_email: string;
  client_tax_number: string;
  client_phone: string;
  receiver_id: number;
  receiver_name: string;
  receiver_name_to_show: string;
  receiver_first_name: string;
  receiver_last_name: string;
  receiver_address: {
    name: string;
    street: string;
    building: string;
    apartment: string;
    city: string;
    postal_code: string;
    country_code: string;
    state: string;
  };
  receiver_delivery_point: {
    type: string;
    symbol: string;
    address: {
      name: string;
      street: string;
      building: string;
      apartment: string;
      postal_code: string;
      city: string;
      state: string;
      country: string;
      country_code: string;
    };
  };
  receiver_email: string;
  receiver_phone: string;
  client_notes: string;
  trader_notes: string;
  payment_method: {
    id: number;
    name: string;
    image: string;
  };
  delivery_method: {
    id: number;
    name: string;
    image: string;
  };
  delivery_method_vmp: {
    id: number;
    name: string;
    image: string;
  };
  delivery: IDelivery[];
  create_date: string;
  status_type: number;
  status_message: string;
  value_net: number;
  value_gross: number;
  value_net_formatted: string;
  value_gross_formatted: string;
  status_history: HistoryItem[];
  status: IStatus;
  name: string;
  is_blocked: boolean;
  is_editable: boolean;
  dropshipping: boolean;
  show_information_to_pay: boolean;
  manual_cash_on_delivery_value: null | string;
  manual_cash_on_delivery_value_formatted: string;
  customer: {
    id: number;
    name: string;
    address: {
      street: string;
      building: string;
      apartment: string;
      postal_code: string;
      city: string;
      state: string;
      country: string;
      country_code: string;
    };
    nip: string;
    email: string;
    phone: string;
  };
  tax_details: ITaxDetails;
}

// parametry requestu do api
export type IRequest = IPaginationRequest &
  ISearchRequest & {
    showEditingData?: boolean;
  };

const getOrderVmp = (id: number, params?: IRequest): Promise<IResponse> =>
  axios.get(`/orders/${id}/vmp`, { params });

export const useGetOrderVmp = (
  id: number,
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) => useQuery<IResponse, IError>(['order', id], () => getOrderVmp(id, params), options);
