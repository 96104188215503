// slider z produktami kupionymi przez innych

import React, { FC } from 'react';
import classnames from 'classnames';
import { Grid } from '@mui/material';
import { Trans } from 'react-i18next';

import { ProductItem } from 'components/containers';
import { useGetProductsBoughtByOthers } from 'api';
import { useRWD } from 'hooks';
import { Collapse } from 'components/controls';

import styles from 'theme/pages/Product/components/OthersAlsoBought/OthersAlsoBought.module.scss';

// typ danych wejściowych
interface IProps {
  productId: number;
}

const OthersAlsoBought: FC<IProps> = ({ productId }) => {
  const { isMobile } = useRWD();

  // pobranie listy produktów oglądanych prze3z innnych
  const { data: productsBoughtByOthersData } = useGetProductsBoughtByOthers(productId, {
    page: 1,
    limit: 999
  });

  const renderContent = () => {
    if (!productsBoughtByOthersData?.items.length) {
      return null;
    }

    return (
      <>
        <div className={styles.title}>
          <Trans>Zobacz także</Trans>
        </div>
        <Grid container columnSpacing="21px" rowSpacing="24px">
          {productsBoughtByOthersData?.items.map((product) => (
            <Grid key={product.id} item md={4}>
              <ProductItem key={product.id} product={product} />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  if (!productsBoughtByOthersData) {
    return null;
  }

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        { [styles.empty]: !productsBoughtByOthersData?.items.length },
        'StylePath-Page-Product-components-OthersAlsoBought'
      )}>
      {isMobile && !!productsBoughtByOthersData?.items.length ? (
        <Collapse
          title={
            <div className={styles.collapseTitle}>
              <Trans>Zobacz także</Trans>
            </div>
          }>
          {renderContent()}
        </Collapse>
      ) : (
        renderContent()
      )}
    </div>
  );
};

export default OthersAlsoBought;
