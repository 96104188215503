// lista zamówień

import React, { FC, useState, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useGetDashboardOrdersSummaryVmp } from 'api';
import classnames from 'classnames';

import { ISettlementsSummary } from 'api/types';
import { useGetDashboardOrdersSummaryPeriodTimeVmp } from 'api/endpoints';
import { Select } from 'components/controls';

import styles from 'theme/pages/Dashboard/Dashboard.module.scss';

interface ISelectItem {
  value: string;
}

// typ danych wejściowych
interface IProps {
  tradeCredit?: ISettlementsSummary['trade_credit'];
}

const Orders: FC<IProps> = ({ tradeCredit }) => {
  const [selectValue, setSelectValue] = useState<string>('');

  // pobieranie listy filtrów
  const { data: periodList } = useGetDashboardOrdersSummaryPeriodTimeVmp();

  // pobranie podsumowania zamówień
  const { data: dashboardOrdersSummaryData, refetch: refetchDashboardOrdersSummaryData } =
    useGetDashboardOrdersSummaryVmp({ periodTime: selectValue }, { enabled: false });

  useEffect(() => {
    setSelectValue(periodList?.types[0].type || '');
  }, [periodList]);

  useEffect(() => {
    if (selectValue) {
      refetchDashboardOrdersSummaryData();
    }
  }, [selectValue]);

  // opcje dropdownu
  const selectOptions = useMemo(() => {
    const types = periodList
      ? (periodList.types || []).map((type) => ({
          value: type.type,
          label: <span>{type.name}</span>,
          item: { value: type.type }
        }))
      : [];

    return types;
  }, [periodList]);

  return (
    <div className={classnames(styles.orders, 'StylePath-Pages-Dashboard-Components-Orders')}>
      <div className={styles.tile}>
        <div className={styles.title}>
          <Trans>Limit kupiecki</Trans>
        </div>
        <div className={styles.row}>
          <span>
            <Trans>Całkowity limit</Trans>
          </span>
          <span>
            <Trans>Wykorzystany</Trans>
          </span>
        </div>
        <div className={styles.row}>
          <div>
            {tradeCredit?.trade_credit_total} {tradeCredit?.currency} <Trans>brutto</Trans>
          </div>
          <div className={styles.usedLimit}>
            {tradeCredit?.trade_credit_free} {tradeCredit?.currency}{' '}
            <span className={styles.brutto}>
              <Trans>brutto</Trans>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.tile}>
        <div className={styles.title}>
          <Trans>Podsumowanie zamówień</Trans>

          <Select<ISelectItem>
            options={selectOptions}
            value={selectValue}
            onChange={(item) => setSelectValue(item?.value || '')}
          />
        </div>
        <div className={styles.row}>
          <span>
            <Trans>Razem</Trans>
          </span>
          <span>
            <Trans>Wartość</Trans>
          </span>
        </div>
        <div className={styles.row}>
          <div className={styles.orderSummary}>
            <span>
              <Trans>Zrealizowane</Trans>:{' '}
              <span className={styles.totalCount}>
                {dashboardOrdersSummaryData?.realized_count}
              </span>
            </span>
            &nbsp;
            <span>
              <Trans>Opłacone</Trans>:{' '}
              <span className={styles.paidCount}>{dashboardOrdersSummaryData?.paid_count}</span>
            </span>
            &nbsp;
            <span>
              <Trans>Nieopłacone</Trans>:{' '}
              <span className={styles.expiredCount}>
                {dashboardOrdersSummaryData?.to_pay_count}
              </span>
            </span>
          </div>
          <div>
            {dashboardOrdersSummaryData?.total_value_formatted.replace('.', ',')}{' '}
            {dashboardOrdersSummaryData?.currency}{' '}
            <span className={styles.brutto}>
              <Trans>brutto</Trans>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
