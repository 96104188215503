import React, { FC, useCallback, useEffect } from 'react';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import classnames from 'classnames';
import each from 'lodash/each';
import find from 'lodash/find';

import { useSelector } from 'store';
import { useAppNavigate } from 'hooks';
import { Container, Breadcrumbs } from 'components/controls';
import { DashboardMenu } from 'components/containers';

import styles from 'theme/components/layouts/DashboardSubLayout/SubLayoutDashboard.module.scss';

// typ danych wejściowych
interface IProps {
  urlPrefix: string;
}

interface IRoute {
  label: string;
  path: string;
  key: string | null;
}

export const userRolesAccessRoutes = {
  ROLE_USER_MAIN: {
    orders: [{ label: 'Lista zamówień', path: '/dashboard/orders', key: 'orders' }],
    documents: [{ label: 'Moje dokumenty', path: '/dashboard/documents', key: 'documents' }],
    finances: [{ label: 'Finanse', path: '/dashboard/finances', key: 'finances' }],
    favourites: [{ label: 'Ulubione', path: '/dashboard/favourites', key: 'favourites' }],
    myData: [{ label: 'Moje konto', path: '/dashboard/my-account', key: 'my-account' }],
    admin: []
  },
  ROLE_USER: {
    orders: [{ label: 'Lista zamówień', path: '/dashboard/orders', key: 'orders' }],
    documents: [{ label: 'Moje dokumenty', path: '/dashboard/documents', key: 'documents' }],
    finances: [{ label: 'Finanse', path: '/dashboard/finances', key: 'finances' }],
    favourites: [{ label: 'Ulubione', path: '/dashboard/favourites', key: 'favourites' }],
    myData: [{ label: 'Moje konto', path: '/dashboard/my-account', key: 'my-account' }],
    admin: []
  },
  ROLE_ADMIN: {
    orders: [{ label: 'Lista zamówień', path: '/dashboard/orders', key: 'orders' }],
    documents: [{ label: 'Moje dokumenty', path: '/dashboard/documents', key: 'documents' }],
    finances: [{ label: 'Finanse', path: '/dashboard/finances', key: 'finances' }],
    favourites: [{ label: 'Ulubione', path: '/dashboard/favourites', key: 'favourites' }],
    myData: [{ label: 'Moje konto', path: '/dashboard/my-account', key: 'my-account' }],
    admin: [
      { label: 'Lista kontrahentów', path: '/dashboard/clients', key: 'clients' },
      { label: 'Lista sekcji', path: '/dashboard/cms/sections', key: 'sections' },
      { label: 'Lista artykułów', path: '/dashboard/cms/articles', key: 'articles' },
      { label: 'Media', path: '/dashboard/cms/media', key: 'media' }
    ]
  },
  ROLE_TRADER: {
    orders: [{ label: 'Lista zamówień', path: '/dashboard/orders', key: 'orders' }],
    documents: [{ label: 'Moje dokumenty', path: '/dashboard/documents', key: 'documents' }],
    finances: [{ label: 'Finanse', path: '/dashboard/finances', key: 'finances' }],
    favourites: [{ label: 'Ulubione', path: '/dashboard/favourites', key: 'favourites' }],
    myData: [{ label: 'Moje konto', path: '/dashboard/my-account', key: 'my-account' }],
    admin: []
  },
  ROLE_OPEN_PROFILE: {
    orders: [],
    documents: [],
    finances: [],
    favourites: [],
    myData: [],
    admin: []
  }
};

const SubLayoutDashboard: FC<IProps> = ({ urlPrefix }) => {
  const { profile } = useSelector((state) => state.auth);
  const navigate = useAppNavigate();

  // ścieżka z url
  const { pathname } = useLocation();

  // czy użytkownik ma dostęp do podstrony
  const allowRoute = useCallback(() => {
    let arr: IRoute[] = [{ label: 'Dashboard', path: '/dashboard', key: null }];

    each(
      userRolesAccessRoutes[`${profile?.role || 'ROLE_OPEN_PROFILE'}`],
      (route) => (arr = [...arr, ...route])
    );

    return !!find(arr, (route) => route.key && pathname.includes(route.key));
  }, [pathname]);

  useEffect(() => {
    if (pathname === `${urlPrefix}/dashboard`) {
      return;
    }

    if (!allowRoute()) {
      navigate('/dashboard');
    }
  }, [pathname]);

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-DashboardSubLayout'
      )}>
      <Container>
        {profile?.role && <DashboardMenu accessRoutes={userRolesAccessRoutes[`${profile.role}`]} />}
        <Breadcrumbs />
        <Grid container columnSpacing="32px">
          <Grid item xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SubLayoutDashboard;
