import React, { FC } from 'react';

import { IDynamicAction, IComponentParams } from 'plugins/api/types';
import { useCallCommand } from 'plugins/api';

import { Button } from 'components/controls';

// typ danych wejściowych
interface IProps {
  action: IDynamicAction;
  handleClose: () => void;
  componentParams?: IComponentParams;
  requestParams?: any;
  requestState?: any;
}

const Action: FC<IProps> = ({
  action,
  handleClose,
  requestParams,
  requestState,
  componentParams
}) => {
  const { mutate: callCommand } = useCallCommand(action.method_params?.command_url || '', {
    onSuccess: () => {
      if (action.refresh_data_mode === 'ONSUCCESS') {
        componentParams?.refetchFn();
        handleClose();
      }
    }
  });

  const handleAction = () => {
    if (action.method === 'Cancel') {
      handleClose();
    }

    if (action.method === 'CallCommand') {
      const paramsList = action.method_params?.params_list.split(';');

      let request = {};

      paramsList?.map((param: string) => {
        if (requestState && requestState[param]) {
          return (request = { ...request, [param]: requestState[param] });
        }

        if (requestParams && requestParams[param]) {
          return (request = { ...request, [param]: requestParams[param] });
        }

        request = { ...request, [param]: null };
      });

      callCommand(request);
    }
  };

  return <Button onClick={handleAction}>{action.label}</Button>;
};

export default Action;
