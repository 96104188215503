import React from 'react';
import { Trans } from 'react-i18next';
import { ChevronRight } from 'react-bootstrap-icons';

import { useAppNavigate } from 'hooks';
import { useGetOnlinePaymentStatus } from 'api';

import { Container, Button } from 'components/controls';

import styles from 'theme/pages/Checkout/components/Confirmation/Confirmation.module.scss';

// nazwa pola z tokenem w localStorage
const ONLINEPAYMENT_HASH = 'ONLINEPAYMENT_HASH';

const PaymentStatus = () => {
  const hash = localStorage.getItem(ONLINEPAYMENT_HASH);
  const navigate = useAppNavigate();

  const { data: onlinePaymentStatus } = useGetOnlinePaymentStatus(hash || '');

  const handleButtonClick = () => {
    navigate('/dashboard/orders');
  };

  return (
    <div className={styles.componentWrapper}>
      <Container>
        <div className={styles.contentHeader} />
        <div className={styles.heading}>
          <h1>
            <Trans>Podsumowanie zamówienia</Trans>
          </h1>
        </div>

        <div className={styles.content}>
          <div>
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: onlinePaymentStatus?.description_html || '' }}
              />
              <Button onClick={() => handleButtonClick()}>
                <Trans>Przejdź do zamówień</Trans>
                <ChevronRight />
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PaymentStatus;
