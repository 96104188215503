// Hook odpowiedzialny za aktualizację dodatkowych opcji

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

interface IOption {
  symbol: string;
  value: boolean;
}

// parametry requestu do api
interface IRequest {
  options: IOption[];
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const putCartDeliveryOptions = (cartId: number, data: IRequest): Promise<IResponse> =>
  axios.put(`/carts/${cartId}/delivery-options`, data);

export const usePutCartDeliveryOptions = (
  cartId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => putCartDeliveryOptions(cartId, data), options);
