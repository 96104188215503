import React, { FC, useState, Dispatch, SetStateAction, ChangeEvent } from 'react';

import { IDynamicUiField } from 'plugins/api/types';

// typ danych wejściowych
interface IProps {
  field: IDynamicUiField;
  setRequestState: Dispatch<SetStateAction<object>>;
}

const DynamicDecimal: FC<IProps> = ({ field, setRequestState }) => {
  // ustawianie wartości
  const [value, setValue] = useState<string>(field.value || '');

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestState((prevState: object) => ({
      ...prevState,
      [field.symbol]: parseFloat(e.target.value)
    }));
    setValue(e.target.value);
  };

  return (
    <div className="dynamicDecimal">
      <span>{field.label}</span>
      <input type="number" value={value} onChange={(e) => onChangeHandler(e)} />
    </div>
  );
};

export default DynamicDecimal;
