// Hook odpowiedzialny za pobranie listy metod dostawy koszyka

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse
} from 'api/types';

interface IAdditionalInfo {
  symbol: string;
  value: string;
}

export interface IAdditionalType {
  parameter_definition: {
    label: string;
    symbol: string;
    type: string;
  };
  additional_info?: IAdditionalInfo[];
  value: boolean;
}

export interface ICartDeliveryMethodListItem {
  id: number;
  provider: string;
  free_delivery: boolean;
  delivery_time: string | null;
  delivery_time_style: string | null;
  free_delivery_from: string;
  free_delivery_from_message: string;
  missing_to_free_delivery: string | null;
  missing_to_free_delivery_message: string | null;
  image: string;
  name: string;
  price_gross: number;
  price_gross_formatted: string;
  price_net: number;
  price_net_formatted: string;
  currency: string;
  style_type: string;
  additional_options: IAdditionalType[];
  parcel_point_widget: 'inpost' | 'pocztex' | 'dpd_pickup';
  suggest: string;
  suggest_style: 'danger' | 'success';
}

// parametry requestu do api
export type IRequest = IPaginationRequest;

// typ zwracanych danych
export type IResponse = IPaginationResponse<ICartDeliveryMethodListItem>;

const getCartDeliveryMethods = (id: number, params?: IRequest): Promise<IResponse> =>
  axios.get(`/carts/${id}/delivery-methods`, { params });

export const useGetCartDeliveryMethods = (
  id: number,
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['cart-delivery-methods', id, params],
    () => getCartDeliveryMethods(id, params),
    options
  );
