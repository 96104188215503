// Hook odpowiedzialny za pobranie koszyka

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, ITaxDetails } from 'api/types';

// typ zwracanych danych
export interface IResponse {
  cart_id: number;
  cart_price_gross?: number;
  cart_price_gross_formatted?: string;
  cart_price_net?: number;
  cart_price_net_formatted?: string;
  delivery_price?: number;
  delivery_price_formatted?: string;
  payment_method?: string;
  currency: string;
  free_delivery_gross?: number;
  free_delivery_gross_formatted?: string;
  merchant_limit_net?: number;
  merchant_limit_net_formatted?: string;
  merchant_limit_gross?: number;
  merchant_limit_gross_formatted?: string;
  missing_to_order_net?: number;
  missing_to_order_net_formatted?: string;
  missing_to_order_gross?: number;
  missing_to_order_gross_formatted?: string;
  total_price_gross?: number;
  total_price_gross_formatted?: string;
  total_price_net?: number;
  total_price_net_formatted?: string;
  total_old_price_net: number | null;
  total_old_price_gross: number | null;
  total_old_price_net_formatted: string | null;
  total_old_price_gross_formatted: string | null;
  total_discount_gross: number | null;
  total_discount_net: number | null;
  total_discount_gross_formatted: string | null;
  total_discount_net_formatted: string | null;
  positions_base_value_gross: number;
  positions_base_value_net: number;
  positions_base_value_gross_formatted: string;
  positions_base_value_net_formatted: string;
  positions_value_gross_formatted: string;
  positions_value_net_formatted: string;
  tax_value: number;
  gross_value: number;
  gross_value_formatted: string;
  upcoming_invoice_payments_response: string[] | null;
  tax_details: ITaxDetails;
  has_free_delivery: boolean;
  amount_to_free_delivery: number;
  amount_to_free_delivery_formatted: string;
  value_base_gross_with_services_formatted: string;
  value_base_net_with_services_formatted: string;
}

const getCart = (id: number): Promise<IResponse> => axios.get(`/carts/${id}`);

export const useGetCart = (id: number, options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['cart', id], () => getCart(id), options);
