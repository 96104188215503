import React, { FC, useState, Dispatch, SetStateAction } from 'react';

import { IDynamicUiField, IDictionaryValue } from 'plugins/api/types';
import { Select } from 'components/controls';

// typ danych wejściowych
interface IProps {
  field: IDynamicUiField;
  setRequestState: Dispatch<SetStateAction<object>>;
}

const DynamicSelect: FC<IProps> = ({ field, setRequestState }) => {
  // ustawianie wartości
  const [value, setValue] = useState<string | undefined>(field.value);

  const onChangeHandler = (item: IDictionaryValue) => {
    setRequestState((prevState: object) => ({ ...prevState, [field.symbol]: item.value }));
    setValue(item.value.toString());
  };

  return (
    <div className="dynamicSelect">
      <span>{field.label}</span>
      <Select
        value={value?.toString()}
        options={field.dictionary.values.map((value) => ({
          value: value.value.toString(),
          label: value.text,
          item: value
        }))}
        onChange={(item) => item && onChangeHandler(item)}
      />
    </div>
  );
};

export default DynamicSelect;
