// sekcja z wyborem metody dostawy

import React, { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { InfoCircle, Truck } from 'react-bootstrap-icons';
import filter from 'lodash/filter';
import map from 'lodash/map';

import { usePutCartDeliveryOptions, usePutCartMainData, usePostCartValidate } from 'api';
import {
  ICartMainData,
  ICartMainDataUpdateRequest,
  IReceiverPoint,
  IAdditionalType,
  ICheckoutStep,
  ICartDeliveryMethodsResponse
} from 'api/types';
import { Attachment } from 'components/containers';
import { Radio, Collapse, Checkbox, Button, Loader } from 'components/controls';
import { GeoWidget } from './components';

import styles from 'theme/pages/Checkout/components/Delivery/Delivery.module.scss';

// typ danych wejściowych
interface IProps {
  cartId: number;
  deliveryMethod: ICartMainData['delivery_method'];
  deliveryPoint: ICartMainData['receiver_delivery_point'];
  updateCartMainData: (data: Partial<ICartMainDataUpdateRequest>) => void;
  setCheckoutStep: (step: ICheckoutStep) => void;
  refetchCartMainData: () => void;
  cartDeliveryMethodsData?: ICartDeliveryMethodsResponse;
  refetchDeliveryMethodsData: () => void;
  isLoading: boolean;
}

const Delivery: FC<IProps> = ({
  cartId,
  deliveryMethod,
  updateCartMainData,
  setCheckoutStep,
  deliveryPoint,
  refetchCartMainData,
  cartDeliveryMethodsData,
  refetchDeliveryMethodsData,
  isLoading
}) => {
  const { t } = useTranslation();

  // przeliczanie koszyka
  const { mutateAsync: validateCart } = usePostCartValidate(cartId || 0, {
    onSuccess: () => {
      setCheckoutStep('SUMMARY');
    }
  });

  // pobieranie metod dostaw przy pierwzej zmianie metody płatności
  useEffect(() => {
    refetchDeliveryMethodsData();
  }, [deliveryMethod?.id]);

  // aktualizacja main data koszyka
  const { mutate: putCartMainData } = usePutCartMainData(cartId, {
    onSuccess: () => {
      refetchCartMainData();
    }
  });

  // dodawanie dodatkowych opcji wysyłki
  const { mutate: updateCartDeliveryOptions } = usePutCartDeliveryOptions(cartId, {
    onSuccess: () => refetchDeliveryMethodsData()
  });

  const handleParcelLocker = (data: IReceiverPoint) => {
    putCartMainData({ receiver_delivery_point: data });
  };

  const renderAdditionalOptions = (
    option: IAdditionalType,
    additionalOptions?: IAdditionalType[]
  ) => {
    if (option.parameter_definition.type === 'attachment') {
      return (
        <div className={styles.attachment} key={option.parameter_definition.symbol}>
          <Attachment
            cartId={cartId}
            option={option}
            refetchDeliveryMethodsData={refetchDeliveryMethodsData}
          />
        </div>
      );
    }

    return (
      <div className={styles.additionalOption} key={option.parameter_definition.symbol}>
        <Checkbox
          checked={option.value}
          onClick={() => {
            const selectedOptions = map(
              filter(additionalOptions, (additionalOption) => {
                if (
                  additionalOption.value &&
                  additionalOption.parameter_definition.symbol !==
                    option.parameter_definition.symbol
                ) {
                  return additionalOption;
                }
              })
            ) as IAdditionalType[];

            updateCartDeliveryOptions({
              options: [
                ...map(selectedOptions, (o) => ({
                  symbol: o.parameter_definition.symbol,
                  value: o.value
                })),
                {
                  symbol: option.parameter_definition.symbol,
                  value: !option.value
                }
              ]
            });
          }}
        />{' '}
        {option.parameter_definition.label}
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Pages-Checkout-Components-Delivery'
      )}>
      <h2>
        <Trans>Wysyłka</Trans>
      </h2>
      <div className={styles.deliveriesWrapper}>
        {cartDeliveryMethodsData?.items.map((item) => (
          <div
            onClick={() => updateCartMainData({ delivery_method_id: item.id })}
            className={styles.itemWrapper}
            key={item.id}>
            <div
              className={classnames(styles.item, {
                [styles.active]: item.id === deliveryMethod?.id
              })}>
              <div className={styles.radio}>
                {isLoading ? <Loader /> : <Radio checked={item.id === deliveryMethod?.id} />}
              </div>

              <div className={styles.description}>
                <div className={styles.name}>
                  <img className={styles.image} src={item.image} alt="" /> {item.name}
                </div>
                {item.suggest && (
                  <div
                    className={classnames(styles.info, {
                      [styles.missingFreeDelivery]: item.suggest_style === 'danger',
                      [styles.freeDeliverySuccess]: item.suggest_style === 'success'
                    })}>
                    <InfoCircle /> {item.suggest}
                  </div>
                )}
                {item.free_delivery && (
                  <div className={classnames(styles.info, styles.freeDelivery)}>
                    <InfoCircle /> <Trans>Darmowa dostawa</Trans>
                  </div>
                )}

                {item.parcel_point_widget === 'inpost' && deliveryPoint?.type === 'inpost_locker' && (
                  <div className={classnames(styles.info)}>
                    <Trans>Wybrany punkt</Trans>: {deliveryPoint?.symbol}
                  </div>
                )}

                {item.parcel_point_widget === 'pocztex' &&
                  deliveryPoint?.type === 'pocztex_pickup' && (
                    <div className={classnames(styles.info)}>
                      <Trans>Wybrany punkt</Trans>: {deliveryPoint?.symbol}
                    </div>
                  )}

                {item.parcel_point_widget === 'dpd_pickup' && deliveryPoint?.type === 'dpd_pickup' && (
                  <div className={classnames(styles.info)}>
                    <Trans>Wybrany punkt</Trans>: {deliveryPoint?.symbol}
                  </div>
                )}

                {item.delivery_time && (
                  <div className={styles.time}>
                    <Truck /> {item.delivery_time}
                  </div>
                )}

                {!!item.additional_options?.length && (
                  <div
                    className={classnames(styles.additionalOptions, {
                      [styles.attachmentOptions]: item.provider === 'own_courier'
                    })}
                    onClick={(e) => e.stopPropagation()}>
                    {item.provider === 'own_courier' ? (
                      <div className={styles.attachments}>
                        {item.additional_options.map((option) => renderAdditionalOptions(option))}
                      </div>
                    ) : (
                      <div className={styles.additionalOptions}>
                        <Collapse title={t('Dodatkowe opcje wysyłki')}>
                          {item.additional_options.map((option) =>
                            renderAdditionalOptions(option, item.additional_options)
                          )}
                        </Collapse>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className={styles.price}>
                <div className={styles.priceGross}>
                  {item.price_net_formatted?.replace('.', ',')} {item.currency}{' '}
                  <span>
                    <Trans>netto</Trans>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <GeoWidget
        handleParcelLocker={handleParcelLocker}
        code={
          cartDeliveryMethodsData?.items.find((method) => method.id === deliveryMethod?.id)
            ?.provider
        }
      />
      <div className={styles.actions}>
        <Button ghost onClick={() => setCheckoutStep('ADDRESS')}>
          {t('Wstecz')}
        </Button>
        <Button
          onClick={() => validateCart({ verification_scope: 'paymentMethod|deliveryMethod' })}>
          {t('Dalej')}
        </Button>
      </div>
    </div>
  );
};

export default Delivery;
