// główny layout - górna belka nagłówka

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'react-bootstrap-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { usePostOverLogout } from 'api';
import { useDispatch, reduxActions, useSelector } from 'store';
import { CartsButton, Languages } from 'components/containers';
import { DropDown, OverLoginUsers } from 'components/controls';

import styles from 'theme/components/layouts/MainLayout/components/HeaderTopBar/HeaderTopBar.module.scss';
import LoginButton from 'components/containers/LoginButton';
import RegisterButton from 'components/containers/RegisterButton';
import ShoppingListButton from 'components/containers/ShoppingListButton';

// typ danych wejściowych
interface IProps {
  simpleHeader: boolean;
}

const HeaderTopBar: FC<IProps> = ({ simpleHeader }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile, overloginUserId } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { mutateAsync } = usePostOverLogout();

  // wylogowanie uźytkownika z systemu
  const handleLogout = async () => {
    if (overloginUserId) {
      await mutateAsync();

      if (pathname.includes('/cart/')) {
        navigate('/cart/0');
      }

      dispatch(reduxActions.setCurrentCartId(null));
      dispatch(reduxActions.setOverlogin(null));

      return;
    }
    dispatch(reduxActions.signOut());
  };

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        { [styles.simpleHeader]: simpleHeader },
        'StylePath-Components-Layouts-MainLayout-Components-HeaderTopBar'
      )}>
      <div className={styles.container}>
        <div className={styles.topBar}>
          <div>
            <Languages />
          </div>
          <div>
            <div className={styles.buttons}>
              {profile?.role !== 'ROLE_OPEN_PROFILE' && (
                <>
                  <div className={styles.myAccountButton}>
                    <DropDown
                      label={
                        <div className={styles.myAccount}>
                          <span>{t('Moje konto')}</span>
                          <ChevronDown className={styles.arrowDown} />
                        </div>
                      }
                      isListType
                      isMyAccount
                      items={[
                        {
                          label: t('Moje konto'),
                          onClick: () => navigate('/dashboard')
                        },
                        {
                          label: t('Zamówienia'),
                          onClick: () => navigate('/dashboard/orders')
                        },
                        {
                          label: t('Dokumenty i płatności'),
                          onClick: () => navigate('/dashboard/documents')
                        },
                        {
                          label: t('Finanse'),
                          onClick: () => navigate('/dashboard/finances')
                        },
                        // {
                        //   label: t('Ulubione'),
                        //   onClick: () => navigate('/dashboard/favourites')
                        // },
                        {
                          label: t('Moje dane'),
                          onClick: () => navigate('/dashboard/my-account')
                        },
                        {
                          label: overloginUserId ? t('Wróć na konto') : t('Wyloguj się'),
                          onClick: () => {
                            handleLogout();
                          }
                        }
                      ]}
                      withDropdownIcon={false}
                    />
                  </div>
                  <CartsButton />
                  {/* <ShoppingListButton /> */}
                </>
              )}
            </div>
            {profile?.role !== 'ROLE_OPEN_PROFILE' ? (
              !overloginUserId && profile?.role !== 'ROLE_USER' && <OverLoginUsers />
            ) : (
              <>
                <LoginButton />
                <RegisterButton />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopBar;
