// stopka głównego layoutu

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { usePostNewsletterRegister } from 'api';
import { useSelector } from 'store';
import { useRWD } from 'hooks';
import { Container, Button } from 'components/controls';
import { Link } from 'components/controls';

import styles from 'theme/components/layouts/MainLayout/components/Footer/Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.auth);
  const location = useLocation();
  const { isMobile } = useRWD();

  // ustawienie wartości w polu input
  const [email, setEmail] = useState('');

  // zapisanie emaila do newslettera
  const { mutate: newsletterRegister } = usePostNewsletterRegister({
    onSuccess: () => {
      setEmail('');
    }
  });

  const renderNewsletter = () => (
    <div className={styles.newsletter}>
      <div>
        <span className={styles.title}>
          <Trans>Zapisz się do naszego newslettera!</Trans>
        </span>
        <div className={styles.newsletterFormWrapper}>
          <input
            onChange={(e) => setEmail(e.target.value)}
            placeholder={`${t('Twój adres e-mail')}`}
          />
          <Button onClick={() => newsletterRegister({ email })}>
            <Trans>Zapisz się</Trans>
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-Footer'
      )}
      itemScope
      itemType="http://schema.org/Organization">
      <meta itemProp="url" content={window.location.href} />
      <Container>
        <div className={styles.contentWrapper}>
          <div className={styles.logoWrapper}>
            <img src={`${process.env.REACT_APP_API_BASE_URL}/files/logo.png`} />
            <meta
              itemProp="logo"
              content={`${process.env.REACT_APP_API_BASE_URL}/files/logo.png`}
            />
            <section>
              <ul>
                <li>
                  <p itemProp="name">VMP Group sp. z o.o.</p>
                  <p itemProp="location">ul. Zawiła 65E</p>
                  <p itemProp="location">30-390 Kraków</p>
                </li>
                <li>
                  <p itemProp="email">hurt@vmp.com.pl</p>
                  <p itemProp="telephone">+48 505 44 94 95</p>
                </li>
                <li>
                  <p>
                    NIP: <span itemProp="vatID">676-258-02-46</span>
                  </p>
                  <p>REGON: 385983116</p>
                  <p>KRS: 0000839593</p>
                </li>
              </ul>
            </section>
          </div>
          <div className={styles.footerSections}>
            <section>
              <span className={classnames(styles.title)}>
                <Trans>Informacje</Trans>
              </span>
              <ul>
                <li>
                  <Link to="/cms/SUBPAGE/O_NAS">
                    <Trans>O nas</Trans>
                  </Link>
                </li>
                <li>
                  <Link to="/cms/SUBPAGE/REGULAMIN">
                    <Trans>Regulamin</Trans>
                  </Link>
                </li>
                <li>
                  <Link to="/cms/SUBPAGE/POLITYKA_PRYWATNOSCI">
                    <Trans>Polityka prywatności</Trans>
                  </Link>
                </li>
                <li>
                  <Link to="/cms/SUBPAGE/ZAMOWIENIA_WYSYLKA">
                    <Trans>Zamówienia i wysyłka</Trans>
                  </Link>
                </li>
                <li>
                  <Link to="/cms/SUBPAGE/PLATNOSCI">
                    <Trans>Płatności</Trans>
                  </Link>
                </li>
                <li>
                  <Link to="/cms/SUBPAGE/INTEGRACJA">
                    <Trans>Integracja</Trans>
                  </Link>
                </li>
                <li>
                  <Link to="/cms/SUBPAGE/DROPSHIPPING">
                    <Trans>Dropshipping</Trans>
                  </Link>
                </li>
              </ul>
            </section>
            <section>
              <span className={classnames(styles.title)}>
                <Trans>Moje konto</Trans>
              </span>
              <ul>
                {profile?.role === 'ROLE_OPEN_PROFILE' ? (
                  <>
                    <li>
                      <Link
                        to={`/login?return_url=${encodeURIComponent(
                          location.pathname + location.search
                        )}`}>
                        <Trans>Zaloguj się</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to="/register">
                        <Trans>Załóż konto</Trans>
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/dashboard">
                        <Trans>Panel klienta</Trans>
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard/orders">
                        <Trans>Historia zamówień</Trans>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </section>

            <section>
              <span className={classnames(styles.title)}>
                <Trans>Oferta</Trans>
              </span>
              <ul>
                <li>
                  <Link to="/products?mode=CONTRACTING">
                    <Trans>Kontraktacje</Trans>
                  </Link>
                </li>
                <li>
                  <Link to="/cms/SUBPAGE/KATALOGI">
                    <Trans>Katalogi</Trans>
                  </Link>
                </li>
                <li>
                  <Link to="/products?mode=PROMOTIONS">
                    <Trans>Promocje</Trans>
                  </Link>
                </li>
                {profile?.role !== 'ROLE_OPEN_PROFILE' && (
                  <li>
                    <Link to="/cms/SUBPAGE/PLIKI_DO_POBRANIA">
                      <Trans>Pliki do pobrania</Trans>
                    </Link>
                  </li>
                )}
              </ul>
            </section>
            {!isMobile && renderNewsletter()}
          </div>
          {isMobile && renderNewsletter()}
        </div>
      </Container>
    </div>
  );
};

export default Footer;
