// Hook odpowiedzialny za pobranie podsumowania na dashboardzie

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
export interface IResponse {
  settlements: {
    paid: {
      pln: {
        count: number;
        total: number;
        total_formatted: string;
        currency: string;
      };
    };
    expired: {
      pln: {
        count: number;
        total: number;
        total_formatted: string;
        currency: string;
      };
    };
    upcoming: {
      pln: {
        count: number;
        total: number;
        total_formatted: string;
        currency: string;
      };
    };
    cancel: {
      pln: {
        count: number;
        total: number;
        total_formatted: string;
        currency: string;
      };
    };
  };
  trade_credit: {
    trade_credit_total: number;
    trade_credit_free: number;
    currency: string;
  };
}

const getDashboardSettlementsSummaryVmp = (): Promise<IResponse> =>
  axios.get('dashboard/settlements/summary/vmp');

export const useGetDashboardSettlementsSummaryVmp = (
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['dashboard-settlements-summary-vmp'],
    getDashboardSettlementsSummaryVmp,
    options
  );
