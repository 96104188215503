// pozycje zamówinenia

import React, { FC, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import {
  useDeleteOrderEditPositions,
  useGetOrderPositions,
  usePostOrderEditPosition,
  usePostOrderEditPositionQuantityIncrement,
  usePostOrderEditPositionQuantityDecrement
} from 'api';
import { IOrderPositionListItem } from 'api/types';
import { Checkbox, Counter, Link } from 'components/controls';
import Table, { IColumn, ISorter } from 'components/controls/Table';

import { TrashIcon, TrashGrayIcon } from 'assets/icons';
import styles from 'theme/pages/Order/components/OrderPositions/OrderPositions.module.scss';

// typ danych wejściowych
interface IProps {
  orderId: number;
  onChange: () => void;
  isEditingMode: boolean;
}

const OrderPositions: FC<IProps> = ({ orderId, onChange, isEditingMode }) => {
  // zaznaczone pozycje
  const [checkedItemIds, setCheckedItemIds] = useState<number[]>([]);

  // parametry zapytania API
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 999,
    sort_method: ''
  });

  // informacja o sortowaniu listy
  const [sorter, setSorter] = useState<ISorter<IOrderPositionListItem>>({
    by: 'name',
    direction: 'asc'
  });

  // pobranie listy pozycji
  const {
    data: orderPositionsData,
    isRefetching: isPositionsRefetching,
    refetch: refetchOrderPositions
  } = useGetOrderPositions(orderId, {
    ...queryParams,
    showEditingData: isEditingMode
  });

  // aktualizacja konkretnej pozycji (zmiana ilości)
  const { mutate: updatePosition, isLoading: isSetQuantityLoading } =
    usePostOrderEditPosition(orderId);

  // zwiększenie ilości konkretnej pozycji
  const { mutate: increaseQuantity, isLoading: isIncrementQuantityLoading } =
    usePostOrderEditPositionQuantityIncrement(orderId);

  // zmniejszenie ilości konkretnej pozycji
  const { mutate: decreaseQuantity, isLoading: isDecrementQuantityLoading } =
    usePostOrderEditPositionQuantityDecrement(orderId);

  // skasowanie pozycji z koszyka
  const { mutate: deleteOrderPositions } = useDeleteOrderEditPositions(orderId, {
    onSuccess: () => {
      onChange?.();
      refetchOrderPositions();
      setCheckedItemIds([]);
    }
  });

  // funkcja wrapująca aktualizację ilości
  const handleChangeQuantity = (
    position: IOrderPositionListItem,
    newValue: number | null,
    isIncrement?: boolean
  ) => {
    const options = {
      onSuccess: () => {
        onChange?.();
        refetchOrderPositions();
      }
    };

    newValue === null
      ? isIncrement
        ? increaseQuantity(
            {
              positionId: position.id
            },
            options
          )
        : decreaseQuantity(
            {
              positionId: position.id
            },
            options
          )
      : updatePosition(
          {
            value: newValue,
            position_id: position.id,
            order_id: orderId
          },
          options
        );
  };

  const handleDeletePositions = () => {
    const positions = checkedItemIds.map((id) => {
      return {
        id
      };
    });

    deleteOrderPositions({
      order_id: orderId,
      positions
    });
  };

  const columns: IColumn<IOrderPositionListItem>[] = useMemo(
    () => [
      {
        title: '',
        key: 'checkbox',
        align: 'left',
        renderCell: (item) => {
          const isChecked = checkedItemIds.includes(item.id);

          if (isEditingMode) {
            return (
              <Checkbox
                size="small"
                checked={isChecked}
                onClick={() =>
                  setCheckedItemIds(
                    isChecked
                      ? checkedItemIds.filter((checkbox) => checkbox !== item.id)
                      : [...checkedItemIds, item.id]
                  )
                }
              />
            );
          }

          return null;
        },
        width: 30
      },
      {
        title: <Trans>Produkt</Trans>,
        align: 'left',
        key: 'product-image',
        renderCell: (item) => (
          <div className={styles.productThumb}>
            <Link to={`/${item.url_link}`}>
              <img src={item.image[0]?.thumb} alt={item.name} />
            </Link>
          </div>
        ),
        width: 78
      },
      {
        title: '',
        dataIndex: 'name',
        align: 'left',
        renderCell: (item) => (
          <div className={styles.productInfo}>
            <span className={styles.producerName}>{item.producer_name}</span>
            <Link to={`/${item.url_link}`}>
              <span className={styles.productName}>{item.name}</span>
            </Link>
            <span className={styles.productSize}>
              <Trans>Rozmiar</Trans>: {item.size}
            </span>
            {item.color && (
              <span className={styles.productSize}>
                <Trans>Kolor</Trans>: {item.color}
              </span>
            )}
          </div>
        ),
        width: 200
      },
      {
        title: <Trans>Cena netto</Trans>,
        dataIndex: 'price_net',
        sortBy: 'price_net',
        align: 'center',
        renderCell: (item) => {
          return (
            <div className={styles.priceNet}>
              {item.old_price_net_formatted && (
                <div className={classnames(styles.productPrice, styles.oldPrice)}>
                  {item.old_price_net_formatted.replace('.', ',')} {item.currency}
                </div>
              )}
              <div
                className={classnames(
                  styles.productPrice,
                  item.old_price_net_formatted && styles.discount
                )}>
                {item.price_net_formatted.replace('.', ',')} {item.currency}
              </div>
            </div>
          );
        }
      },
      {
        title: <Trans>Ilość</Trans>,
        dataIndex: 'quantity',
        sortBy: 'quantity',
        align: 'center',
        renderCell: (item) => {
          if (isEditingMode) {
            return (
              <Counter
                onChange={(value) => handleChangeQuantity(item, value)}
                onIncrease={() => handleChangeQuantity(item, null, true)}
                onDecrease={() => handleChangeQuantity(item, null, false)}
                value={item.quantity}
                disabled={
                  isPositionsRefetching ||
                  isSetQuantityLoading ||
                  isIncrementQuantityLoading ||
                  isDecrementQuantityLoading
                }
              />
            );
          }

          return <strong>{item.quantity}</strong>;
        },
        width: 150
      },
      {
        title: <Trans>Wartość netto</Trans>,
        dataIndex: 'total_price_net',
        sortBy: 'total_price_net',
        align: 'center',
        renderCell: (item) => (
          <>
            {item.total_old_price_net_formatted && (
              <div className={classnames(styles.productTotalPrice, styles.oldPrice)}>
                {item.total_old_price_net_formatted.replace('.', ',')} {item.currency}
              </div>
            )}
            <div
              className={classnames(
                styles.productTotalPrice,
                item.total_old_price_net_formatted && styles.discount
              )}>
              {item.total_price_net_formatted.replace('.', ',')} {item.currency}{' '}
            </div>
          </>
        )
      },
      {
        title: <Trans>VAT</Trans>,
        dataIndex: 'tax_percent',
        align: 'center',
        renderCell: (item) => <div className={styles.productTotalPrice}>{item.tax_percent} %</div>
      },
      {
        title: <Trans>Wartość brutto</Trans>,
        dataIndex: 'total_price_gross',
        sortBy: 'total_price_gross',
        align: 'center',
        renderCell: (item) => (
          <>
            {item.total_old_price_gross_formatted && (
              <div className={classnames(styles.productTotalPrice, styles.oldPrice)}>
                {item.total_old_price_gross_formatted.replace('.', ',')} {item.currency}
              </div>
            )}
            <div
              className={classnames(
                styles.productTotalPrice,
                item.total_old_price_gross_formatted && styles.discount
              )}>
              {item.total_price_gross_formatted.replace('.', ',')} {item.currency}{' '}
            </div>
          </>
        )
      },
      {
        title: '',
        key: 'actions',
        align: 'center',
        renderCell: (item) => {
          if (isEditingMode) {
            return (
              <div className={styles.actionsWrapper}>
                <button
                  onClick={() =>
                    deleteOrderPositions({
                      order_id: orderId,
                      positions: [{ id: item.id }]
                    })
                  }>
                  <TrashIcon />
                </button>
              </div>
            );
          }

          return null;
        }
      }
    ],
    [
      checkedItemIds,
      setCheckedItemIds,
      orderPositionsData,
      isDecrementQuantityLoading,
      isIncrementQuantityLoading,
      isSetQuantityLoading,
      isPositionsRefetching
    ]
  );

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Order-components-OrderPositions'
      )}>
      <div className={styles.actionsBarWrapper}>
        {!!checkedItemIds.length && (
          <div>
            <button onClick={() => handleDeletePositions()}>
              <TrashGrayIcon /> <Trans>Usuń zaznaczone</Trans>
            </button>
          </div>
        )}
      </div>

      <div className={styles.tableWrapper}>
        <Table<IOrderPositionListItem>
          columns={columns}
          isOrder={true}
          dataSource={orderPositionsData?.items || []}
          rowKey="id"
          sorter={sorter}
          onSortChange={(sorter) => {
            setSorter(sorter);
            setQueryParams({
              ...queryParams,
              sort_method: `${sorter.by}_${sorter.direction}`
            });
          }}
        />
      </div>
    </div>
  );
};

export default OrderPositions;
