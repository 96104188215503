// Hook odpowiedzialny za aktualozację pozycji w koszyku

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
interface IRequest {
  value: number;
  order_id: number;
  position_id: number;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postOrderEditPosition = (
  orderId: number,
  positionId: number,
  data: IRequest
): Promise<IResponse> =>
  axios.post(`/order-edit/${orderId}/positions/${positionId}/quantity`, data);

export const usePostOrderEditPosition = (
  orderId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) =>
  useMutation((data: IRequest) => postOrderEditPosition(orderId, data.position_id, data), options);
