import React, { FC, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExclamationCircle } from 'react-bootstrap-icons';
import Popover from '@mui/material/Popover';

import { usePostCartImport, useGetCartImportTemplate } from 'api';
import { useRWD } from 'hooks';

import { BoxIcon, MiniCartIcon, Download } from 'assets/icons';

import styles from 'theme/components/containers/CartsButton/CartsButton.module.scss';

// typ danych wejściowych
interface IProps {
  productsCount: number;
  hasFreeDelivery?: boolean;
  freeDeliveryAmount?: number | null;
  currency?: string;
  refetchCartPositions: () => void;
  refetchCartsData: () => void;
}

const DeliveryInfo: FC<IProps> = ({
  productsCount,
  hasFreeDelivery,
  freeDeliveryAmount,
  currency,
  refetchCartPositions,
  refetchCartsData
}) => {
  const { t } = useTranslation();
  const { isMobile } = useRWD();

  // czy jest widoczny popover dla importuj
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null);

  // ref popover'a
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { mutate: importToCartImport } = usePostCartImport({
    onSuccess: () => {
      refetchCartsData();
      refetchCartPositions();
    }
  });

  const { refetch: downloadCartTemplate } = useGetCartImportTemplate({
    onSuccess: (data) => {
      const a = document.createElement('a');
      a.download = '';
      a.href = data.url_to_template;
      a.click();
    },
    enabled: false
  });

  const createBase64File = (file: Blob): Promise<string | undefined> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString().replace(/^data:(.*,)?/, ''));
      reader.onerror = (error) => reject(error);
    });

  const handleImport = async (files: FileList | null) => {
    const file = files?.[0];
    if (file) {
      const base64 = await createBase64File(file);

      importToCartImport({
        file: base64 as string,
        name: files[0].name
      });
    }
  };

  return (
    <>
      {productsCount > 0 ? (
        <div className={styles.info}>
          {freeDeliveryAmount !== null && (
            <>
              <BoxIcon />
              {hasFreeDelivery ? (
                <Trans>Dodano darmową wysyłkę!</Trans>
              ) : (
                <>
                  <Trans>Brakuje</Trans> {freeDeliveryAmount} {currency} {t('netto')}{' '}
                  <Trans>do darmowej wysyłki</Trans>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div className={styles.cartsImport}>
          <div className={styles.importWrapper}>
            <MiniCartIcon />
            <span ref={wrapperRef}>
              <label>
                <Trans>Importuj</Trans>
                <input type="file" onChange={(e) => handleImport(e.target.files)} />
              </label>
            </span>
            <ExclamationCircle
              title={t('Pobierz szablon pliku')}
              onClick={() => setPopoverAnchor(wrapperRef.current)}
            />
          </div>
          <Popover
            open={!!popoverAnchor && !isMobile}
            anchorEl={popoverAnchor}
            onClose={() => setPopoverAnchor(null)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}>
            <div className={styles.importTemplate} onClick={() => downloadCartTemplate()}>
              <span>
                <Trans>Pobierz szablon pliku</Trans>
              </span>
              <Download />
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default DeliveryInfo;
