import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from 'theme/components/containers/RegisterButton/RegisterButton.module.scss';

const RegisterButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-RegisterButton'
      )}>
      <button className={styles.authButton} onClick={() => navigate('/register')}>
        {t('Zarejestruj się')}
      </button>
    </div>
  );
};

export default RegisterButton;
