import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Input, Checkbox } from 'components/controls';

import styles from 'theme/pages/Clients/components/ClientForm/ClientForm.module.scss';

// typ danych wejściowych
interface IProps {
  label: string;
  checkboxName: string;
  inputName: string;
  checkboxValue: boolean;
  inputValue: string | null;
  setFieldValue: (arg1: string, arg2: string | boolean | null) => void;
}

const UserForm: FC<IProps> = ({
  label,
  setFieldValue,
  checkboxName,
  inputName,
  checkboxValue,
  inputValue
}) => {
  return (
    <div>
      <div className={styles.label}>
        <span>{label}</span>
        <div>
          <Checkbox
            checked={checkboxValue}
            onClick={() => setFieldValue(checkboxName, !checkboxValue)}
          />
          <Trans>Tak</Trans>
        </div>
      </div>
      <Input
        value={inputValue?.toString()}
        onChange={(input) => {
          setFieldValue(inputName, input);
        }}
        disabled={!checkboxValue}
      />
    </div>
  );
};

export default UserForm;
