// lista kategorii

import React, { FC, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { reduxActions, useDispatch } from 'store';
import { useRWD } from 'hooks';
import { useGetLayoutCategories } from 'api';
import { ICategoryListItem, ILayoutCategoriesRequest } from 'api/types';
import { Container, Collapse, Link } from 'components/controls';

import styles from 'theme/components/layouts/MainLayout/components/Categories/Categories.module.scss';

// typ danych wejściowych
interface Props {
  onCategoryClick?: () => void;
  columnsCount?: number;
  chosenCategoryId?: number;
  searchKeyword?: string;
  onClose?: () => void;
}

const Categories: FC<Props> = ({ searchKeyword, onCategoryClick, onClose }) => {
  const { isMobile } = useRWD();
  const dispatch = useDispatch();

  // parametry zapytania do api
  const [categoriesQuery, setCategoriesQuery] = useState<ILayoutCategoriesRequest>({
    page: 1,
    limit: 999,
    search_keyword: searchKeyword
  });

  // ustawienie aktywnej pozycji menu
  const [activeMenuItem, setActiveMenuItem] = useState<number | null>(null);

  // pobranie listy kategorii
  const { data: categoriesData, isLoading: isCategoryLoading } =
    useGetLayoutCategories(categoriesQuery);

  // aktualizacja parametrów zapytania do api przy zmianie frazy wyszukiwania
  useEffect(() => {
    setCategoriesQuery((prevState) => ({
      ...prevState,
      search_keyword: searchKeyword
    }));
  }, [searchKeyword]);

  // przypysanie efektu timeout do zmiennej
  let menuHoverTimeout: null | ReturnType<typeof setTimeout> = null;

  const setMenuHover = (id: number | null) => {
    if (menuHoverTimeout) {
      clearTimeout(menuHoverTimeout);
    }

    menuHoverTimeout = setTimeout(() => {
      setActiveMenuItem(id);
    }, 100);
  };

  const renderMainMenuPositions = useCallback(
    (category: ICategoryListItem) => {
      return (
        <div
          onMouseOver={() => setMenuHover(category.id)}
          onMouseOut={() => setMenuHover(null)}
          className={classnames(
            styles.firstLevel,
            activeMenuItem === category.id && !!category.subcategories.length && styles.active
          )}>
          {isMobile ? (
            <div className={styles.collapseWrapper}>
              <Collapse title={category.name}>
                <div>{renderCategoryTree(category.subcategories)}</div>
              </Collapse>
            </div>
          ) : (
            <>
              <span>
                <Link to={`/${category.url_link}`} onClick={() => handleCategoryClick(category)}>
                  {category.name}
                </Link>
              </span>

              {activeMenuItem === category.id && !!category.subcategories.length && (
                <div className={styles.menuWrapper}>
                  <Container>
                    <div className={styles.categoryWrapper}>
                      <div>{renderCategoryTree(category.subcategories)}</div>
                      {!isMobile && (
                        <div>
                          <a
                            href={category.image_redirect_url}
                            onClick={() => setActiveMenuItem(null)}>
                            {category.image && <img src={category.image} />}
                          </a>
                        </div>
                      )}
                    </div>
                  </Container>
                </div>
              )}
            </>
          )}
        </div>
      );
    },
    [activeMenuItem]
  );

  // funkcja renderująca drzewo kategorii
  const renderCategoryTree = useCallback(
    (category: ICategoryListItem[] = []) => {
      // funkcja rekurencyjna renderująca  listę wraz z rozwiniętymi podkategoriami
      const renderTree = (categories: ICategoryListItem[], level = 0) =>
        categories.map((category) => (
          <div
            key={category.id}
            className={classnames(styles.category, {
              [styles.main]: level === 0,
              [styles.secondary]: level !== 0,
              [styles.highlighted]: category.styleType === 'HIGHLIGHTED'
            })}>
            {level === 0 ? (
              <div className={styles.mainLevel}>
                <span>
                  <Link to={`/${category.url_link}`} onClick={() => handleCategoryClick(category)}>
                    {category.name}
                  </Link>
                </span>
              </div>
            ) : (
              <span>
                <Link to={`/${category.url_link}`} onClick={() => handleCategoryClick(category)}>
                  {category.name}
                </Link>
              </span>
            )}

            {category.subcategories_total_count > 0 &&
              renderTree(category.subcategories, level + 1)}
          </div>
        ));

      return renderTree(category);
    },
    [activeMenuItem]
  );

  const handleCategoryClick = (category: ICategoryListItem) => {
    onCategoryClick?.();
    setActiveMenuItem(null);
    dispatch(reduxActions.setCategoryId(category.id));
  };

  const handleModeClick = () => {
    dispatch(reduxActions.setCategoryId(undefined));
    dispatch(reduxActions.setSearchKeyword(''));
    onClose?.();
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Layouts-MainLayout-Components-Categories'
      )}>
      {categoriesData?.items.map((category, i) => {
        if (category.categories_list) {
          return category.categories_list.map((category) => (
            <div key={category.id}>{renderMainMenuPositions(category)}</div>
          ));
        }

        return (
          <div key={i}>
            <div
              className={classnames(styles.firstLevel, {
                [styles.news]: category.style === 'orange_style',
                [styles.promotion]: category.style === 'red_style'
              })}>
              <span>
                <Link onClick={handleModeClick} to={`/${category.url_link}`}>
                  {category.label}
                </Link>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Categories;
