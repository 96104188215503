// pojedynczy typ głównego filtra

import React, { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import map from 'lodash/map';

import { Collapse, Checkbox } from 'components/controls';
import { IProductsFilter, IProductsFilterValue } from 'api/types';
import { useRWD } from 'hooks';

import { CheckboxIcon, CheckboxCheckedIcon, SearchIcon } from 'assets/icons';

import styles from 'theme/pages/Products/components/MainFilters/MainFilters.module.scss';

interface IFilter {
  filter_id: string;
  filter_value: string;
  filter_type?: 'singlechoice' | 'multichoice' | string;
}

// typ danych wejściowych
interface IProps {
  filter: IProductsFilter;
  queryFilters: IFilter[];
  onChange: (filters: IFilter[], currentFilter: string) => void;
  clearFilters: (arg: string) => void;
  categoryId?: number;
  searchKeywords?: string;
}

const MainFilter: FC<IProps> = ({
  filter,
  queryFilters,
  onChange,
  clearFilters,
  categoryId,
  searchKeywords
}) => {
  const { t } = useTranslation();
  const { isMobile } = useRWD();

  // aktualnie wyszukiwana fraza
  const [search, setSearch] = useState('');

  // wybrana wyszukana fraza
  const [selectedSearch, setSelectedSearch] = useState(search);

  // lista przefiltrowanych wyników
  const [filteredResults, setFilteredResults] = useState<IProductsFilterValue[]>(filter.values);

  const visibleFiltersCount = 7;

  // resetoweanie szukanej frazy po zmianie kategorii
  useEffect(() => {
    setSearch('');
    setSelectedSearch('');
  }, [categoryId, searchKeywords]);

  // ustawienie domyślnych filtrów w sytuacji pobrania nowych filtrów
  useEffect(() => {
    setFilteredResults(
      filter.values.filter((o) => o.name.toUpperCase().match(search.toUpperCase()))
    );
  }, [filter.values]);

  const handleSetFilteredResults = useCallback(() => {
    setFilteredResults(
      filter.values.filter((o) => o.name.toUpperCase().match(search.toUpperCase()))
    );
    setSelectedSearch(search);
  }, [search]);

  const resetSearch = () => {
    setFilteredResults(filter.values);
    setSearch('');
    setSelectedSearch('');
  };

  const handleFilterChange = (value: IProductsFilterValue) => {
    const isSelected = queryFilters.some(
      (queryFilter) =>
        queryFilter.filter_id === filter.id &&
        queryFilter.filter_value.split(';').includes(value.value)
    );

    if (isSelected) {
      clearFilters(value.value);

      return;
    }

    ///sytuacja kiedy nie ma wybranych jeszcze żadnych filtrów
    if (!queryFilters.length) {
      onChange(
        [
          {
            filter_id: filter.id,
            filter_value: value.value,
            filter_type: filter.type
          }
        ],
        filter.id
      );

      return;
    }

    // sytuacja, kiedy już mamy jakiś filtr i wybieramy kolejny
    if (!queryFilters.find((assignedFilter) => assignedFilter.filter_id == filter.id)) {
      onChange(
        [
          ...queryFilters,
          {
            filter_id: filter.id,
            filter_value: value.value,
            filter_type: filter.type
          }
        ],
        filter.id
      );

      return;
    }

    // sytuacja, kiedy edytujemy już obecne filtry
    onChange(
      map(queryFilters, (assignedFilter) => {
        if (assignedFilter.filter_id === filter.id) {
          return {
            filter_id: filter.id,
            filter_value: value.value,
            filter_type: filter.type
          };
        }

        return assignedFilter;
      }),
      filter.id
    );
  };

  const renderFilteredResults = () => {
    return filteredResults.map((value) => (
      <div key={value.name}>
        <span className={styles.checkbox}>
          <Checkbox
            size="small"
            checked={queryFilters.some(
              (queryFilter) =>
                queryFilter.filter_id === filter.id &&
                queryFilter.filter_value.split(';').includes(value.value)
            )}
            onClick={() => handleFilterChange(value)}
            icon={<CheckboxIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
          />
        </span>
        <span>{value.name}</span>
      </div>
    ));
  };

  return (
    <Collapse
      customWidth={isMobile ? 200 : undefined}
      title={filter.label}
      collapsed={!queryFilters.find((queryFilter) => queryFilter.filter_id === filter.id)}>
      {filter.values.length > visibleFiltersCount && (
        <div className={styles.searchWrapper}>
          <div className={styles.search}>
            <input
              placeholder={`${t('Szukaj')}...`}
              defaultValue={search}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  handleSetFilteredResults();
                }
              }}
            />
            <button onClick={handleSetFilteredResults}>
              <SearchIcon />
            </button>
          </div>
          {selectedSearch && (
            <div className={styles.searchResult}>
              <Trans>Wyszukiwana fraza:</Trans> {selectedSearch}{' '}
              <button onClick={() => resetSearch()}>
                <Trans>Usuń</Trans>
              </button>
            </div>
          )}
        </div>
      )}

      <div className={styles.content}>
        {filteredResults.length ? (
          renderFilteredResults()
        ) : (
          <span className={styles.noResults}>
            <Trans>Brak wyników</Trans>
          </span>
        )}
      </div>
    </Collapse>
  );
};

export default MainFilter;
