// strona z listą list zakupowych

import React, { useEffect, useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { reduxActions, useDispatch } from 'store';
import { useGetShoppingListPositions, useDeleteShoppingListPosition } from 'api';
import { useRWD } from 'hooks';
import { IProductListItem } from 'api/types';
import { PageTitle, SearchInput, Button, ProgressBar } from 'components/controls';
import { ProductItem } from 'components/containers';

import { TrashGrayIcon } from 'assets/icons';

import styles from 'theme/pages/ShoppingLists/ShoppingLists.module.scss';

const ShoppingLists = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useRWD();

  // zaznaczone pozycje
  const [checkedItemIds, setCheckedItemIds] = useState<number[]>([]);

  // parametry zapytania do API o listy zakupowe
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 20,
    search_keyword: ''
  });

  // pozycje listy zakupowej
  const {
    data: shoppingListData,
    hasNextPage,
    fetchNextPage,
    status: positionsStatus,
    refetch: refetchShoppingListPositions
  } = useGetShoppingListPositions(queryParams);

  // skasowanie pozycji z listy zakupowej
  const { mutate: deleteShoppingListPosition } = useDeleteShoppingListPosition({
    onSuccess: () => {
      refetchShoppingListPositions();
    }
  });

  // ustawienie breadcrumbsów na starcie strony
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        { name: t('Moje konto'), path: '/dashboard' },
        { name: t('Ulubione') }
      ])
    );
  }, []);

  useEffect(() => {
    refetchShoppingListPositions();
  }, [queryParams]);

  const toggleCheckbox = (id: number) => {
    const isChecked = checkedItemIds.includes(id);

    setCheckedItemIds(
      isChecked ? checkedItemIds.filter((checkbox) => checkbox !== id) : [...checkedItemIds, id]
    );
  };

  // Przygotowanie listy produktów do wyświetlenia
  const positionList = useMemo(() => {
    const list: IProductListItem[] = [];

    shoppingListData?.pages.forEach((page) => {
      list.push(...page.items);
    });

    return list;
  }, [shoppingListData?.pages.length]);

  // liczba produktów aktualnie na stronie
  const itemsCount: number = positionList.length;

  // całkowita liczba produktów
  const totalCount: number = shoppingListData
    ? shoppingListData.pages[shoppingListData.pages.length - 1].total_count
    : 0;

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-ShoppingLists')}>
      <PageTitle
        title={
          <div className={styles.titleWrapper}>
            <div className={styles.text}>
              <Trans>Lista ulubionych produktów</Trans> <span className="thin">({itemsCount})</span>
            </div>
          </div>
        }
      />
      <div className={styles.actionBar}>
        {!isMobile && (
          <div>
            <SearchInput
              placeholder={t('Szukaj')}
              value={queryParams.search_keyword}
              onChange={(value) =>
                setQueryParams((prevState) => ({ ...prevState, search_keyword: value }))
              }
            />
          </div>
        )}
        <div className={styles.removePositions}>
          {!!checkedItemIds.length && (
            <button
              onClick={() => deleteShoppingListPosition(checkedItemIds.map((id) => ({ id })))}>
              <TrashGrayIcon />
              <Trans>Usuń zaznaczone</Trans>
            </button>
          )}
        </div>
      </div>
      {positionList.map((position) => (
        <ProductItem
          key={position.id}
          line
          product={position}
          isShoppingList
          isChecked={checkedItemIds.includes(position.id)}
          toggleCheckbox={() => toggleCheckbox(position.id)}
          handleRemove={() => deleteShoppingListPosition([{ id: position.id }])}
        />
      ))}
      {positionsStatus === 'success' && (
        <div className={classnames(styles.paginationWrapper)}>
          {shoppingListData && (
            <span className={classnames(styles.paginationCount)}>
              {itemsCount} <Trans>na</Trans> {totalCount} <Trans>produktów</Trans>
            </span>
          )}
          <ProgressBar value={(itemsCount / totalCount) * 100} />
          <Button disabled={!hasNextPage} ghost square onClick={() => fetchNextPage()}>
            <Trans>Więcej produktów</Trans>
          </Button>
        </div>
      )}
    </div>
  );
};

export default ShoppingLists;
