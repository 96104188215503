// strona finalizacji koszyka

import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { ChevronLeft } from 'react-bootstrap-icons';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import qs from 'query-string';

import {
  useGetCart,
  useGetCartMainData,
  useGetCartsAll,
  usePostCartPreConfirm,
  usePostCartConfirmVmp,
  usePutCartMainData,
  useGetCartDeliveryMethods,
  usePostCartValidate,
  useGetCartPositions,
  useGetCartServicesVmp
} from 'api';
import { ICommandResponseError, ICheckoutStep } from 'api/types';
import { useAppNavigate, useNotifications, useRWD } from 'hooks';
import { CartSummary } from 'components/containers';
import { Container, CheckoutSteps, Link } from 'components/controls';
import { Payer, Payment, Delivery, Details, Confirmation, Positions } from './components';

import styles from 'theme/pages/Checkout/Checkout.module.scss';

const Checkout = () => {
  const { t } = useTranslation();
  const navigate = useAppNavigate();
  const { showErrorMessage } = useNotifications();
  const { isMobile } = useRWD();

  const location = useLocation();

  // ustawienie aktualnego kroku
  const checkoutStep = qs.parse(location.search)?.checkoutStep as ICheckoutStep;
  const setCheckoutStep = (step: ICheckoutStep) =>
    navigate(`/checkout/${cartId}?checkoutStep=${step}`);

  // ID koszyka z url'a
  const { cartId: cartIdParam } = useParams();
  // rzutowanie ID na numer
  const cartId = useMemo(() => parseInt(cartIdParam || ''), [cartIdParam]);

  // czy jest widok podsumowania
  const [comment, setComment] = useState('');

  // czy jest widok podsumowania
  const [validationErrors, setValidationErrors] = useState<ICommandResponseError['error_fields']>();

  // czy jest widok potwierdzenia
  const [isConfirmation, setIsConfirmation] = useState(false);

  // parametry zapytania API dla pozycja koszyka
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    searchKeyword: '',
    sort_method: ''
  });

  // ustawienie w url stanu początkowego
  useEffect(() => {
    if (!checkoutStep) {
      setCheckoutStep('ADDRESS');
    }
  }, []);

  // ustawienie komentarza z responsu (i wyczyszczenie błędów) z api (przy poejściu pomiędzy widokiem podsumowania i checkoutu)
  useEffect(() => {
    if (checkoutStep === 'SUMMARY') {
      setComment(cartMainData?.comment || '');
      setValidationErrors(undefined);
    }
  }, [checkoutStep]);

  // scroll do góry przy zmianie kroku procesu zakupowego
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [checkoutStep]);

  // reset stanu po zmienie ID koszyka
  useEffect(() => {
    setIsConfirmation(false);
  }, [cartId]);

  // pobranie wszystkich koszyków
  const { refetch: refetchAllCarts } = useGetCartsAll({
    onSuccess: (data) => {
      const currentCartItem = data.items.find((item) => item.id === cartId);

      if (!isConfirmation) {
        currentCartItem
          ? currentCartItem.products_count > 0
            ? refetchCartData()
            : navigate(`/cart/${cartId}`)
          : navigate('/');
      }
    }
  });

  // pobranie szczegółów koszyka
  const { data: cartData, refetch: refetchCartData } = useGetCart(cartId, {
    enabled: false,
    onError: () => {
      navigate('/');
    }
  });

  // pobranie listy metod dostawy
  const { data: cartDeliveryMethodsData, refetch: refetchDeliveryMethodsData } =
    useGetCartDeliveryMethods(
      cartId,
      {
        page: 1,
        limit: 999
      },
      { enabled: false }
    );

  // pobranie listy pozycji koszyka
  const { data: cartPositionsData, refetch: refetchCartPositions } = useGetCartPositions(
    cartId,
    queryParams,
    {
      enabled: false
    }
  );

  // pobieranie listy usług
  const { data: servicesData, refetch: refetchServicesData } = useGetCartServicesVmp(
    cartId,
    {},
    { enabled: false }
  );

  // walidacja poprawności koszykia
  const { mutate: preConfirmCart, isLoading: isCartPreConfirming } = usePostCartPreConfirm(cartId, {
    onSuccess: () => {
      setValidationErrors(undefined);
    },
    onError: (error) => {
      setValidationErrors(error.error_fields);
    }
  });

  // pobranie main data koszyka
  const {
    data: cartMainData,
    refetch: refetchCartMainData,
    isFetching: isMainDataLoading
  } = useGetCartMainData(cartId, {
    onSuccess: (data) => {
      !cartMainData && setComment(data.comment || '');

      // odznaczenie błędu walidacji
      setValidationErrors((prevState) =>
        prevState?.filter(
          (item) => !data[item.property_path as 'receiver' | 'delivery_method' | 'payment_method']
        )
      );
    }
  });

  // aktualizacja main data koszyka
  const {
    mutate: putCartMainData,
    mutateAsync: putCartMainDataAsync,
    isLoading: isMainDataUpdating
  } = usePutCartMainData(cartId, {
    onSuccess: (data, variables) => {
      refetchAllCarts();
      refetchCartMainData();
      if (typeof variables.comment !== 'undefined') {
        setComment(variables.comment);
        setValidationErrors((prevState) =>
          prevState?.filter((item) => item.property_path !== 'comment')
        );
      }

      if (!data.data && data.message) {
        showErrorMessage(data.message);
      }
    },
    onError: (error) => {
      setValidationErrors(error.error_fields);
    }
  });

  // walidacja koszyka
  const { mutateAsync: validateCart } = usePostCartValidate(cartId || 0, {
    onSuccess: (data) => {
      if (!data.status) {
        refetchCartData();
        refetchCartMainData();
        refetchCartPositions();
        refetchServicesData();
      }
    }
  });

  // potwiedznie koszyka (złożenie zamówienia)
  const { mutate: confirmCart, isLoading: isCartConfirming } = usePostCartConfirmVmp(cartId, {
    onSuccess: () => {
      setIsConfirmation(true);
      refetchAllCarts();
    }
  });

  const getCurrentStepData = () => {
    switch (checkoutStep) {
      case 'ADDRESS':
        return {
          currentStepIndex: 1,
          title: <Trans>Dane zamawiającego</Trans>,
          content: (
            <Payer
              cartId={cartId}
              customer={cartMainData?.customer}
              receiver={cartMainData?.receiver}
              dropShipping={cartMainData?.dropshipping}
              refetchCartMainData={refetchCartMainData}
              updateCartMainData={putCartMainData}
              setCheckoutStep={setCheckoutStep}
              accessToUseDropshipping={cartMainData?.enable_dropshipping}
            />
          )
        };
      case 'BILLING':
        return {
          currentStepIndex: 2,
          title: <Trans>Forma płatności i wysyłki</Trans>,
          content: (
            <>
              <Payment
                cartId={cartId}
                updateCartMainData={putCartMainDataAsync}
                paymentMethod={cartMainData?.payment_method}
                cashOnDelivery={cartMainData?.cash_on_delivery_value}
                currency={cartData?.currency}
                refetchDeliveryMethodsData={refetchDeliveryMethodsData}
                isLoading={isMainDataLoading || isMainDataUpdating}
              />
              <Delivery
                cartId={cartId}
                updateCartMainData={putCartMainData}
                deliveryMethod={cartMainData?.delivery_method}
                deliveryPoint={cartMainData?.receiver_delivery_point}
                setCheckoutStep={setCheckoutStep}
                refetchCartMainData={refetchCartMainData}
                cartDeliveryMethodsData={cartDeliveryMethodsData}
                refetchDeliveryMethodsData={refetchDeliveryMethodsData}
                isLoading={isMainDataLoading || isMainDataUpdating}
              />
            </>
          )
        };
      case 'SUMMARY':
        return {
          currentStepIndex: 3,
          title: <Trans>Podsumowanie zamówienia</Trans>,
          content: (
            <>
              <Positions
                cartId={cartId}
                cartPositionsData={cartPositionsData}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                refetchCartPositions={refetchCartPositions}
              />
              <Details servicesData={servicesData} refetchServicesData={refetchServicesData} />
              <div className={styles.summary}>
                {isMobile && (
                  <CartSummary
                    cartId={cartId}
                    buttonOnClick={handleOrderButtonClick}
                    buttonLabel={
                      checkoutStep === 'SUMMARY'
                        ? t('Płacę i zamawiam')
                        : t('Przejdź do podsumowania')
                    }
                    isLoading={isCartConfirming || isCartPreConfirming}
                    isSummary={checkoutStep === 'SUMMARY'}
                    isMobileCheckoutTop={true}
                  />
                )}
                <div>
                  <Payer
                    cartId={cartId}
                    customer={cartMainData?.customer}
                    receiver={cartMainData?.receiver}
                    updateCartMainData={putCartMainData}
                    setCheckoutStep={setCheckoutStep}
                    isSummary={checkoutStep === 'SUMMARY'}
                    cashOnDelivery={cartMainData?.cash_on_delivery_value}
                    showInformationToPay={cartMainData?.show_information_to_pay}
                    deliveryPoint={cartMainData?.receiver_delivery_point_summary}
                    currency={cartData?.currency}
                  />
                  <div className={styles.comment}>
                    <div
                      className={classnames({
                        [styles.header]: true,
                        [styles.error]: !!commentError
                      })}>
                      <span>
                        <Trans>Dodaj komentarz do zamówienia</Trans>
                      </span>{' '}
                    </div>
                    <div
                      className={classnames({
                        [styles.content]: true
                      })}>
                      <TextareaAutosize
                        className={styles.textarea}
                        value={comment}
                        minRows={7}
                        onChange={(event) => setComment(event.target.value)}
                        onBlur={() =>
                          comment !== cartMainData?.comment
                            ? putCartMainData({
                                comment
                              })
                            : setValidationErrors((prevState) =>
                                prevState?.filter((item) => item.property_path !== 'comment')
                              )
                        }
                      />
                      {commentError && <span className={styles.errorMessage}>{commentError}</span>}
                    </div>
                  </div>
                </div>
                <CartSummary
                  cartId={cartId}
                  buttonOnClick={handleOrderButtonClick}
                  buttonLabel={
                    checkoutStep === 'SUMMARY'
                      ? t('Płacę i zamawiam')
                      : t('Przejdź do podsumowania')
                  }
                  isLoading={isCartConfirming || isCartPreConfirming}
                  isSummary={checkoutStep === 'SUMMARY'}
                  isMobileCheckoutBottom={isMobile}
                />
              </div>
            </>
          )
        };
      default:
        return {
          currentStepIndex: 1,
          title: <Trans>Dane zamawiającego</Trans>,
          content: (
            <Payer
              cartId={cartId}
              customer={cartMainData?.customer}
              receiver={cartMainData?.receiver}
              dropShipping={cartMainData?.dropshipping}
              refetchCartMainData={refetchCartMainData}
              updateCartMainData={putCartMainData}
              setCheckoutStep={setCheckoutStep}
            />
          )
        };
    }
  };

  const commentError = useMemo(
    () => validationErrors?.find((item) => item.property_path === 'comment')?.message,
    [validationErrors]
  );

  const handleOrderButtonClick = async (data = {}) => {
    await putCartMainDataAsync({
      comment
    });

    if (checkoutStep === 'SUMMARY') {
      const { status } = await validateCart({ verification_scope: 'all' });
      status === 1 && confirmCart(data);

      return;
    }

    preConfirmCart();
  };

  const { title, currentStepIndex, content } = getCurrentStepData();

  if (isConfirmation) {
    return <Confirmation cartId={cartId} />;
  }

  return (
    <div className={classnames(styles.componentWrapper, 'StylePath-Pages-Checkout')}>
      <Container>
        <div className={styles.contentHeader}>
          <Link to={`/cart/${cartId}`} className={styles.link}>
            <ChevronLeft className={styles.backIcon} /> <Trans>Wróć do koszyka</Trans>
          </Link>

          <CheckoutSteps
            setCheckoutStep={setCheckoutStep}
            currentStepIndex={currentStepIndex}
            id={cartId}
          />
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.heading}>
              <h1>{title}</h1>
            </div>
            {content}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Checkout;
