// Hook odpowiedzialny za potwiedzene koszyka (stworzenie zamówienia)

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

interface IAttachment {
  base64: string;
  type: string;
  name: string;
  description: string;
}

export type IRequest = IAttachment;

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const putCartAttachments = (
  cartId: number,
  attachmentId: number,
  data: IRequest
): Promise<IResponse> => axios.put(`/carts/${cartId}/attachments/${attachmentId}`, data);

export const usePutCartAttachments = (
  cartId: number,
  attachmentId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => putCartAttachments(cartId, attachmentId, data), options);
