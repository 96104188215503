// Hook odpowiedzialny za pobranie listy płatności

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse,
  ISearchRequest
} from 'api/types';

export interface IPaymentListItem {
  id: number;
  create_datetime: string;
  payment_date: string;
  symbol: string;
  status: string;
  status_mobile: string;
  value_gross: number;
  value_net: number;
  value_gross_formatted: string;
  value_net_formatted: string;
  currency: string;
  payment_diff_days: number;
  current_balance: number;
  current_balance_formatted: string;
  future_balance: number;
  future_balance_formatted: string;
}

// parametry requestu do api
export type IRequest = IPaginationRequest &
  ISearchRequest & {
    search_keyword?: string;
    date_from?: string;
    date_to?: string;
    status_type?: 'PAID' | 'CURRENT' | 'EXPIRED' | 'EXTRACT' | 'ORDER';
  };

// typ zwracanych danych
type IResponse = IPaginationResponse<IPaymentListItem>;

const getPayments = (params?: IRequest): Promise<IResponse> => axios.get('/payments', { params });

export const useGetPayments = (params?: IRequest, options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['payments', params], () => getPayments(params), options);
