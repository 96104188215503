import React, { FC } from 'react';
import Switch from '@mui/material/Switch';

import { usePostOrderEditEnable, usePostOrderEditDisable } from 'api';
import styles from 'theme/pages/Orders/Orders.module.scss';

// typ danych wejściowych
interface IProps {
  orderId: number;
  isBlocked: boolean;
  refetch: () => void;
  isEditingMode?: boolean;
}

const OrderSwitch: FC<IProps> = ({ orderId, isBlocked, refetch, isEditingMode = false }) => {
  const { mutate: orderEnable } = usePostOrderEditEnable({
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: orderDisable } = usePostOrderEditDisable({
    onSuccess: () => {
      refetch();
    }
  });

  const handleHold = () => {
    const payload = { orders: [{ id: orderId }], editPanel: isEditingMode };
    isBlocked ? orderEnable(payload) : orderDisable(payload);
  };

  return (
    <div className={styles.hold}>
      <Switch
        classes={{
          thumb: styles.switchThumb,
          track: styles.trackThumb,
          switchBase: styles.switchBase,
          checked: styles.switchChecked
        }}
        disableRipple
        checked={isBlocked}
        onChange={() => handleHold()}
      />
    </div>
  );
};

export default OrderSwitch;
