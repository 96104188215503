// główne filtry listy produktów

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { IProductsFilter } from 'api/types';

import { MainFilter, Range } from './components';

import styles from 'theme/pages/Products/components/MainFilters/MainFilters.module.scss';

interface IFilter {
  filter_id: string;
  filter_value: string;
  filter_type?: 'singlechoice' | 'multichoice' | string;
}

// typ danych wejściowych
interface IProps {
  queryFilters: IFilter[];
  onChange: (filters: IFilter[], currentFilter: string) => void;
  clearFilters: (arg: string) => void;
  filtersData?: IProductsFilter[];
  categoryId?: number;
  searchKeywords?: string;
}

const MainFilters: FC<IProps> = ({
  onChange,
  queryFilters,
  filtersData,
  clearFilters,
  categoryId,
  searchKeywords
}) => {
  const { t } = useTranslation();

  const renderFilterType = (filter: IProductsFilter) => {
    switch (filter.type) {
      case 'singlechoice':
        return (
          <MainFilter
            onChange={onChange}
            queryFilters={queryFilters}
            filter={filter}
            clearFilters={clearFilters}
            categoryId={categoryId}
            searchKeywords={searchKeywords}
          />
        );
      case 'multichoice':
        return (
          <MainFilter
            onChange={onChange}
            queryFilters={queryFilters}
            filter={filter}
            clearFilters={clearFilters}
            categoryId={categoryId}
            searchKeywords={searchKeywords}
          />
        );
      case 'range':
        return <Range onChange={onChange} queryFilters={queryFilters} filter={filter} />;
      default:
        <div className={styles.unknownType}>
          {t('Nieznany typ filtra')} (<strong>{filter.type}</strong>)
        </div>;
    }
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Products-components-MainFilters'
      )}>
      <div className={styles.filtersWrapper}>
        {filtersData?.map((filter) => (
          <div className={styles.filter} key={filter.id}>
            {renderFilterType(filter)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainFilters;
