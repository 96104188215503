// komponent wizualizujący dostępność produktu

import React, { FC } from 'react';
import classnames from 'classnames';
import map from 'lodash/map';

import styles from 'theme/components/controls/Variants/Variants.module.scss';

// typ danych wejściowych
interface IProps {
  variants: string[];
  line?: boolean;
}

const Variants: FC<IProps> = ({ line, variants }) => (
  <div
    className={classnames(
      styles.componentWrapper,
      { [styles.line]: line },
      'StylePath-Components-Controls-Variants'
    )}>
    {map(variants, (variant, i) => (
      <span key={i} className={classnames(styles.variant)}>
        <span>{variant}</span>
      </span>
    ))}
  </div>
);

export default Variants;
