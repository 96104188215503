// komponent pojedynczego wyboru

import React, { FC, ReactElement, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import 'intersection-observer';

// typ danych wejściowych
interface IProps {
  fallback?: () => void;
  children: ReactElement;
}

const RenderWhenVisible: FC<IProps> = ({ fallback, children }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    initialInView: false,
    rootMargin: '10%'
  });

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setWasVisible(true);
    }
  }, [inView]);

  const renderFallback = () => {
    const fallbackRender = fallback?.();

    if (fallbackRender) {
      return fallbackRender;
    }

    return <div className="RenderWhenVisible-Detector" />;
  };

  const renderChildren = () => {
    return children;
  };

  return <div ref={ref}>{!inView ? renderFallback() : renderChildren()}</div>;
};

export default RenderWhenVisible;
