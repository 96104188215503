// Hook odpowiedzialny za potwierdzenie edycji zamówienia

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
interface IRequest {
  order_id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: {
    street: string;
    city: string;
    postal_code: string;
    building: string;
    apartment: string;
    country_code?: string;
  };
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const putOrderEditReceiverAddress = (orderId: number, data: IRequest): Promise<IResponse> =>
  axios.put(`/order-edit/${orderId}/receiver-address`, data);

export const usePutOrderEditReceiverAddress = (
  orderId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => putOrderEditReceiverAddress(orderId, data), options);
