import React, { FC } from 'react';
import Dropzone from 'react-dropzone';
import { X } from 'react-bootstrap-icons';
import { Trans, useTranslation } from 'react-i18next';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';

import { useNotifications } from 'hooks';
import { usePostCartAttachments, usePutCartAttachments, useDeleteCartAttachments } from 'api';
import { IAdditionalType } from 'api/types';

import styles from 'theme/pages/Checkout/components/Delivery/Delivery.module.scss';

// typ danych wejściowych
interface IProps {
  cartId: number;
  refetchDeliveryMethodsData: () => void;
  option: IAdditionalType;
}

const Attachment: FC<IProps> = ({ cartId, refetchDeliveryMethodsData, option }) => {
  const { t } = useTranslation();
  const { showErrorMessage } = useNotifications();

  const additionalInfo = mapValues(keyBy(option.additional_info, 'symbol'), 'value');

  // dodawanie załączników
  const { mutate: uploadAttachments } = usePostCartAttachments(cartId, {
    onSuccess: () => {
      refetchDeliveryMethodsData();
    }
  });

  // dodawanie załączników
  const { mutate: updateAttachments } = usePutCartAttachments(cartId, parseInt(additionalInfo.id), {
    onSuccess: () => {
      refetchDeliveryMethodsData();
    }
  });

  // dodawanie załączników
  const { mutate: deleteAttachments } = useDeleteCartAttachments(
    cartId,
    parseInt(additionalInfo.id),
    {
      onSuccess: () => {
        refetchDeliveryMethodsData();
      }
    }
  );

  const handleDropzone = (file: File[], type: string) => {
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
      if (typeof reader.result === 'string') {
        if (additionalInfo?.id) {
          updateAttachments({
            base64: reader.result,
            description: '',
            name: file[0].name,
            type: type
          });
          return;
        }

        uploadAttachments({
          attachments: [
            {
              base64: reader.result,
              description: '',
              name: file[0].name,
              type: type
            }
          ]
        });
      }
    };
  };

  return (
    <Dropzone
      accept={{
        pdf: ['.pdf']
      }}
      multiple={false}
      onDrop={(acceptedFiles) => handleDropzone(acceptedFiles, option.parameter_definition.symbol)}
      onError={() => showErrorMessage(t('Załącznik musi być plikiem pdf'))}>
      {({ getRootProps, getInputProps }) => (
        <section className={styles.dropzone}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              <span>{option.parameter_definition.label}</span>
              <div>
                <button>
                  <Trans>Przeglądaj</Trans>...
                </button>
              </div>
            </p>
            {additionalInfo?.name}

            {additionalInfo?.id && (
              <span className={styles.deleteAttachment}>
                <X onClick={(e) => (e.stopPropagation(), deleteAttachments())} />
              </span>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default Attachment;
