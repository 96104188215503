// Hook odpowiedzialny za zwiększenie ilości danej pozycji w koszyku

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postOrderEditPositionQuantityIncrement = (
  orderId: number,
  positionId: number
): Promise<IResponse> =>
  axios.post(`/order-edit/${orderId}/positions/${positionId}/quantity-increment`, {});

export const usePostOrderEditPositionQuantityIncrement = (
  orderId: number,
  options?: UseMutationOptions<IResponse, IError, { positionId: number }>
) =>
  useMutation(
    (data: { positionId: number }) =>
      postOrderEditPositionQuantityIncrement(orderId, data.positionId),
    options
  );
