// panel nad tabelką na stronie koszyka

import React, { FC, useMemo } from 'react';

import { IClientStatusListItem } from 'api/types';
import { usePostClientAccept } from 'api';
import { Select } from 'components/controls';

// typ danych wejściowych
interface IProps {
  clientStatuses?: IClientStatusListItem[];
  value: string;
  clientId: number;
  refetchClients: () => void;
}

const SelectStatus: FC<IProps> = ({ clientStatuses, value, clientId, refetchClients }) => {
  const { mutate } = usePostClientAccept(clientId, { onSuccess: () => refetchClients() });

  // opcje dropdownu typów pola
  const selectOptions = useMemo(() => {
    const sections = (clientStatuses || []).map((status) => ({
      value: status.status_symbol,
      label: <span>{status.status_formatted}</span>,
      item: status
    }));

    return sections;
  }, [clientStatuses]);

  return (
    <Select<IClientStatusListItem>
      options={selectOptions}
      variant="small"
      value={value}
      onChange={(item) => {
        mutate({ client_id: clientId, status: item?.status as number });
      }}
    />
  );
};

export default SelectStatus;
