// strana ustawień notyfikacji

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { reduxActions, useDispatch, useSelector } from 'store';
import { useGetUserMessageSettings } from 'api';
import { useNotifications } from 'hooks';
import { PageTitle, Checkbox } from 'components/controls';

import styles from 'theme/pages/MessageSettings/MessageSettings.module.scss';

const DashboardNotifications = () => {
  const { t } = useTranslation();
  const { showWarningMessage } = useNotifications();
  const { profile } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { data: userMessageSettingsData } = useGetUserMessageSettings(profile?.id || 0, {
    page: 1,
    limit: 999
  });

  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        { name: t('Dashboard'), path: '/dashboard' },
        { name: t('Komunikaty') }
      ])
    );
  }, []);

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-MessageSettings')}>
      <PageTitle title={t('Komunikaty')} />

      <div className={styles.notifications}>
        {(userMessageSettingsData?.items || []).map((message) => (
          <label className={styles.notification} key={message.name}>
            {message.name}
            
            <Checkbox
              checked={message.enabled}
              onClick={() => {
                showWarningMessage('Funkcja niezaimplementowana');
              }}
            />
          </label>
        ))}
      </div>
    </div>
  );
};

export default DashboardNotifications;
