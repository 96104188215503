// widok pozycji w wersji default

import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { IColumn } from 'components/controls/Table';
import { IProductVersion, IUnit, IPosition, IProduct } from 'api/types';

import SizingTableQty from './SizingTableQty';

import styles from 'theme/pages/Product/components/SizingTable/SizingTable.module.scss';

export const getSimpleColumns = ({
  renderCell,
  isMobile,
  unit,
  product,
  positions,
  changeProductPositions
}: {
  renderCell: (arg: number) => ReactElement;
  isMobile: boolean;
  unit?: IUnit;
  product: IProduct;
  positions: IPosition[];
  changeProductPositions: (arg: IPosition) => void;
}): IColumn<IProductVersion>[] => [
  {
    title: <Trans>Kolor</Trans>,
    key: 'color',
    align: 'center',
    renderCell: (item, index) => <div>{!index && item.color}</div>
  },
  {
    title: <Trans>Rozmiar</Trans>,
    key: 'size',
    align: 'center',
    renderCell: (item) => <div>{item.size}</div>
  },
  {
    title: <Trans>Cena netto</Trans>,
    key: 'net_price',
    align: 'center',
    renderCell: (item) => (
      <div className={classNames({ [styles.promoPrice]: !!item.old_price_net })}>
        {!!item.old_price_net && (
          <span>
            {item.old_price_net_formatted.replace('.', ',')} <br /> {product.currency}
          </span>
        )}
        <span>
          {item.price_net_formatted.replace('.', ',')} <br /> {product.currency}
        </span>
      </div>
    )
  },
  {
    title: isMobile ? (
      'M'
    ) : (
      <>
        <Trans>Dostępność</Trans>
        <br />
        <Trans>w magazynie VMP</Trans>
      </>
    ),
    key: 'vmp_availability',
    align: 'center',
    renderCell: (item) => <div>{renderCell(item.vmp_stock_qty)}</div>
  },
  {
    title: isMobile ? (
      <>
        <span>
          <Trans>Dostępność w magazynie</Trans>
        </span>
        P
      </>
    ) : (
      <>
        <Trans>Dostępność</Trans>
        <br />
        <Trans>w magazynie partnerskim</Trans>
      </>
    ),
    key: 'stock_availability',
    align: 'center',
    renderCell: (item) => <div>{renderCell(item.partner_stock_qty)}</div>
  },
  {
    title: isMobile ? (
      'D'
    ) : (
      <>
        <Trans>Dostępność</Trans>
        <br />
        <Trans>u producenta</Trans>
      </>
    ),
    key: 'producer_availability',
    align: 'center',
    renderCell: (item) => <div>{renderCell(item.producer_stock_qty)}</div>
  },
  {
    title: <Trans>Ilość</Trans>,
    key: 'qty',
    align: 'center',
    renderCell: (item) =>
      unit && (
        <SizingTableQty
          productId={item.product_id}
          unitId={unit.unit_id}
          onChangeQty={changeProductPositions}
          isNoSelected={!positions.length}
        />
      )
  }
];

export default getSimpleColumns;
