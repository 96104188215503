// sekcja ze szczegółami na podsumowaniu

import React, { FC, useEffect } from 'react';
import classnames from 'classnames';

import { ICartServicesVmpResponse } from 'api/types';

import styles from 'theme/pages/Checkout/components/Details/Details.module.scss';

// typ danych wejściowych
interface IProps {
  servicesData?: ICartServicesVmpResponse;
  refetchServicesData: () => void;
}

const Details: FC<IProps> = ({ servicesData, refetchServicesData }) => {
  useEffect(() => {
    refetchServicesData();
  }, []);

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Pages-Checkout-Components-Details'
      )}>
      {servicesData?.items.map((service) => (
        <div key={service.id} className={styles.row}>
          <div className={styles.column}>
            <div className={styles.title}>{service.name}</div>
          </div>
          <div className={styles.column}>{service.description}</div>
          <div className={styles.column}>{service.total_price_net_formatted.replace('.', ',')}</div>
        </div>
      ))}
    </div>
  );
};

export default Details;
