// Hook odpowiedzialny za pobranie listy usług w koszyku

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse
} from 'api/types';

export interface IOrderServicesListItem {
  calc_param: null;
  currency: string;
  description: string;
  id: number;
  name: string;
  role: string;
  service_id: number;
  total_price_gross: number;
  total_price_gross_formatted: string;
  total_price_net: number;
  total_price_net_formatted: string;
}

// parametry requestu do api
export type IRequest = IPaginationRequest & { showEditingData: boolean };

// typ zwracanych danych
export type IResponse = IPaginationResponse<IOrderServicesListItem>;

const getOrdersServicesVmp = (id: number, params?: IRequest): Promise<IResponse> =>
  axios.get(`/orders/${id}/services/vmp`, { params });

export const useGetOrdersServicesVmp = (
  id: number,
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['order-services', id, params],
    () => getOrdersServicesVmp(id, params),
    options
  );
