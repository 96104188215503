// główny layout - dolna belka nagłówka

import React, { useRef } from 'react';
import classnames from 'classnames';

import { Container } from 'components/controls';
import Categories from '../Categories';

import styles from 'theme/components/layouts/MainLayout/components/HeaderBottomBar/HeaderBottomBar.module.scss';

const HeaderBottomBar = () => {
  // panel z kategoriami
  const popupContentRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-HeaderBottomBar'
      )}>
      <Container>
        <div className={styles.bottomBar}>
          <div></div>
        </div>
      </Container>
      <div className={styles.categoriesPopup}>
        <div className={styles.content} ref={popupContentRef}>
          <Container>
            <Categories columnsCount={4} />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default HeaderBottomBar;
