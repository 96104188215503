// Hook odpowiedzialny za zmniejszenie ilości danej pozycji w koszyku

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postCartDropshippingDisable = (cartId: number): Promise<IResponse> =>
  axios.post(`/carts/${cartId}/dropshipping/disable`, {});

export const usePostCartDropshippingDisable = (
  cartId: number,
  options?: UseMutationOptions<IResponse, IError>
) => useMutation(() => postCartDropshippingDisable(cartId), options);
