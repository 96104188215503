// Hook odpowiedzialny za usunięcie załączników

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const deleteCartAttachments = (cartId: number, attachmentId: number): Promise<IResponse> =>
  axios.delete(`/carts/${cartId}/attachments/${attachmentId}`);

export const useDeleteCartAttachments = (
  cartId: number,
  attachmentId: number,
  options?: UseMutationOptions<IResponse, IError>
) => useMutation(() => deleteCartAttachments(cartId, attachmentId), options);
