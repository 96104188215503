// Hook odpowiedzialny za tworzenie klienta

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

interface IAddress {
  name: string;
  first_name: string;
  last_name: string;
  address: {
    street: string;
    postal_code: string;
    city: string;
    building: string;
    apartment: string;
    country_code: string;
  };
}

// parametry requestu do api
interface IRequest {
  street: string;
  postal_code: string;
  city: string;
  building: string;
  apartment: string;
  country_code: string;
  name: string;
  first_name: string;
  last_name: string;
  nip: string;
  primary_shipping_address: IAddress;
  additional_shipping_address: IAddress;
  contact_person_first_name: string;
  contact_person_last_name: string;
  phone: string;
  email: string;
  website: string;
  agree_online_invoice: boolean;
  agree_to_send_marketing_information: boolean;
  allow_trade_credit: boolean;
  automatic_return_insurance_percent_in_dropshipping: number | null;
  automatic_return_insurance_percent_without_dropshipping: number | null;
  contract_has_delivered: boolean;
  dropshipping_percent: number | null;
  flag_automatic_return_insurance_in_dropshipping: boolean;
  flag_automatic_return_insurance_without_dropshipping: boolean;
  flag_dropshipping: boolean;
  trade_credit_total: number | null;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postClientVMP = (data: IRequest): Promise<IResponse> => axios.post('/clients/vmp', data);

export const usePostClientVMP = (options?: UseMutationOptions<IResponse, IError, IRequest>) =>
  useMutation((data: IRequest) => postClientVMP(data), options);
