import React, { FC } from 'react';
import { Grid } from '@mui/material';
import Dropzone from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import { useNotifications } from 'hooks';
import { IOrderVmp } from 'api/types';
import { usePutOrderEditDeliveryReport, usePutOrderEditDeliveryLabel } from 'api';
import { TruckIcon } from 'assets/icons';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

interface IProps {
  orderData: IOrderVmp;
  refetchOrderData: () => void;
  isEditingMode: boolean;
}

const DeliveryEdit: FC<IProps> = ({ orderData, refetchOrderData, isEditingMode }) => {
  const { t } = useTranslation();
  const { showErrorMessage } = useNotifications();

  // edycja etykiet na edycji zamówienia
  const { mutate: updateReport } = usePutOrderEditDeliveryReport(orderData.id, {
    onSuccess: () => {
      refetchOrderData();
    }
  });

  // edycja etykiet na edycji raportów
  const { mutate: updateLabel } = usePutOrderEditDeliveryLabel(orderData.id, {
    onSuccess: () => {
      refetchOrderData();
    }
  });

  const handleDropzone = (file: File[], type: string) => {
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
      if (typeof reader.result === 'string') {
        if (type === 'shipping_letter') {
          updateLabel({ name: file[0].name, base64: reader.result, order_id: orderData.id });

          return;
        }

        if (type === 'shipping_protocol') {
          updateReport({ name: file[0].name, base64: reader.result, order_id: orderData.id });
        }
      }
    };
  };

  const renderAttachments = () => {
    return orderData.attachments.map((attachment, i) => (
      <div key={i} className={styles.attachment}>
        {isEditingMode ? (
          <>
            <Dropzone
              accept={{
                pdf: ['.pdf']
              }}
              multiple={false}
              onDrop={(acceptedFiles) => handleDropzone(acceptedFiles, attachment.type)}
              onError={() => showErrorMessage(t('Załącznik musi być plikiem pdf'))}>
              {({ getRootProps, getInputProps }) => (
                <section className={styles.dropzone}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      <span>
                        <Trans>
                          {attachment.type === 'shipping_letter'
                            ? 'Załącz etykietę'
                            : 'Załącz protokół'}
                        </Trans>
                      </span>
                      <div>
                        <button>
                          <Trans>Przeglądaj</Trans>...
                        </button>
                      </div>
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            <a href={attachment.url || ''} target="_blank" className={styles.link} rel="noreferrer">
              {attachment.name}
            </a>
          </>
        ) : (
          <div>
            <span>
              <Trans>{attachment.type === 'shipping_letter' ? 'Etykieta' : 'Protokół'}</Trans>:{' '}
            </span>
            <button className={styles.link}>{attachment.name}</button>
          </div>
        )}
      </div>
    ));
  };

  const delivery = orderData.delivery?.find(
    (o) => o.delivery_method_id === orderData.delivery_method_vmp.id
  );

  return (
    <Grid className={styles.box} item xs={12} lg={4}>
      <div className={styles.boxTitle}>
        <div>
          <TruckIcon />
          <Trans>Wysyłka</Trans>
        </div>
      </div>
      <div className={styles.value}>
        <img src={orderData.delivery_method_vmp?.image} />
        {orderData.delivery_method_vmp?.name}
      </div>
      {delivery && (
        <div className={styles.trackingNumber}>
          <Trans>Numer przesyłki</Trans>:{' '}
          <span>
            {orderData.delivery.map((delivery, i) => (
              <a href={delivery.tracking_url} target="_blank" key={i} rel="noreferrer">
                {delivery.tracking_number}
                <br />
              </a>
            ))}
          </span>
        </div>
      )}

      {orderData.receiver_delivery_point && (
        <div className={styles.deliveryPoint}>
          <span>
            <Trans>Wybrany punkt odbioru</Trans>: {orderData.receiver_delivery_point.symbol}{' '}
            {orderData.receiver_delivery_point.address.postal_code}{' '}
            {orderData.receiver_delivery_point.address.building}{' '}
            {orderData.receiver_delivery_point.address.apartment}{' '}
            {orderData.receiver_delivery_point.address.city}
          </span>
        </div>
      )}
      <div className={styles.attachments}>{renderAttachments()}</div>
    </Grid>
  );
};

export default DeliveryEdit;
