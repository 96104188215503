// Hook odpowiedzialny za pobranie listy koszyków

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

export type IDictionaryValue = {
  value: string | number;
  text: string;
};

export type IField = {
  type: string;
  value: string;
  label: string;
  editable: true;
  dictionary: {
    values: IDictionaryValue[];
    multi_choice: false;
    values_get_u_i_api: null;
  };
  symbol: string;
  width: 12;
  height: 1;
};

export type IAction = {
  label: string;
  method: 'CallCommand' | 'Cancel';
  method_params?: {
    command_url: string;
    params_list: string;
  };
  refresh_data_mode?: 'ONSUCCESS';
};

type IComponent = {
  fields: IField[];
  actions: IAction[];
  type: string;
  symbol: string;
  label: string;
};

type IProcessResult = {
  message: string;
  message_style: 'success' | 'error';
  show_message: boolean;
  status: 0 | 1;
};

// typ zwracanych danych
export type IResponse = {
  page_symbol: string;
  components: IComponent[];
  process_result?: IProcessResult;
  table_prefix: string;
};

const getDynamicUIPageDefinition = (pageSymbol: string, params?: object): Promise<IResponse> =>
  axios.get(`/dynamicUI/page_definition/${pageSymbol}`, { params });

export const useGetDynamicUIPageDefinition = (
  pageSymbol: string,
  params?: object,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['dynamic-ui-page-definition', pageSymbol, params],
    () => getDynamicUIPageDefinition(pageSymbol, params),
    options
  );
