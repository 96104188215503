// Hook odpowiedzialny za pobranie listy zamówień na dashboardzie

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, IPaginationRequest } from 'api/types';

export interface IDashboardOrderPeriodTimeItem {
  type: string;
  name: string;
}

// parametry requestu do api
export type IRequest = IPaginationRequest;

// typ zwracanych danych
type IResponse = {
  types: IDashboardOrderPeriodTimeItem[];
};

const getDashboardOrdersSummaryPeriodTimeVmp = (params?: IRequest): Promise<IResponse> =>
  axios.get('dashboards/orders/summary/period-time/vmp', { params });

export const useGetDashboardOrdersSummaryPeriodTimeVmp = (
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['dashboard-orders-summary-period-time-vmp', params],
    () => getDashboardOrdersSummaryPeriodTimeVmp(params),
    options
  );
