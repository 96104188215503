// Hook odpowiedzialny za aktualizację blokowania zamówienia

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
interface IRequest {
  orders: { id: number }[];
  editPanel?: boolean;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postOrderEditDisable = (data: IRequest): Promise<IResponse> =>
  axios.post('/order-edit/disable', data, {
    params: data.editPanel ? { editPanel: data.editPanel } : {}
  });

export const usePostOrderEditDisable = (
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => postOrderEditDisable(data), options);
