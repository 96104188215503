// Hook odpowiedzialny za tworzenie użytkownika

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
export interface IRequest {
  trader_id?: number;
  email: string;
  first_name: string;
  last_name: string;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postUsers = (data: IRequest): Promise<IResponse> => axios.post('/users', data);

export const usePostUsers = (options?: UseMutationOptions<IResponse, IError, IRequest>) =>
  useMutation((data: IRequest) => postUsers(data), options);
