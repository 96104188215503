// Hook odpowiedzialny za skasowanie pozycji koszyka

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
interface IRequest {
  orders: { id: number }[];
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const deleteOrders = (data: IRequest): Promise<IResponse> =>
  axios.delete('/orders/orders', { data });

export const useDeleteOrders = (options?: UseMutationOptions<IResponse, IError, IRequest>) =>
  useMutation((data: IRequest) => deleteOrders(data), options);
