import React, { useState, FC } from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import Popover from '@mui/material/Popover';

import { IDocumentListItemVmp, IDocumentsInformationVmpResponse } from 'api/types';

import { FinancesIcon, CheckIcon, DisabledIcon } from 'assets/icons';

import styles from 'theme/pages/Documents/Documents.module.scss';

type IProps = {
  item: IDocumentListItemVmp;
  documentsInformation?: IDocumentsInformationVmpResponse;
};

const DocumentStatus: FC<IProps> = ({ item, documentsInformation }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <span className={classnames(styles.cell, styles.status)}>
      <span></span>
      {!item.is_paid ? (
        <>
          <div className={styles.unpaid}>
            <span>
              <DisabledIcon /> <Trans>nieopłacone</Trans>
            </span>
          </div>
          <div>
            <button className={styles.repay} onClick={handleClick}>
              <FinancesIcon /> <Trans>zapłać</Trans>
            </button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}>
              <div
                className={styles.popoverContent}
                dangerouslySetInnerHTML={{ __html: documentsInformation?.information || '' }}
              />
            </Popover>
          </div>
        </>
      ) : (
        <div className={styles.paid}>
          <span>
            <CheckIcon /> <Trans>opłacone</Trans>
          </span>
        </div>
      )}
    </span>
  );
};

export default DocumentStatus;
