// strona regulaminu

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { reduxActions, useDispatch } from 'store';
import { useGetTerms } from 'api';
import { PageTitle } from 'components/controls';

import styles from 'theme/pages/Terms/Terms.module.scss';

const DashboardTerms = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // pobranie danych regulaminu
  const { data: termsData } = useGetTerms();

  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        { name: t('Dashboard'), path: '/dashboard' },
        { name: t('Regulamin') }
      ])
    );
  }, []);

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Terms')}>
      <PageTitle title={t('Regulamin')} />

      <div className={styles.content}>
        {termsData?.user_agreement_content && (
          <div dangerouslySetInnerHTML={{ __html: termsData?.user_agreement_content }} />
        )}
      </div>
    </div>
  );
};

export default DashboardTerms;
