// Hook odpowiedzialny za potwiedzene koszyka (stworzenie zamówienia)

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

export interface IRequest {
  enabled?: boolean;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postCartConfirmVmp = (cartId: number, data: IRequest): Promise<IResponse> =>
  axios.post(`/carts/carts/${cartId}/confirm/vmp`, data);

export const usePostCartConfirmVmp = (
  cartId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => postCartConfirmVmp(cartId, data), options);
