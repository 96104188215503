// Hook odpowiedzialny za tworzenie exportu

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
type IRequest = {
  email: string;
};

type IResponse = ICommandResponseSuccess;

const postNewsletterRegister = (data: IRequest): Promise<IResponse> =>
  axios.post('/newsletter/register', data);

export const usePostNewsletterRegister = (
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => postNewsletterRegister(data), options);
