// zwijany box

import React, { FC, PropsWithChildren, useState, ReactElement } from 'react';
import classnames from 'classnames';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { Collapse as CollapseMui } from '@mui/material';

import { Container } from 'components/controls';

import styles from 'theme/components/controls/Collapse/Collapse.module.scss';

// typ danych wejściowych
interface IProps {
  title: string | ReactElement;
  collapsed?: boolean;
  fullWidth?: boolean;
  isDocuments?: boolean;
  isOrders?: boolean;
  customWidth?: number;
}
const Collapse: FC<PropsWithChildren<IProps>> = ({
  title,
  children,
  collapsed = true,
  fullWidth,
  isDocuments,
  isOrders,
  customWidth
}) => {
  // czy treść jest widoczna
  const [open, setOpen] = useState(!collapsed);

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        { [styles.opened]: open, [styles.fullWidth]: !!fullWidth },
        { [styles.opened]: open },
        { [styles.isOrders]: !!isOrders },
        'StylePath-Components-Controls-Collapse'
      )}>
      <div className={styles.header} onClick={() => setOpen(!open)}>
        <Container>
          <div
            style={customWidth ? { width: customWidth } : {}}
            className={classnames(styles.box, { [styles.customWidth]: !!customWidth })}>
            <div
              className={classnames(
                styles.title,
                { [styles.documentsTitle]: !!isDocuments },
                { [styles.ordersTitle]: !!isOrders }
              )}>
              <span>{title}</span>
            </div>
            <div className={styles.arrow}>{open ? <ChevronUp /> : <ChevronDown />}</div>
          </div>
        </Container>
      </div>
      <CollapseMui in={open}>{<div className={styles.content}>{children}</div>}</CollapseMui>
    </div>
  );
};

export default Collapse;
