import React, { ReactElement, useMemo, FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ChevronDown, Gear, Power } from 'react-bootstrap-icons';

import { usePostOverLogout } from 'api';
import { useDispatch, useSelector, reduxActions } from 'store';
import { useAppNavigate, useRWD } from 'hooks';
import { DropDown } from 'components/controls';

import {
  OrdersIcon,
  DocumentsIcon,
  FinancesIcon,
  FavouritesIcon,
  UserLargeIcon
} from 'assets/icons';
import styles from 'theme/components/containers/DashboardMenu/DashboardMenu.module.scss';

interface IMenuItems {
  label: string;
  path?: string;
  icon?: ReactElement;
  onClick?: () => void;
  items?: IMenuItems[];
}

interface IRoute {
  label: string;
  path: string;
}

interface IAccessRoutes {
  orders: IRoute[];
  documents: IRoute[];
  finances: IRoute[];
  favourites: IRoute[];
  myData: IRoute[];
  admin: IRoute[];
}

// typ danych wejściowych
interface IProps {
  accessRoutes: IAccessRoutes;
}

const DashboardMenu: FC<IProps> = ({ accessRoutes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useAppNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useRWD();

  const { overloginUserId } = useSelector((state) => state.auth);

  const { mutateAsync } = usePostOverLogout();

  // wylogowanie uźytkownika z systemu
  const handleLogout = async () => {
    if (overloginUserId) {
      await mutateAsync();

      if (pathname.includes('/cart/')) {
        navigate('/cart/0');
      }

      dispatch(reduxActions.setCurrentCartId(null));
      dispatch(reduxActions.setOverlogin(null));

      return;
    }
    dispatch(reduxActions.signOut());
  };

  const menuItems: IMenuItems[] = useMemo(() => {
    return [
      {
        label: 'Moje konto',
        path: '/dashboard'
      },
      {
        label: 'Zamówienia',
        icon: <OrdersIcon />,
        items: accessRoutes.orders
      },
      {
        label: 'Dokumenty i płatności',
        icon: <DocumentsIcon />,
        items: accessRoutes.documents
      },
      {
        label: 'Finanse',
        icon: <FinancesIcon />,
        items: accessRoutes.finances
      },
      // {
      //   label: 'Ulubione',
      //   icon: <FavouritesIcon />,
      //   items: accessRoutes.favourites
      // },
      {
        label: 'Moje dane',
        icon: <UserLargeIcon />,
        items: accessRoutes.myData
      },
      {
        label: 'Panel Administratora',
        icon: <Gear />,
        items: accessRoutes.admin
      },
      ...(isMobile
        ? [
            {
              label: overloginUserId ? t('Wróć na konto') : t('Wyloguj się'),
              icon: <Power />,
              onClick: () => {
                handleLogout();
              }
            }
          ]
        : [])
    ];
  }, []);

  const renderItems = (parentItem: IMenuItems) => {
    if (!parentItem.items?.length) {
      return null;
    }

    if (parentItem.items?.length === 1) {
      return (
        <div
          className={classnames(styles.content, {})}
          onClick={() => parentItem?.items?.[0].path && navigate(parentItem.items[0].path)}>
          {parentItem.icon && <div className={styles.iconWrapper}>{parentItem.icon}</div>}

          {t(parentItem.label)}
        </div>
      );
    }

    return (
      <>
        {parentItem.icon && <div className={styles.iconWrapper}>{parentItem.icon}</div>}
        <DropDown
          label={
            <div className={classnames(styles.language, styles.label)}>
              <span>{t(parentItem.label)}</span>
              <ChevronDown className={styles.arrowDown} />
            </div>
          }
          items={parentItem.items}
          withDropdownIcon={false}
        />
      </>
    );
  };

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Containers-DashboardMenu')}>
      {menuItems.map((parentItem, index) => (
        <>
          {!!index && (
            <div
              className={classnames(styles.separator, {
                [styles.emptySeparator]: !parentItem.items?.length
              })}></div>
          )}
          <div
            className={classnames(styles.label, {
              [styles.open]: pathname.slice(pathname.indexOf('/dashboard')) === parentItem.path,
              [styles.empty]: !parentItem.items?.length
            })}
            key={index}
            onClick={() => {
              if (parentItem.onClick) {
                parentItem.onClick();

                return;
              }

              parentItem.path && navigate(parentItem.path);
            }}>
            {parentItem.items ? (
              renderItems(parentItem)
            ) : (
              <div
                className={classnames(styles.content, {})}
                onClick={() => parentItem.path && navigate(parentItem.path)}>
                {parentItem.icon && <div className={styles.iconWrapper}>{parentItem.icon}</div>}

                {t(parentItem.label)}
              </div>
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default DashboardMenu;
