// box z produktami

import React, { FC, useMemo } from 'react';
import classnames from 'classnames';

import { useGetHomeProductsBox } from 'api';
import { ProductSlider } from 'components/containers';

import styles from 'theme/pages/Home/components/ProductsBox/ProductsBox.module.scss';

interface IProps {
  productBoxId: string;
}

const ProductsBox: FC<IProps> = ({ productBoxId }) => {
  const { data: homeProductsBoxData } = useGetHomeProductsBox(productBoxId, {
    page: 1,
    limit: 10
  });

  const onlySlider = useMemo(
    () => !homeProductsBoxData?.image && !homeProductsBoxData?.see_also,
    [homeProductsBoxData?.image, homeProductsBoxData?.see_also]
  );

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        { [styles.onlySlider]: onlySlider, [styles.withImage]: homeProductsBoxData?.image },
        'StylePath-Pages-Home-components-ProductBox'
      )}>
      <h2 className={styles.title}>{homeProductsBoxData?.name}</h2>
      <div className={styles.content}>
        <ProductSlider products={homeProductsBoxData?.products || []} />
      </div>
    </div>
  );
};

export default ProductsBox;
