// Hook odpowiedzialny za zmianę danych w profilu

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
export interface IRequest {
  primary_shipping_address?: {
    name?: string;
    first_name?: string;
    last_name?: string;
    address?: {
      street?: string;
      building?: string;
      apartment?: string;
      postal_code?: string;
      city?: string;
      country?: string;
      country_code?: string;
    };
  };
  additional_shipping_address?: {
    name?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    address?: {
      name?: string;
      street?: string;
      building?: string;
      apartment?: string;
      postal_code?: string;
      city?: string;
      state?: string;
      country?: string;
      country_code?: string;
    };
  };
  name?: string;
  street?: string;
  building?: string;
  apartment?: string;
  postal_code?: string;
  city?: string;
  country?: string;
  country_code?: string;
  nip?: string;
  first_name?: string;
  last_name?: string;
  contact_person_first_name?: string;
  contact_person_last_name?: string;
  email?: string;
  phone?: string;
  website?: string;
  trade_credit_total?: number;
  trade_credit_free?: number;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const putUserProfileVmp = (userId: number, data: IRequest): Promise<IResponse> =>
  axios.put(`/users/profile/${userId}/vmp`, data);

export const usePutUserProfileVmp = (
  userId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => putUserProfileVmp(userId, data), options);
