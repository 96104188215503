// Hook odpowiedzialny za skasowanie pozycji koszyka

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

type Iposition = { id: number };

// parametry requestu do api
type IRequest = {
  order_id: number;
  positions: Iposition[];
};

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const deleteOrderEditPositions = (orderId: number, data: IRequest): Promise<IResponse> =>
  axios.delete(`/order-edit/${orderId}/positions`, { data });

export const useDeleteOrderEditPositions = (
  orderId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => deleteOrderEditPositions(orderId, data), options);
