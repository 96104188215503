// strona Moje konto

import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useGetTokenSecurity } from 'api';
import { useNotifications } from 'hooks';
import { reduxActions, useDispatch, useSelector } from 'store';
import { ChangePasswordForm, UserForm } from 'components/containers';
import { Input } from 'components/controls';

import { ClipboardIcon } from 'assets/icons';

import styles from 'theme/pages/MyAccount/MyAccount.module.scss';

const DashboardMyAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.auth);

  const { showSuccessMessage } = useNotifications();

  // pobieranie danych clientApi
  const { data: tokenData } = useGetTokenSecurity(profile?.id || 0);

  // ustawienie breadcrumbs'ów na starcie strony
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        { name: t('Moje konto'), path: '/dashboard' },
        { name: t('Moje dane') }
      ])
    );
  }, []);

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-MyAccount')}>
      {profile && <UserForm />}
      <div className={styles.changePasswordTitle}>
        <Trans>Zmiana hasła</Trans>
      </div>
      <ChangePasswordForm vertical />
      {tokenData?.client_id && tokenData?.client_secret && (
        <div className={styles.clientApiWrapper}>
          <div className={styles.changePasswordTitle}>
            ClientAPI{' '}
            <span>
              (<Trans>Aby zmienić klucz, skontaktuj się z Biurem obsługi klienta</Trans>)
            </span>
          </div>
          <div className={styles.accountWrapper}>
            <div className={styles.tokenWrapper}>
              <div className={styles.formElement}>
                <label>Client Id</label>
                <Input value={tokenData?.client_id} disabled />
                <ClipboardIcon
                  onClick={() => {
                    navigator.clipboard.writeText(tokenData?.client_id || '');
                    showSuccessMessage(t('Skopiowano token do schowka'));
                  }}
                />
              </div>
            </div>
            <div className={styles.tokenWrapper}>
              <div className={styles.formElement}>
                <label>Client Secret</label>
                <Input value={tokenData?.client_secret} disabled />
                <ClipboardIcon
                  onClick={() => {
                    navigator.clipboard.writeText(tokenData?.client_secret || '');
                    showSuccessMessage(t('Skopiowano token do schowka'));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardMyAccount;
