// przycisk z listą podglądu kkoszyków

import React, { useState, FC } from 'react';
import classnames from 'classnames';
import { ChevronDown } from 'react-bootstrap-icons';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import { UserMobileIcon } from 'assets/icons';

import { useRWD } from 'hooks';
import { Login } from 'pages';

import styles from 'theme/components/containers/LoginButton/LoginButton.module.scss';

// typ danych wejściowych
interface IProps {
  onClose?: () => void;
}

const ButtonCart: FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const { isMobile } = useRWD();

  // czy jest ustawiony element HTML popovera (użyte przy pokazywaniu modala)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-LoginButton'
      )}>
      <button
        className={styles.authButton}
        onClick={(event) => {
          onClose?.();
          setPopoverAnchor(event.currentTarget);
        }}>
        {isMobile ? (
          <UserMobileIcon />
        ) : (
          <>
            {t('Zaloguj się')}
            <ChevronDown />
          </>
        )}
      </button>

      <Popover
        anchorEl={popoverAnchor}
        classes={{ paper: styles.paper }}
        open={!!popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        marginThreshold={50}>
        <Login isPopover setPopoverAnchor={setPopoverAnchor} />
      </Popover>
    </div>
  );
};

export default ButtonCart;
