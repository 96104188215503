import React, { FC } from 'react';
import classnames from 'classnames';

import { usePostOrderEditActivate } from 'api';
import { useAppNavigate } from 'hooks';

import { OrderActivateIcon } from 'assets/icons';

import styles from 'theme/pages/Orders/Orders.module.scss';

// typ danych wejściowych
interface IProps {
  orderId: number;
  isInEditMode: boolean;
}

const OrderActivate: FC<IProps> = ({ orderId, isInEditMode }) => {
  const navigate = useAppNavigate();

  const { mutate: orderActivate } = usePostOrderEditActivate(orderId, {
    onSuccess: () => {
      navigate(`/dashboard/orders/${orderId}?isEditingMode=true`);
    }
  });

  const handleEditMode = () => {
    if (isInEditMode) {
      navigate(`/dashboard/orders/${orderId}?isEditingMode=true`);

      return;
    }

    orderActivate();
  };

  return (
    <div
      className={classnames(
        styles.activateIconWrapper,
        { [styles.isEditMode]: !!isInEditMode },
        'StylePath-Pages-Orders'
      )}>
      <button onClick={handleEditMode}>
        <OrderActivateIcon />
      </button>
    </div>
  );
};

export default OrderActivate;
