// Hook odpowiedzialny za pobranie listy płatności

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse
} from 'api/types';

// parametry requestu do api
export type IRequest = IPaginationRequest & {
  type?: string;
  dateFrom?: string;
  dateTo?: string;
  sortMethod?: string;
  searchKeyword?: string;
};

export type ISettlementVmp = {
  id: number;
  booking_date: string;
  payment_date: string | null;
  type: string;
  description: string;
  amount: number;
  amount_formatted: string;
  currency: string;
  balance: number;
  balance_formatted: string;
  balance_future: number;
  balance_future_formatted: string;
  is_active: boolean;
};

// typ zwracanych danych
type IResponse = IPaginationResponse<ISettlementVmp>;

const getSettlementsVmp = (params?: IRequest): Promise<IResponse> =>
  axios.get('/settlements/settlements/vmp', { params });

export const useGetSettlementsVmp = (
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['settlements-vmp', params],
    () => getSettlementsVmp(params),
    options
  );
