// Hook odpowiedzialny za pobranie danych potwierdzających złoźenie zamówienia

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, IPaginationResponse } from 'api/types';

// typ zwracanych danych
export interface ValidatingMessageItem {
  product_id: number;
  missing_price: number;
  message: string;
}

type IResponse = IPaginationResponse<ValidatingMessageItem>;

const getCartValidatingMessage = (id: number): Promise<IResponse> =>
  axios.get(`/carts/${id}/validating_message`);

export const useGetCartValidatingMessage = (
  id: number,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['cart-validating_message', id],
    () => getCartValidatingMessage(id),
    options
  );
