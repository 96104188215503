// Hook odpowiedzialny za pobranie podsumowania płatności

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';
import { Record } from 'react-bootstrap-icons';

interface IPaymentSummaryItem {
  payments: {
    count: number;
  };
  invoices: {
    count: number;
  };
  total: {
    value_net: number;
    value_gross: number;
    value_net_formatted: string;
    value_gross_formatted: string;
  };
  currency: string;
}

// typ zwracanego obiektu
export type IResponse = Record<'paid' | 'expired' | 'upcoming', IPaymentSummaryItem>;

const getPaymentsSummary = (): Promise<IResponse> => axios.get('/payments/summary');

export const useGetPaymentsSummary = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['payments-summary'], () => getPaymentsSummary(), options);
