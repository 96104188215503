// kroki checkoutu

import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Truck, ChevronRight } from 'react-bootstrap-icons';
import classnames from 'classnames';

import { usePostCartValidate } from 'api';
import { ICheckoutStep } from 'api/types';

import styles from 'theme/components/controls/CheckoutSteps/CheckoutSteps.module.scss';
import { User, CreditCard } from 'assets/icons';

interface IStep {
  label: string;
  key?: string;
  icon: ReactElement;
  onClick?: () => void | undefined;
}

// typ danych wejściowych
interface IProps {
  id: number;
  currentStepIndex: number;
  setCheckoutStep: (step: ICheckoutStep) => void;
}

const CheckoutSteps: FC<IProps> = ({ currentStepIndex, setCheckoutStep, id }) => {
  const { t } = useTranslation();

  // przeliczanie koszyka
  const { mutateAsync: validateCart } = usePostCartValidate(id || 0);

  // konfiguracja kroków
  const steps: IStep[] = [
    {
      label: t('Dane'),
      icon: <User />,
      onClick: () => setCheckoutStep('ADDRESS')
    },
    {
      label: t('Wysyłka i płatność'),
      icon: <Truck className={styles.icon} />,
      onClick: () => setCheckoutStep('BILLING')
    },
    {
      label: t('Podsumowanie'),
      icon: <CreditCard className={styles.icon} />,
      onClick: () => setCheckoutStep('SUMMARY')
    }
  ];

  const handleCick = async (item: IStep, index: number) => {
    if (index < currentStepIndex) {
      item.onClick?.();

      return;
    }

    if (index === 1) {
      await validateCart({ verification_scope: 'receiverAddress|clientAddress' });
    }

    if (index === 2) {
      await validateCart({ verification_scope: 'paymentMethod|deliveryMethod' });
    }

    item.onClick?.();
  };

  // Komponent renderujący krok
  const ItemContent = ({ label, icon }: IStep) => (
    <>
      {icon}
      <span className={styles.label}>{label}</span>
    </>
  );

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Controls-CheckoutSteps'
      )}>
      {steps.map((item, index) => (
        <div
          key={index}
          className={classnames(styles.item, {
            [styles.current]: index + 1 == currentStepIndex,
            [styles.clickable]: index < currentStepIndex
          })}>
          <span onClick={() => handleCick(item, index)}>
            <ItemContent label={item.label} icon={item.icon} />
          </span>
          {index + 1 !== steps.length && <ChevronRight className={styles.arrow} />}
        </div>
      ))}
    </div>
  );
};

export default CheckoutSteps;
