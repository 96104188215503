// pliki z tłumaczeniami

import enTranslations from './en.json';
import plTranslations from './pl.json';
import bgTranslations from './bg.json';
import czTranslations from './cz.json';
import deTranslations from './de.json';
import esTranslations from './es.json';
import frTranslations from './fr.json';
import huTranslations from './hu.json';
import itTranslations from './it.json';
import ltTranslations from './lt.json';
import roTranslations from './ro.json';
import ruTranslations from './ru.json';
import skTranslations from './sk.json';
import slTranslations from './sl.json';

export const resources = {
  en: {
    translation: enTranslations
  },
  pl: {
    translation: plTranslations
  },
  bg: {
    translation: bgTranslations
  },
  cz: {
    translation: czTranslations
  },
  de: {
    translation: deTranslations
  },
  es: {
    translation: esTranslations
  },
  fr: {
    translation: frTranslations
  },
  hu: {
    translation: huTranslations
  },
  it: {
    translation: itTranslations
  },
  lt: {
    translation: ltTranslations
  },
  ro: {
    translation: roTranslations
  },
  ru: {
    translation: ruTranslations
  },
  sk: {
    translation: skTranslations
  },
  sl: {
    translation: slTranslations
  }
};
