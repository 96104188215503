// Hook odpowiedzialny za export galerii produktu

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
interface IResponse {
  url: string;
  file_name: string;
}

const getProductExportImages = (productId: number): Promise<IResponse> =>
  axios.get(`/products/${productId}/export/images`);

export const useGetProductExportImages = (
  productId: number,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['product-export-images', productId],
    () => getProductExportImages(productId),
    options
  );
