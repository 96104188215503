// Hook odpowiedzialny za pobranie listy usług w koszyku

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse,
  ISearchRequest
} from 'api/types';

export interface ICartServicesListItem {
  description: string;
  id: number;
  service_id: number;
  name: string;
  total_price_net: number;
  total_price_gross: number;
  total_price_net_formatted: string;
  total_price_gross_formatted: string;
}

// parametry requestu do api
export type IRequest = IPaginationRequest & ISearchRequest;

// typ zwracanych danych
export type IResponse = IPaginationResponse<ICartServicesListItem>;

const getCartServicesVmp = (id: number, params?: IRequest): Promise<IResponse> =>
  axios.get(`/carts/${id}/services/vmp`, { params });

export const useGetCartServicesVmp = (
  id: number,
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['cart-services', id, params],
    () => getCartServicesVmp(id, params),
    options
  );
