// Hook odpowiedzialny za pobranie listy do slider'a w banerze na stronie głównej

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, IProductListItem, IPaginationRequest } from 'api/types';

// typ zwracanych danych
interface IResponse {
  name: string;
  image?: string;
  see_also?: {
    name: string;
    url?: string;
    new_tab: boolean;
    category_id?: number;
    filter_attributes?: string;
    search_keyword?: string;
    sort_method?: string;
  }[];
  products: IProductListItem[];
}

// parametry requestu do api
export type IRequest = IPaginationRequest;

const getHomeProductsBox = (productBoxId: string, params?: IRequest): Promise<IResponse> =>
  axios.get(`/home/products/${productBoxId}`, { params });

export const useGetHomeProductsBox = (
  productBoxId: string,
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['home-products-box', productBoxId],
    () => getHomeProductsBox(productBoxId, params),
    options
  );
