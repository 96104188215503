// Hook odpowiedzialny za potwierdzenie edycji zamówienia

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
interface IRequest {
  order_id: number;
  note: string;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const putOrderEditClientNote = (orderId: number, data: IRequest): Promise<IResponse> =>
  axios.put(`/order-edit/${orderId}/client-note`, data);

export const usePutOrderEditClientNote = (
  orderId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => putOrderEditClientNote(orderId, data), options);
