// komponent wizualizujący dostępność produktu

import React, { FC } from 'react';
import classnames from 'classnames';

import { IWarehouse } from 'api/types';

import styles from 'theme/components/controls/Availability/Availability.module.scss';

// typ danych wejściowych
interface IProps {
  stock: any;
  line?: boolean;
}

const Availability: FC<IProps> = ({ stock, line }) => {
  const renderStockList = (item: IWarehouse) => {
    return (
      <div
        key={item.symbol}
        className={classnames(styles.stock, { [styles.active]: item.style === 'available' })}>
        {item?.symbol}
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        !!line,
        { [styles.line]: !!line },
        'StylePath-Components-Controls-Availability'
      )}>
      {Array.isArray(stock) && stock?.map((item) => renderStockList(item))}
    </div>
  );
};

export default Availability;
