// Hook odpowiedzialny za potwierdzenie edycji zamówienia

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postOrderEditConfirm = (orderId: number): Promise<IResponse> =>
  axios.post(`/order-edit/${orderId}/confirm`, {});

export const usePostOrderEditConfirm = (
  orderId: number,
  options?: UseMutationOptions<IResponse, IError>
) => useMutation(() => postOrderEditConfirm(orderId), options);
