// Hook odpowiedzialny za skasowanie pozycji z listy zakupowej

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
type IRequest = { id: number }[];

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const deleteShoppingListPosition = (data: IRequest): Promise<IResponse> =>
  axios.delete('/shopping-lists/positions', { data });

export const useDeleteShoppingListPosition = (
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => deleteShoppingListPosition(data), options);
