// Hook odpowiedzialny za zmianę statusu użytkownika

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
interface IRequest {
  last_password: string;
  new_password: string;
  repeat_new_password: string;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postUserChangePassword = (data: IRequest): Promise<IResponse> =>
  axios.post(`/users/change-password`, data);

export const usePostUserChangePassword = (
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => postUserChangePassword(data), options);
