// strona koszyka

import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { useAppNavigate } from 'hooks';
import { usePostNewsletterConfirm } from 'api';
import { Container, Loader } from 'components/controls';

import styles from 'theme/pages/Cart/Cart.module.scss';

const NewsletterConfirmPage = () => {
  const { search } = useLocation();
  const token = qs.parse(search).token as string;

  const navigate = useAppNavigate();

  // potwierdzenie rejestracji
  const { mutate: newsletterConfirm } = usePostNewsletterConfirm({
    onSuccess: () => {
      navigate('/');
    },
    onError: () => {
      navigate('/');
    }
  });

  useEffect(() => {
    if (token) {
      newsletterConfirm({ token });
    }
  }, []);

  return (
    <div className={classnames(styles.componentWrapper, 'StylePath-Pages-NewsletterConfirmPage')}>
      <Container>
        <Loader />
      </Container>
    </div>
  );
};

export default NewsletterConfirmPage;
