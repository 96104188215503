// formularz dodawania odbiorcy

import React, { FC, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import map from 'lodash/map';

import {
  usePutCartMainData,
  usePostCartValidate,
  useGetReceiversCountries,
  usePutOrderEditReceiverAddress
} from 'api';
import {
  IReceiverRequest,
  ICartMainData,
  IReceiversCountryListItem,
  ICheckoutStep
} from 'api/types';
import { useAppNavigate, useRWD } from 'hooks';
import { Input, Button, FormElement, Select } from 'components/controls';

import styles from 'theme/components/containers/ReceiverForm/ReceiverForm.module.scss';

// typ danych wejściowych
interface IProps {
  id?: number;
  receiver?: ICartMainData['receiver'];
  isDropShipping?: boolean;
  setCheckoutStep?: (step: ICheckoutStep) => void;
  dropshippingStatus?: 'ENABLED' | 'DISABLED';
  isEditOrder?: boolean;
  onCancelButtonClick?: () => void;
  refetchOrderData?: () => void;
}

const ReceiverForm: FC<IProps> = ({
  id,
  receiver,
  isDropShipping,
  setCheckoutStep,
  dropshippingStatus,
  isEditOrder,
  onCancelButtonClick,
  refetchOrderData
}) => {
  const { t } = useTranslation();
  const navigate = useAppNavigate();
  const { isMobile } = useRWD();

  // walidacja koszyka
  const { mutate: validateCart } = usePostCartValidate(id || 0, {
    onSuccess: () => {
      setCheckoutStep?.('BILLING');
    },
    onError: (errors) => {
      map(errors.fields_info, (field) => setFieldError(field.property_path, field.message));
    }
  });

  // obsługa dropshippingu
  const { mutate: updateMainData } = usePutCartMainData(id || 0, {
    onSuccess: () => {
      validateCart({ verification_scope: 'receiverAddress|clientAddress' });
    },
    onError: (errors) => {
      map(errors.fields_info, (field) => setFieldError(field.property_path, field.message));
    }
  });

  // edycja danych odbiorcy
  const { mutate: editReceiverAddress } = usePutOrderEditReceiverAddress(id || 0, {
    onSuccess: () => {
      refetchOrderData?.();
      onCancelButtonClick?.();
    },
    onError: (errors) => {
      map(errors.fields_info, (field) => setFieldError(field.property_path, field.message));
    }
  });

  // pobieranie krajów odbiorców
  const { data: receiversCountriesData } = useGetReceiversCountries();

  // obsługa danych formularza
  const { values, errors, setFieldValue, handleSubmit, setValues, setFieldError, initialValues } =
    useFormik<IReceiverRequest>({
      initialValues: {
        email: receiver?.email || '',
        name: receiver?.name || '',
        first_name: receiver?.first_name || '',
        last_name: receiver?.last_name || '',
        phone: receiver?.phone || '',
        address: {
          street: receiver?.address.street || '',
          city: receiver?.address.city || '',
          postal_code: receiver?.address.postal_code || '',
          building: receiver?.address.building || '',
          apartment: receiver?.address.apartment || '',
          country_code: receiver?.address.country_code || ''
        }
      },
      onSubmit: (values) => {
        if (isEditOrder && id) {
          editReceiverAddress({ order_id: id, ...values });

          return;
        }

        if (isDropShipping) {
          updateMainData({ receiver_id: null, receiver: values });

          return;
        }

        validateCart({ verification_scope: 'receiverAddress|clientAddress' });
      },
      validateOnChange: false,
      enableReinitialize: true
    });

  // opcje dropdownu typów pola odbiorce
  const receiverCountryOptions = useMemo(() => {
    const sections = (receiversCountriesData?.items || []).map((country) => ({
      value: country.code,
      label: <span>{country.name}</span>,
      item: country
    }));

    return sections;
  }, [receiversCountriesData]);

  // zmiana danych przy zmianie dropshippingu
  useEffect(() => {
    if (dropshippingStatus === 'ENABLED') {
      setValues({
        email: '',
        name: '',
        first_name: '',
        last_name: '',
        phone: '',
        address: {
          street: '',
          city: '',
          postal_code: '',
          building: '',
          apartment: '',
          country_code: ''
        }
      });
    }

    if (dropshippingStatus === 'DISABLED') {
      setValues(initialValues);
    }
  }, [dropshippingStatus]);

  const renderTelephoneInput = () => {
    return (
      <FormElement>
        <label>
          <Trans>Telefon</Trans>
        </label>
        <Input
          value={values.phone}
          onChange={(value) => setFieldValue('phone', value)}
          error={errors.phone}
          disabled={!isDropShipping}
        />
      </FormElement>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-ReceiverForm'
      )}>
      <div className="confirmationModalContent">
        <FormElement>
          <label>
            <Trans>Nazwa firmy</Trans>
          </label>
          <Input
            value={values.name}
            onChange={(value) => setFieldValue('name', value)}
            error={errors.name}
            disabled={!isDropShipping}
          />
        </FormElement>

        <div className={classnames(styles.formGroup, styles.nameGroup)}>
          <FormElement>
            <label>
              <Trans>Imię</Trans>
            </label>
            <Input
              value={values.first_name}
              onChange={(value) => setFieldValue('first_name', value)}
              error={errors.first_name}
              disabled={!isDropShipping}
            />
          </FormElement>

          <FormElement>
            <label>
              <Trans>Nazwisko</Trans>
            </label>
            <Input
              value={values.last_name}
              onChange={(value) => setFieldValue('last_name', value)}
              error={errors.last_name}
              disabled={!isDropShipping}
            />
          </FormElement>
        </div>

        <div className={classnames(styles.formGroup, styles.streetGroup)}>
          <FormElement>
            <label>
              <Trans>Ulica</Trans>
            </label>
            <Input
              value={values.address.street}
              onChange={(value) => setFieldValue('address.street', value)}
              error={errors.address?.street}
              disabled={!isDropShipping}
            />
          </FormElement>
          <FormElement>
            <label>
              <Trans>Numer budynku</Trans>
            </label>
            <Input
              value={values.address.building}
              onChange={(value) => setFieldValue('address.building', value)}
              error={errors.address?.building}
              disabled={!isDropShipping}
            />
          </FormElement>
          <FormElement>
            <label>
              <Trans>Numer lokalu</Trans>
            </label>
            <Input
              value={values.address.apartment}
              onChange={(value) => setFieldValue('address.apartment', value)}
              error={errors.address?.apartment}
              disabled={!isDropShipping}
            />
          </FormElement>
        </div>

        <div className={classnames(styles.formGroup, styles.townGroup)}>
          <FormElement>
            <label>
              <Trans>Kod pocztowy</Trans>
            </label>
            <Input
              value={values.address.postal_code}
              onChange={(value) => setFieldValue('address.postal_code', value)}
              error={errors.address?.postal_code}
              disabled={!isDropShipping}
            />
          </FormElement>

          <FormElement>
            <label>
              <Trans>Miasto</Trans>
            </label>
            <Input
              value={values.address.city}
              onChange={(value) => setFieldValue('address.city', value)}
              error={errors.address?.city}
              disabled={!isDropShipping}
            />
          </FormElement>
          <FormElement>
            <label>
              <Trans>Państwo</Trans>
            </label>
            <Select<IReceiversCountryListItem>
              options={receiverCountryOptions}
              placeholder={t('Wybierz')}
              variant="small"
              value={values.address.country_code}
              disabled
              onChange={(item) => {
                if (item?.code) {
                  setFieldValue('address.country_code', item?.code);
                }
              }}
            />
            <span className={styles.error}>{errors.address?.country_code}</span>
          </FormElement>
          {isMobile && renderTelephoneInput()}
        </div>

        <div className={classnames(styles.formGroup, styles.contactGroup)}>
          <FormElement>
            <label>
              <Trans>Email</Trans>
            </label>
            <Input
              value={values.email}
              onChange={(value) => setFieldValue('email', value)}
              error={errors.email}
              disabled={!isDropShipping}
            />
          </FormElement>
          {!isMobile && renderTelephoneInput()}
        </div>
      </div>
      <div
        className={classnames(!isEditOrder ? styles.actions : 'confirmationModalActions', {
          [styles.isEditMode]: !!isEditOrder
        })}>
        {isEditOrder ? (
          <>
            {onCancelButtonClick && (
              <Button ghost onClick={() => onCancelButtonClick()}>
                {t('Anuluj')}
              </Button>
            )}
            <Button onClick={() => handleSubmit()}>{t('Zmień dane')}</Button>
          </>
        ) : (
          <>
            <Button ghost onClick={() => navigate(`/cart/${id}`)}>
              {t('Wstecz')}
            </Button>
            <Button onClick={() => handleSubmit()}>{t('Dalej')}</Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ReceiverForm;
