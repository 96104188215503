// Hook odpowiedzialny za pobranie podsumowania listy zamówień na dashboardzie

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

interface IRequest {
  periodTime?: string;
}

// typ zwracanych danych
export interface IResponse {
  realized_count: 0;
  paid_count: 0;
  to_pay_count: 4;
  total_value: 360.86;
  currency: 'PLN';
  total_value_formatted: '360.86';
}

const getDashboardOrdersSummaryVmp = (params?: IRequest): Promise<IResponse> =>
  axios.get('dashboards/orders/summary/vmp', { params });

export const useGetDashboardOrdersSummaryVmp = (
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['dashboard-orders-summary-vmp', params],
    () => getDashboardOrdersSummaryVmp(params),
    options
  );
