import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import classnames from 'classnames';

import { useNotifications } from 'hooks';
import { useGetOrderExportImages, useGetOrderExportDocuments } from 'api';
import { IOrderVmp } from 'api/types';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

interface IProps {
  orderData: IOrderVmp;
}

const DownloadSection: FC<IProps> = ({ orderData }) => {
  const { t } = useTranslation();
  const { showErrorMessage } = useNotifications();

  const { refetch: downloadImages } = useGetOrderExportImages(orderData.id, {
    enabled: false,
    onSuccess: (data) => {
      fetch(data.url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;

          a.download = data.file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => showErrorMessage(t('Błąd pobierania pliku')));
    }
  });

  // export zamówienia do pdf
  const { refetch: getDocuments } = useGetOrderExportDocuments(
    orderData.id,
    {},
    {
      enabled: false,
      onSuccess: (data) => {
        const a = document.createElement('a');
        a.download = data.file_name;
        a.href = data.url;
        a.click();
      }
    }
  );

  return (
    <Grid className={classnames(styles.box, styles.download)} item xs={12} lg={4}>
      <div className={styles.inner}>
        <div className={styles.boxTitle}>
          <Trans>Pobierz</Trans>
        </div>
        {orderData.has_document_files && (
          <div>
            <button onClick={() => getDocuments()}>
              <Trans>Faktury</Trans>
            </button>
          </div>
        )}
        <div>
          <button onClick={() => downloadImages()}>
            <Trans>Zdjęcia</Trans>
          </button>
        </div>
      </div>
    </Grid>
  );
};

export default DownloadSection;
